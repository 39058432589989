import { ArrowTrendCellRenderer } from './../../components/server-side-grid/arrow-trend-cell-renderer/arrow-trend-cell-renderer.component';
import { IconCellRenderer } from './../../components/server-side-grid/icon-cell-renderer/icon-cell-renderer.component';
import { ArrowWithPercentCellRenderer } from '../../components/server-side-grid/arrow-with-percent-cell-renderer/arrow-with-percent-cell-renderer.component';
import { ArrowCellRenderer } from './../../components/server-side-grid/arrow-cell-renderer/arrow-cell-renderer.component';
import { IconWithTextComponent } from '../../components/icon-with-text/icon-with-text.component';
import { StudentSupportsPanelListKebabComponent } from './../../../school/lists/student-supports-panel-list/student-supports-panel-list-kebab/student-supports-panel-list-kebab.component';
import { Injectable } from '@angular/core';
import { NetworkSettingsMoreButtonComponent } from '../../../network/network-settings/network-settings-more-button/network-settings-more-button.component';
import { SupportCategoriesPills } from '../../components/supports-list/support-categories-pills/support-categories-pills.component';
import { PortfolioPermissionDropdownComponent } from '../../components/user-management/portfolio-permission-dropdown/portfolio-permission-dropdown.component';
import { IColumn, IColumnStuLvl, IRowData, TList } from '../../models/list-models';
import { SupportAttendanceFormDropdown } from './../../../school/lists/support-attendance-form-list-v2/support-attendance-form-dropdown/support-attendance-form-dropdown.component';
import { SupportAttendanceLogsKebabComponent } from './../../../school/lists/support-attendance-logs-list-v2/support-attendance-logs-kebab/support-attendance-logs-kebab.component';
import { SupportOptionsMenuKebabComponent } from './../../../school/lists/supports/support-settings-list/support-options-menu/support-options-menu-kebab.component';
import { ShelterNetworkSettingsMoreButtonComponent } from '../../../shelter/shelter-network-settings/shelter-network-settings-more-button/shelter-network-settings-more-button.component';
import { UserManagementMoreButtonComponent } from '../../components/settings/user-management-settings/user-management-more-button/user-management-more-button.component';
import { StudentPostsecPanelListTrashCanComponent } from 'Src/ng2/school/lists/postsecondary-panel-lists/postsec-panel-paths-list/student-postsec-panel-list-trash-can/student-postsec-panel-list-trash-can.component';
import { StudentPostsecPanelListStatusDropdownComponent } from 'Src/ng2/school/lists/postsecondary-panel-lists/postsec-panel-paths-list/student-postsec-panel-list-status-dropdown/student-postsec-panel-list-status-dropdown.component';
import { OtherToolsMoreButtonComponent } from 'Src/ng2/school/school-tools/other-tools/other-tools-more-button/other-tools-more-button.component';
import { ExperienceMoreButtonComponent } from './../../../student/common-panels/student-postsec/experiences-content-container/experience-more-button/experience-more-button.component';
import { MasterProgramSettingsPrepsPill } from '../../components/settings/master-program-settings/master-program-settings-preps-pill/master-program-settings-preps-pill.component';
import { SuccessMentoringOptionsKebabComponent } from 'Src/ng2/shelter/profile/profile-panels/success-mentoring-panel/success-mentoring-options-menu/success-mentoring-options-kebab.component';
import { CollegeNowStatusPillComponent } from './../../../school/lists/college-now-student-list/college-now-status-pill/college-now-status-pill.component';
import * as moment from 'moment';

const SUPPORT_SESSION_CHECKBOXES = {
  NOT_HELD: 'Not held',
};

/**
 * Other conditions may be added to the body of this function to account for
 * different columnKeys.
 * @param columnKey
 * @param colVal
 * @returns a style class that imposes a color change on text in list cells
 */
const getTextColorStringClass = (columnKey: string, colVal: any): string => {
  if (columnKey === 'FAFSA_STATUS_NUMBER' || 'TAP_STATUS_NUMBER') {
    return (colVal === 1 || colVal === 3) ? 'warning' : '';
  }
}

/* istanbul ignore next */
@Injectable({
  providedIn: 'root',
})
export class CellDisplayService {
  // case1 'Percent': append `%` sign to `Percent` data - JCHU
  static formatDisplayValue ({
    colVal,
    colDataType,
  }: {
    colVal: string | number;
    colDataType: string;
  }): string | number {
    switch (colDataType) {
      case 'Percent':
      case 'Average':
      case 'ShowZeroPercent':
        if (colVal === null || colVal === 'N/A' || colVal === '—') {
          return colVal;
        }
        return `${colVal.toString()}%`;
      case 'postGres_Percent': {
        // colVal will be a string if defined ie: '0'
        // As a result !colVal is only checking for null/undefined
        const val = !colVal ? 0 : Number(colVal);
        const isZero = val === 0;
        return `${isZero ? val : (val * 100).toFixed(1)}%`;
      }
      case 'More':
      case 'MoreInfo':
      case 'MilestoneCheckbox':
        return '';
      case 'Tooltip': // update more info to be tooltip as well
        return '';
      case 'Date': {
        const momentDate = moment(colVal).format('MMM D, YYYY');
        return momentDate === 'Invalid date' ? colVal : momentDate;
      }
      case 'Past':
        if (colVal !== null) return +colVal === 0 ? 'Perfect' : `Missing ${colVal}`;
        else return colVal;
      default:
        return colVal;
    }
  }

  static addCellValueClass ({ colVal, colDataType }): string {
    const parsedInt = typeof (colVal) === 'number' ? colVal.toString() : colVal;

    switch (colDataType) {
      case 'MoreInfo':
        switch (colVal) {
          case 'true':
            return 'center more-info';
          default:
            return 'em-dash';
        }
      // TODO: This type was originally implemented to support Postsec milestones list, but
      // has been extended to support any lists where we need the same cell styles
      // applied. "SUPPORT_SESSION_CHECKBOXES.NOT_HELD" is an example of this. Should
      // we continue to add Airtable columns that specify "MilestoneCheckbox"
      // when they are not supporting postsec list? Should add
      // something like "SupportSessionCheckbox" as a columnDataFormat option and
      // implement support for it.
      case 'MilestoneCheckbox':
        switch (colVal) {
          case 'Complete':
            return 'center milestone milestone-complete';
          case 'Not due yet':
            return 'center milestone milestone-not-due-yet';
          case 'Past due':
            return 'center milestone milestone-past-due';
          case 'Due soon':
            return 'center milestone milestone-due-soon';
          case SUPPORT_SESSION_CHECKBOXES.NOT_HELD:
            return 'center em-dash';
          default:
            return;
        }
      case 'Ellipsis':
        return 'center cell-ellipsis';
      case 'Initials':
        switch (colVal) {
          case colVal === null:
            return 'em-dash';
          default:
            return 'person-initials';
        }
      case 'ShowZeroPercent':
        switch (colVal) {
          case null:
            return 'em-dash';
          default:
            return '';
        }
      case 'Count':
      case 'Percent':
        switch (colVal) {
          case '—':
            return 'em-dash';
          default:
            return '';
        }
      case 'SUPPORT_CATEGORIES_PILLS':
      case 'REGENTS_PREP':
        return 'center';
      case 'SUPPORT_OPTIONS_MENU':
        return 'center';
      case 'NumStringWithZero':
        switch (colVal) {
          case null:
            return 'em-dash';
          default:
            return '';
        }
      case 'Past':
        return parsedInt === null ? 'em-dash' : parsedInt;
      default:
        return '';
    }
  }

  // TODO: clean this up and make it a bit and make it a bit more flexible for different lists and services
  static addCellBackgroundClass ({ colVal, columnKey, cellConfig, data, v4IsOn = false }): string | null {
    switch (cellConfig) {
      case 'TEXT_COLOR':
        return getTextColorStringClass(columnKey, colVal);
      case 'GRADIENT':
        switch (columnKey) {
          case 'FAILING_COURSES_COUNT':
            switch (+colVal) {
              case 0:
                return;
              case 1:
                return 'gradient-low';
              case 2:
              case 3:
              case 4:
                return 'gradient-medium';
              default:
                return 'gradient-high';
            }
          case 'PCT_GROWTH_ELA':
          case 'PCT_GROWTH_MATH':
            switch (+colVal) {
              case 0:
                return 'gradient-low';
              default:
                return;
            }
          case 'LATEST_REL_LEVEL_ELA':
          case 'LATEST_REL_LEVEL_MATH':
            switch (colVal) {
              case '1 below':
                return 'gradient-low';
              case '2 below':
                return 'gradient-medium';
              case '2+ below':
                return 'gradient-high';
            }
          case 'APPLIED_PATHS_FRACTION':
          case 'RESPONSES_PATHS_FRACTION':
            return this.getPostsecFractionClasses(colVal);
          case 'PAST_5_ATT':
          case 'PAST_10_ATT':
          case 'PAST_20_ATT':
          case 'THIS_TERM_ATT':
            return colVal === null ? '' : this.getAttAbsGradient(data.gradient);
          case 'YTD_ATTENDANCE_CHANGE':
            return this.percentChangeCurrYearGradient(colVal);
          case 'ATT_TODAY':
            return colVal === 'Absent' ? 'gradient-medium' : '';
          case 'READING_LVL_GROWTH':
            return colVal === 'Declined' || colVal === 'No growth' ? 'gradient-high' : '';
          case 'INTERACTION_LAST_5_DAYS':
            return this.remoteInteractionLast5Gradient(colVal);
          case 'INTERACTION_LAST_20_DAYS':
            return this.remoteInteractionLast20Gradient(colVal);
        }
      case 'GRAPH_INDICATOR_BG':
        switch (columnKey) {
          case 'NV_ATT_RISK_GROUP_LESS_60':
          case 'NV_TRANSFER_ATT_RISK_GROUP_LESS_33':
            return 'color-bg-lta';
          case 'NV_ATT_RISK_GROUP_60_TO_79':
          case 'NV_TRANSFER_ATT_RISK_GROUP_33_TO_59':
            return 'color-bg-severe';
          case 'NV_ATT_RISK_GROUP_80_TO_89':
          case 'NV_TRANSFER_ATT_RISK_GROUP_60_TO_79':
            return 'color-bg-chron';
          case 'NV_ATT_RISK_GROUP_90_TO_94':
          case 'NV_TRANSFER_ATT_RISK_GROUP_80_TO_90':
            return 'color-bg-at-risk';
          case 'NV_ATT_RISK_GROUP_95_TO_100':
          case 'NV_TRANSFER_ATT_RISK_GROUP_90_TO_100':
            return 'color-bg-not-at-risk';
          case 'NV_ATT_RISK_GROUP_NO_DATA':
          case 'NV_TRANSFER_ATT_RISK_GROUP_NO_DATA':
            return 'color-bg-no-data';
          // -----------------------------------------------------
          case 'SCHED_TO_END_TERM_ON_TRACK':
            return 'color-gb-sched-to-end-term-on-track';
          case 'ZERO_ONE_TO_ONE_GAPS':
            return 'color-gb-sched-zero-one-to-one';
          case 'ONE_TO_TWO_GAPS':
            return 'color-gb-sched-one-to-two';
          case 'TWO_TO_FIVE_GAPS':
            return 'color-gb-sched-two-to-five';
          case 'FIVE_PLUS_GAPS':
            return 'color-gb-sched-five-plus';
          case 'CREDITS_GAPS_SECOND_BAND':
            return 'color-gb-sched-credits-gaps-second-band';
          case 'CREDITS_GAPS_THIRD_BAND':
            return 'color-gb-sched-credits-gaps-third-band';
          case 'CREDITS_GAPS_FOURTH_BAND':
            return 'color-gb-sched-credits-gaps-fourth-band';
          case 'CREDITS_GAPS_FIFTH_BAND':
            return 'color-gb-sched-credits-gaps-fifth-band';
          // -----------------------------------------------------
          case 'SCHEDULED_TO_END_TERM_ON_TRACK_IN_CREDITS':
            return 'color-gb-sched-to-end-term-on-track-in-credits';
          case 'FULLY_PLANNED_FOR_ALL_GAPS':
            return 'color-gb-fully-planned-for-all-gaps';
          case 'MISSING_PLANS_TO_ADDRESS_CREDIT_GAPS':
            return 'color-gb-missing-plans-to-address-credit-gaps';
          // -----------------------------------------------------
          case 'GRAD_PLAN_WITHIN_REACH':
            return 'color-bg-grad-plan-within-reach';
          case 'GRAD_PLAN_MORE_AMBITIOUS_POSSIBLE':
            return 'color-bg-grad-plan-ambitious';
          case 'GRAD_PLAN_PLANNED_NON_GRADS':
            return 'color-bg-grad-plan-non-grads';
          case 'GRAD_PLAN_AT_RISK':
            return 'color-bg-grad-plan-at-risk';
          case 'NO_GRAD_PLAN':
            return 'color-bg-no-grad-plan';
          // -----------------------------------------------------
          case 'FULFILLED_ALL_REG_REQ': // Share the same color palette as Grad Plan
            return v4IsOn ? 'color-bg-regents-fulfilled-all-reqs' : 'color-bg-grad-plan-within-reach';
          case '0_REG_NOT_SCHED':
            return v4IsOn ? 'color-bg-regents-0-reg-not-sched' : 'color-bg-grad-plan-ambitious';
          case '1_REG_NOT_SCHED':
            return v4IsOn ? 'color-bg-regents-1-reg-not-sched' : 'color-bg-grad-plan-non-grads';
          case '2_TO_3_REG_NOT_SCHED':
            return v4IsOn ? 'color-bg-regents-2-3-reg-not-sched' : 'color-bg-grad-plan-at-risk';
          case '4_PLUS_REG_NOT_SCHED':
            return v4IsOn ? 'color-bg-regents-4-plus-reg-not-sched' : 'color-bg-no-grad-plan';
          // -----------------------------------------------------
          case 'FULLY_PLANNED_NEXT_ADMIN':
            return 'color-bg-grad-plan-ambitious';
          case 'FULLY_PLANNED_FUTURE_ADMIN':
            return 'color-bg-grad-plan-non-grads';
          case 'MISSING_PLANS_REG':
            return 'color-bg-grad-plan-at-risk';
          // -----------------------------------------------------
          case 'CURRENT_ELL':
            return 'color-bg-current-ell';
          case 'FORMER_ELL':
            return 'color-bg-former-ell';
          case 'EVER_ELL':
            return 'color-bg-ever-ell';
          case 'NEVER_ELL':
            return 'color-bg-never-ell';
          // -----------------------------------------------------
          case 'PASSING_ALL_COURSES':
            return 'color-bg-passing-all-courses';
          case 'FAILING_ONE_COURSE':
            return 'color-bg-failing-one-course';
          case 'FAILING_TWO_COURSES':
            return 'color-bg-failing-two-courses';
          case 'FAILING_THREE_PLUS_COURSES':
            return 'color-bg-failing-three-plus-courses';
          // -----------------------------------------------------
          default:
            return '';
        }
      case 'GREY_BACKGROUND': {
        return 'grey-background';
      }
      default:
        return null;
    }
  }

  static addCellBackgroundClassForEmptyValue (columnKey: string): string | null {
    switch (columnKey) {
      case 'TOTAL_MARKS':{
        return 'grey-background';
      }
      default:
        return null;
    }
  }

  static getPostsecFractionClasses (colVal): string {
    const vals = colVal.split('/');
    if (vals[0] === '-') return '';
    else if (vals.length === 1) {
      return 'gradient-low';
    } else {
      const numerator = parseInt(vals[0]);
      const denom = parseInt(vals[1]);
      const pct = (numerator / denom) * 100;
      const a = pct === 100;
      const b = pct >= 75 && pct <= 99.9;
      const c = pct >= 50 && pct <= 74.9;
      const d = pct >= 25 && pct <= 49.9;
      const e = pct >= 0 && pct <= 24.9;
      if (a) return '';
      if (b) return 'gradient-low';
      if (c) return 'gradient-medium';
      if (d) return 'gradient-high';
      if (e) return 'gradient-higher';
    }
  }

  static percentChangeCurrYearGradient (percentage: number): string {
    let gradient = '';
    if (percentage < 0) {
      gradient = 'gradient-high';
    }
    return gradient;
  }

  static getAttAbsGradient (data: { abs: number | null; timeFrame: string }): string {
    const { timeFrame, abs } = data;
    // no gradient for null or 0 abs
    if (abs === null || abs === 0) {
      return '';
    } else {
      const isGradientLow =
        (timeFrame === 'last 5' && abs === 1) ||
        (timeFrame === 'last 10' && abs <= 3) ||
        (timeFrame === 'last 20' && abs <= 7) ||
        (timeFrame === 'this term' && abs <= 7);
      const isGradientMedium =
        (timeFrame === 'last 5' && abs <= 3) ||
        (timeFrame === 'last 10' && abs <= 6) ||
        (timeFrame === 'last 20' && abs <= 14) ||
        (timeFrame === 'this term' && abs <= 14);
      if (isGradientLow) {
        return 'gradient-low';
      } else if (isGradientMedium) {
        return 'gradient-medium';
      } else {
        return 'gradient-high';
      }
    }
  }

  static remoteInteractionLast5Gradient (missedStr: string): string {
    if (missedStr === null || missedStr === 'Perfect') return '';
    const [, missedNum] = missedStr.split(' ');
    const parsedNum = parseInt(missedNum);
    switch (true) {
      case parsedNum > 1:
        return 'gradient-high';
      default:
        return '';
    }
  }

  static remoteInteractionLast20Gradient (missedStr: string): string {
    if (missedStr === null || missedStr === 'Perfect') return '';
    const [, missedNum] = missedStr.split(' ');
    const parsedNum = parseInt(missedNum);
    switch (true) {
      case parsedNum > 4:
        return 'gradient-high';
      default:
        return '';
    }
  }

  // format the display value and style of rollup-table/fixed-table/infinite-table row
  // based on columnDataType(mid-lvl), columnDataFormat(stu-lvl) and special case
  static getFormattedRowData ({
    rowDataToFormat,
    columns,
    listType,
    v4IsOn = false,
  }: {
    rowDataToFormat: IRowData[][];
    columns: IColumn[] | IColumnStuLvl[];
    listType: TList;
    v4IsOn?: boolean;
  }): IRowData[][] {
    const dataTypeKey = this.getDataTypeKey(listType);
    return rowDataToFormat.map(row => {
      return row.map((rowCol, rowColIndx) => {
        const headerColIndx = this.getRelHeaderColIndx(listType, rowColIndx);
        const colDataType = this.getColDataType({ columns, headerColIndx, dataTypeKey });
        const newRowCol = Object.assign({}, rowCol);
        let colVal: any = newRowCol.data;
        const showZeroColDataTypes = ['Past', 'ShowZeroPercent', 'NumStringWithZero', 'postGres_Percent'];
        const rowActionComponentTypes = ['SUPPORT_OPTIONS_MENU','SHELTER_STUDENT_NAME'];
        if (rowActionComponentTypes.includes(colDataType)) {
          // These could be defined in the last else block, but that block would need additional updates.
          // which may ultimately make sense to do as part of a larger component refactor.
          // Keeping logic specific to the support list actions isolated for now.
          // REFACTOR CARD: https://newvisions.atlassian.net/browse/PI-2157

          newRowCol.style = 'row-action';
          newRowCol.dynamic = this.getDynamicComponent(colDataType);
        } else if (showZeroColDataTypes.includes(colDataType) && newRowCol.data === '0') {
          colVal = 0;
        } else if (newRowCol.data === null || newRowCol.data === '0') {
          newRowCol.style = 'em-dash';
          newRowCol.backgroundStyle = this.addCellBackgroundClassForEmptyValue(newRowCol.columnKey);
          colVal = '—';
          newRowCol.dynamic = this.getDynamicComponent(colDataType);
        } else {
          let cellConfig;
          if (listType !== 'ROLLUP') {
            cellConfig = columns[rowColIndx].cellConfig;
          } else {
            // only for rollup table, columns.length === row.length - 1 (rollup table header columns do not have the col that shows groupingName)
            cellConfig = headerColIndx > -1 ? columns[headerColIndx].cellConfig : null;
          }
          newRowCol.style = this.addCellValueClass({ colVal, colDataType });
          newRowCol.backgroundStyle = cellConfig
            ? this.addCellBackgroundClass({
              colVal,
              columnKey: rowCol.columnKey,
              cellConfig,
              data: newRowCol.meta && JSON.parse(newRowCol.meta),
              v4IsOn,
            })
            : null;
          newRowCol.dynamic = this.getDynamicComponent(colDataType);
        }
        if (newRowCol.meta) {
          let parsedData = newRowCol.meta;
          if (typeof newRowCol.meta === 'string') {
            try {
              // eslint-disable-next-line
              parsedData = newRowCol.meta.replace(/['\']/g,''); // remove escape characters from string
              parsedData = JSON.parse(parsedData);
              if (parsedData.cellRendererParams) newRowCol.hasCellRenderer = true;
            } catch (err) {
              // most of the time the meta field is a stringified object, but sometimes its just a string
              // when you JSON.parse a non object shaped string, it'll error
              newRowCol.hasCellRenderer = false;
            }
          }
        };
        // sets 'originalData' for data that is replaced with icons
        // this allows the original data to be exported via CSV (JE)
        const formattedVal =
          colVal === '—'
            ? '—'
            : this.formatDisplayValue({
              colVal,
              colDataType,
            });
        if (formattedVal !== newRowCol.data) newRowCol.originalData = newRowCol.data;
        newRowCol.data = formattedVal;

        // Need to know if user is self when adding dot icon and for setting checkbox disabled for logged user in School User Management page
        if (this.isMetadataParsable(listType)) {
          const parsedMeta = JSON.parse(newRowCol.meta);
          newRowCol.parsedMeta = parsedMeta;
        }
        if (rowCol.color === 'CORRECT') {
          newRowCol.backgroundStyle = `${newRowCol.backgroundStyle} color-correct-answer`;
        }

        return newRowCol;
      });
    });
  }

  static isMetadataParsable (listType: TList): boolean {
    let isParsable: boolean;
    switch (listType) {
      case 'USER_MANAGEMENT':
        isParsable = true;
        break;
      default:
        isParsable = false;
        break;
    }

    return isParsable;
  }

  static getColDataType ({ columns, headerColIndx, dataTypeKey }): string {
    let colDataType = '';
    if (headerColIndx > -1 && columns[headerColIndx]) {
      if (columns[headerColIndx][dataTypeKey]) {
        colDataType = columns[headerColIndx][dataTypeKey];
      }
    }
    return colDataType;
  }

  static getDataTypeKey (listType: TList): 'columnDataFormat' | 'columnDataType' {
    let dataTypeKey: 'columnDataFormat' | 'columnDataType';
    switch (listType) {
      case 'NETWORK':
      case 'STUDENT':
      case 'USER':
      case 'USER_SCHOOL_PORTFOLIO':
      case 'USER_SHELTER_PORTFOLIO':
      case 'SUPPORTS':
      case 'SUPPORT_SETTINGS':
      case 'USER_MANAGEMENT':
      case 'MILESTONES':
      case 'STUDENT_PROFILE_EXPERIENCES':
      case 'MASTER_PROGRAM':
      case 'MOCK_REGENTS':
      case 'SHELTER_STUDENT':
        dataTypeKey = 'columnDataFormat';
        break;
      case 'ROLLUP':
        return 'columnDataType';
      case 'SHEETS':
      case 'STUDENT_PROFILE_PATHS':
      case 'GAP_PLANS':
      case 'PROGRAM_CHANGES':
        return 'columnDataType';
      default:
        dataTypeKey = 'columnDataType';
        break;
    }
    return dataTypeKey;
  }

  static getRelHeaderColIndx (listType: TList, rowColIndx: number): number {
    switch (listType) {
      case 'NETWORK':
      case 'SHEETS':
      case 'STUDENT':
      case 'SUPPORTS':
      case 'USER':
      case 'SUPPORT_SETTINGS':
      case 'MILESTONES':
      case 'USER_SCHOOL_PORTFOLIO':
      case 'USER_SHELTER_PORTFOLIO':
      case 'USER_MANAGEMENT':
      case 'MASTER_PROGRAM':
      case 'MOCK_REGENTS':
      case 'STUDENT_PROFILE_PATHS':
      case 'STUDENT_PROFILE_EXPERIENCES':
      case 'DOE_ADVISING_SESSION_NOTES':
      case 'GAP_PLANS':
      case 'PROGRAM_CHANGES':
      case 'SHELTER_STUDENT':
      case 'STUDENT_SEL_SUPPORTS_PROFILE_PANEL':
        return rowColIndx;
      case 'ROLLUP':
        return rowColIndx - 1;
      default:
        return rowColIndx - 1;
    }
  }

  static getDynamicComponent (colDataType: string): any {
    let component;
    switch (colDataType) {
      case 'ICON_CELL_RENDERER':
        component = IconCellRenderer;
        break;
      case "ARROW_WITH_PERCENT_CELL_RENDERER":
        component = ArrowWithPercentCellRenderer;
        break;
      case 'ARROW_CELL_RENDERER':
        component = ArrowCellRenderer;
        break;
      case 'SCHOOL_CLUSTER_USER_LIST_MORE':
        component = NetworkSettingsMoreButtonComponent;
        break;
      case 'SHELTER_CLUSTER_USER_LIST_MORE':
        component = ShelterNetworkSettingsMoreButtonComponent;
        break;
      case 'EDITABLE_DROPDOWN': // for school cluster user portfolio modal
      case 'SHELTER_EDITABLE_DROPDOWN': // for shelter cluster user portfolio modal
        component = PortfolioPermissionDropdownComponent;
        break;
      case 'SHELTER_SUCCESS_MENTORING_UPDATE_KEBAB':
        component = SuccessMentoringOptionsKebabComponent;
        break;
      case 'SUPPORT_CATEGORIES_PILLS':
        component = SupportCategoriesPills;
        break;
      case 'WAS_SUPPORT_SESSION_HELD':
        component = SupportAttendanceLogsKebabComponent;
        break;
      case 'SUPPORT_ATTENDANCE_DROPDOWN':
        component = SupportAttendanceFormDropdown;
        break;
      case 'SUPPORT_OPTIONS_MENU':
        component = SupportOptionsMenuKebabComponent;
        break;
      case 'OTHER_TOOLS_MORE':
        component = OtherToolsMoreButtonComponent;
        break;
      case 'STUDENT_SUPPORT_UPDATE_KEBAB':
        component = StudentSupportsPanelListKebabComponent;
        break;
      case 'SCHOOL_USER_LIST_MORE':
        component = UserManagementMoreButtonComponent;
        break;
      case 'STUDENT_PATH_STATUS':
        component = StudentPostsecPanelListStatusDropdownComponent;
        break;
      case 'STUDENT_PATH_TRASH':
        component = StudentPostsecPanelListTrashCanComponent;
        break;
      case 'EXPERIENCES_LIST_MORE':
        component = ExperienceMoreButtonComponent;
        break;
      case 'COLLEGE_NOW_STATUS':
        component = CollegeNowStatusPillComponent;
        break;
      case 'REGENTS_PREP':
        component = MasterProgramSettingsPrepsPill;
        break;
      case 'ICON_WITH_TEXT':
        component = IconWithTextComponent;
        break;
      case 'ARROW_TREND_CELL_RENDERER':
        component = ArrowTrendCellRenderer;
      default:
        break;
    }
    return component;
  }
}
