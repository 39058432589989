import { SchoolAssessmentsEffects } from './assessments-effects/school-assessments-effects';
import { StudentAssessmentsEffects } from './assessments-effects/student-assessments-effects';
import { AttendanceRecordsEffects } from './attendance-records-effects';
import { CollegePathEffects } from './college-path-effects';
import { CurrentUserEffects } from './current-user-effects';
import { DashboardEffects } from './dashboard-effects';
import { DataLoadsEffects } from './data-loads-effects';
import { DocLogsEffects } from './doc-logs-effects';
import { FocusEffects } from './focus-effects/focus-effects';
import { GridDataEffects } from './grid-effects';
import { PartnerOrgsEffects } from './partner-orgs-effects';
import { RollupGroupingEffects } from './rollup-grouping-effects/rollup-grouping-effects';
import { SchoolEffects } from './school-effects';
import { SdcEffects } from './sdc-effects/sdc-effects';
import { ShelterEffects } from './shelter-effects/shelter-effects';
import { StudentGroupingsEffects } from './student-groupings-effects/student-groupings-effects';
import { StudentPathsEffects } from './student-paths-effects';
import { StudentSupportsEffects } from './student-supports-effects';
import { StudentEffects } from './students-effects';
import { SupportsEffects } from './supports-effects';
import { TileEffects } from './tile-effects/tile-effects';
import { ToggleEffects } from './toggle-effects';
import { ToolsEffects } from './tools-effects';
import { UsersEffects } from './users-effects/users-effects';
import { StudentMapGrowthEffects } from './map-growth-effects/student-map-growth-effects';
import { StudentAcadienceEffects } from './student-acadience-effects/student-acadience-effects';
import { ObjectCacheEffects } from './object-cache-effects';
import { GapPlansEffects } from './gap-plans-effects';
import { CourseDiffEffects } from './course-diff-effects';

export const effects: any[] = [
  StudentEffects,
  SchoolEffects,
  ToggleEffects,
  CurrentUserEffects,
  DocLogsEffects,
  DashboardEffects,
  StudentSupportsEffects,
  AttendanceRecordsEffects,
  StudentPathsEffects,
  CollegePathEffects,
  ToolsEffects,
  PartnerOrgsEffects,
  TileEffects,
  FocusEffects,
  RollupGroupingEffects,
  StudentGroupingsEffects,
  SchoolAssessmentsEffects,
  StudentAssessmentsEffects,
  DataLoadsEffects,
  SupportsEffects,
  GridDataEffects,
  ShelterEffects,
  SdcEffects,
  UsersEffects,
  StudentMapGrowthEffects,
  StudentAcadienceEffects,
  ObjectCacheEffects,
  GapPlansEffects,
  CourseDiffEffects,
];

export * from './assessments-effects/school-assessments-effects';
export * from './assessments-effects/student-assessments-effects';
export * from './attendance-records-effects';
export * from './college-path-effects';
export * from './current-user-effects';
export * from './dashboard-effects';
export * from './data-loads-effects';
export * from './doc-logs-effects';
export * from './focus-effects/focus-effects';
export * from './grid-effects';
export * from './partner-orgs-effects';
export * from './rollup-grouping-effects/rollup-grouping-effects';
export * from './school-effects';
export * from './sdc-effects/sdc-effects';
export * from './shelter-effects/shelter-effects';
export * from './student-groupings-effects/student-groupings-effects';
export * from './student-paths-effects';
export * from './student-supports-effects';
export * from './students-effects';
export * from './supports-effects';
export * from './tile-effects/tile-effects';
export * from './toggle-effects';
export * from './tools-effects';
export * from './users-effects/users-effects';
export * from './student-acadience-effects/student-acadience-effects';
export * from './object-cache-effects';
