<div *ngIf="networkExternalFilterOptions">
  Show data for
  <nv-dropdown-box
    [options]="networkExternalFilterOptions"
    [selected]="activeNetworkExternalFilterOptions"
    (selectOption)="networkExternalFilterOptionChange.emit($event)"
  ></nv-dropdown-box>
</div>
<nv-button *ngIf="!hideEditColumns"
  type="filter"
  size="small"
  icon="edit-column-small-purple"
  (click)="onEditColumns.emit()"
>{{ columnsButtonText }}</nv-button>
<div #filterSummary>
  <nv-button
    type="filter"
    size="small"
    icon="filter-small-purple"
    [isActive]="isFilterSummaryVisible"
    [disabled]="isFilterButtonDisabled"
    (click)="onFilterSummaryClick()"
  >{{ filtersButtonText }}</nv-button>
</div>
<div #sortSummary>
  <nv-button
    type="filter"
    size="small"
    icon="sort-small-purple"
    [disabled]="isSortsButtonDisabled"
    [isActive]="isSortSummaryVisible"
    (click)="onSortSummaryClick()"
  >{{ sortsButtonText }}</nv-button>
</div>
