@switch (optionType) {
    @case ('radio') {
        <div class="multi-select-filter-section-container">
            <div class="multi-select-filter-section-header-row">
                <label class="filter-title">{{sectionTitle}}</label>
                <nv-pill
                    [text]="this.getCurrentCountText()"
                    isInverted="true"
                ></nv-pill>
            </div>
            <div class="multi-select-filter-section-options-container">
                <mat-radio-group 
                class="nv-mat-radio-group"
                (change)="emitOptionChosen($event)"
                >
                <div class="nv-mat-radio-group-default">
                    @for (option of defaultOptions; track option.id) {
                        <mat-radio-button
                        [checked]="option.chosen"
                        [value]="option.value"
                        >
                          {{option.label}}
                        </mat-radio-button>
                    }
                </div>
                    @if (toggleSeeMore == true) {
                        <mat-expansion-panel
                            class="see-more-panel" 
                            hideToggle
                            #expansionPanel="matExpansionPanel"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ expansionPanel.expanded ? 'See less' : 'See more' }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            @for (option of seeMoreOptions; track option.id) {
                                
                                <mat-radio-button
                                [checked]="option.chosen"
                                [value]="option.value"
                                >
                                  {{option.label}}
                                </mat-radio-button>

                        }
                        </mat-expansion-panel>
                    }
                </mat-radio-group>
            </div>
        </div>

    }
    @case ('checkbox') {
        <div class="multi-select-filter-section-container">
            <div class="multi-select-filter-section-header-row">
                <label class="filter-title">{{sectionTitle}}</label>
                <nv-pill
                    [text]="currentCountText"
                    isInverted="true"
                ></nv-pill>
                @if (hasSearch === true) {
                    <nv-textbox
                    class="search-box"
                    (clearValue)="onClearInput()"
                    [textControl]="searchInput"
                    hasClearBtn="true"
                    icon="search-large-black"
                    placeholder="{{searchPlaceholder}}"
                  ></nv-textbox>
                }
            </div>
            @if (searchInput?.value) {
                <div class="multi-select-filter-section-options-container">
                    <section class="nv-mat-checkbox-group">
                        <div class="checkbox-default-group">
                        @for(option of searchedOptions; track option.id) {
                            <mat-checkbox
                            class="nv-mat-checkbox"
                            [(ngModel)]="option.chosen"
                            (change)="emitOptionChosen($event)"
                            [value]="option.value"
                          >
                              {{option.label}}
                          </mat-checkbox>
                        }
                        </div>
                    </section>
                </div>
            } @else {
                <div class="multi-select-filter-section-options-container">
                    <section class="nv-mat-checkbox-group">
                        <div class="checkbox-default-group">
                            @for (option of defaultOptions; track option.id) {
                                @if (option.isAllOption === true) {
                                    <mat-checkbox
                                      class="nv-mat-checkbox"
                                      [checked]="allChosen"
                                      (change)="setAll($event.checked)"
                                      [value]="option.value"
                                    >
                                      {{option.label}}
                                    </mat-checkbox>
                                } @else {
                                    <mat-checkbox
                                    class="nv-mat-checkbox"
                                    [(ngModel)]="option.chosen"
                                    (change)="emitOptionChosen($event)"
                                    [value]="option.value"
                                  >
                                      {{option.label}}
                                  </mat-checkbox>
                                }
                
                            }
                        </div>

                        @if (toggleSeeMore == true) {
                            <mat-expansion-panel 
                                class="see-more-panel"
                                hideToggle
                                #expansionPanel="matExpansionPanel"
                            >
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ expansionPanel.expanded ? 'See less' : 'See more' }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                @for (option of seeMoreOptions; track option.id) {
                                    <mat-checkbox
                                    class="nv-mat-checkbox"
                                    [(ngModel)]="option.chosen"
                                    (change)="emitOptionChosen($event)"
                                    [value]="option.value"
                                    >
                                      {{option.label}}
                                    </mat-checkbox>
                                }
                            </mat-expansion-panel>
                        }
                    </section>
                </div>
            }

        </div>

    }
    @case ('range') {
        <div class="multi-select-filter-section-container">
            <div class="filter-label">{{ sectionTitle }}</div>
            <div class="nv-mat-range-slider">
                <ngx-slider
                  (userChangeEnd)="emitOptionChosen({ $event, optionType: 'range'})"
                  [value]="getFloor()"
                  [highValue]="getCeiling()"
                  [options]="rangeOptions"
                ></ngx-slider>
            </div>
          </div>
    }
}
