import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvMultiSelectFilterSectionComponent } from './nv-multi-select-filter-section.component';
import { NvPillModule } from '../nv-pill/nv-pill.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { NvTextboxModule } from '../nv-textbox/nv-textbox.module';

@NgModule({
  imports: [
    CommonModule,
    NvPillModule,
    MatRadioModule,
    MatCheckboxModule,
    MatExpansionModule,
    FormsModule,
    NvTextboxModule,
    NgxSliderModule,
  ],
  declarations: [NvMultiSelectFilterSectionComponent],
  exports: [NvMultiSelectFilterSectionComponent],
})
export class NvMultiSelectFilterSectionModule {}
