import { RollbarService } from './../rollbar/rollbar.service';
import { Inject, Injectable } from '@angular/core';
import { filter, sortBy } from 'lodash';
import { IUserMiniWithRole } from '../../typings/interfaces/user.interface';
import { ImUser } from './../im-models/im-user';
import * as Rollbar from 'rollbar';

@Injectable()
export class PointPeopleService {
  constructor (private imUser: ImUser, @Inject(RollbarService) private rollbar: Rollbar) {}

  // filters for users that can be assigned as lead point people
  filterForUsersThatCanBeLeadPp (users: IUserMiniWithRole[], schoolId: string) {
    const filteredUsers = filter(users, (user: IUserMiniWithRole) => {
      if (this.imUser.isClusterUser(user)) {
        this.rollbar.error(
          '"PointPeopleService#filterForUsersThatCanBeLeadPp" should NOT be called on cluster users! ' +
            `It was called on user "${user._id}".`,
        );
        return false;
      }
      // @ts-ignore
      const validPp = this.imUser.isEditingUser(user, { partnerId: schoolId }) && this.imUser.isActive(user); // Ensure the user authorizationStatus is not DE_AUTHORIZED

      return validPp;
    });
    const sortedFilteredUsers = sortBy(filteredUsers, (user:any) => {
      return user.name.firstName;
    });

    return sortedFilteredUsers;
  }

  // filters for users that can be assigned as non lead point people (e.g. advisor, guidance counselor)
  filterForUsersThatCanBeNonLeadPp (users: IUserMiniWithRole[], schoolId: string) {
    const filteredUsers = filter(users, (user: IUserMiniWithRole) => {
      if (this.imUser.isClusterUser(user)) {
        this.rollbar.error('"PointPeopleService#filterForUsersThatCanBeNonLeadPp" should NOT be called on cluster users!');
        return false;
      }

      const validPp = !this.imUser.isNoAccessUser(user, { partnerId: schoolId }) && this.imUser.isActive(user); // Ensure the user authorizationStatus is not DE_AUTHORIZED

      return validPp;
    });
    const sortedFilteredUsers = sortBy(filteredUsers, (user: any) => {
      return user.name.firstName;
    });

    return sortedFilteredUsers;
  }
}
