import { TValidUserRoles } from '../../../ng2/shared/typings/interfaces/user.interface';

interface IRoles {
  canCreate: TValidUserRoles[];
  canEdit: TValidUserRoles[];
  canDelete: TValidUserRoles[];
  canView: TValidUserRoles[];
}

export interface IUserRolePermissionsForModel {
  ACTIVITY: IRoles;
  ATTENDANCE_RECORD: IRoles;
  COURSE_DIFF: IRoles;
  GAP_PLAN: IRoles;
  IM_SCHOOL: IRoles;
  IM_STUDENT: IRoles;
  NOTE: IRoles;
  NOTIFICATION: IRoles;
  STUDENT_SUPPORT: IRoles;
  SUPPORT: IRoles;
  USER: IRoles;
  TOOL: IRoles;
  DOC_LOG: IRoles;
}

export const UserRolePermissionsForModel: IUserRolePermissionsForModel = {
  // 'all' -> all roles can perform action
  // 'self' -> (e.g. edit a Note created by the viewingUser)
  // empty [] -> no roles can perform action

  ACTIVITY: {
    canCreate: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canView: ['all'],
  },

  ATTENDANCE_RECORD: {
    canCreate: ['all'],
    canEdit: ['all'],
    canDelete: [],
    canView: ['all'],
  },

  COURSE_DIFF: {
    canCreate: ['delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canEdit: ['delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: [],
    canView: ['all'],
  },

  GAP_PLAN: {
    canCreate: ['delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canEdit: ['delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: [],
    canView: ['all'],
  },

  IM_SCHOOL: {
    canCreate: [],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin'],
    canDelete: [],
    canView: ['all'],
  },

  IM_STUDENT: {
    canCreate: [],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: [],
    canView: ['all'],
  },

  NOTE: {
    canCreate: ['all'],
    canEdit: ['self'],
    canDelete: ['delegated_school_admin', 'school_admin', 'self'],
    canView: ['all'],
  },

  STUDENT_SUPPORT: {
    canCreate: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canView: ['all'],
  },

  SUPPORT: {
    canCreate: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canDelete: ['cluster_admin', 'delegated_school_admin', 'school_admin', 'edit_all', 'edit_caseload'],
    canView: ['all'],
  },

  USER: {
    canCreate: ['cluster_admin', 'delegated_school_admin', 'school_admin'],
    canEdit: ['cluster_admin', 'delegated_school_admin', 'school_admin'],
    canDelete: [],
    canView: ['all'],
  },

  TOOL: {
    canCreate: [
      // None
    ],
    canEdit: [
      // None
    ],
    canDelete: [
      // None
    ],
    canView: ['all'],
  },

  DOC_LOG: {
    canCreate: ['all'],
    canEdit: ['all'],
    canDelete: ['all'],
    canView: ['all'],
  },

  NOTIFICATION: {
    canCreate: ['etl_super_admin', 'cluster_admin', 'super_admin'],
    canEdit: ['all'],
    canDelete: ['all'],
    canView: ['all'],
  },
};
