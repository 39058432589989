import { combineReducers } from '@ngrx/store';
import { reducer as schoolAssessments } from './assessments-reducer/school-assessments-reducer';
import { reducer as studentAssessments } from './assessments-reducer/student-assessments-reducer';
import { reducer as batchActions } from './batch-actions-reducer';
import { reducer as careerPath } from './career-path-reducers';
import { reducer as collegePath } from './college-path-reducers';
import { reducer as csvData } from './csv-data-reducer';
import { reducer as currentUser } from './current-user-reducer';
import { reducer as dashboard } from './dashboard-reducer';
import { reducer as dataLoads } from './data-loads-reducer';
import { reducer as docLogs } from './doc-logs-reducer';
import { reducer as focus } from './focus-reducer/focus-reducer';
import { reducer as gridData } from './grid-reducer';
import { reducer as metrics } from './metrics-reducer';
import { reducer as partnerOrgs } from './partner-orgs-reducer';
import { reducer as patchProcessing } from './patch-processing-reducer';
import { reducer as configs } from './previous-states-reducer';
import { reducer as rollupGrouping } from './rollup-grouping-reducer/rollup-grouping-reducer';
import { reducer as school } from './school-reducer';
import { reducer as shelter } from './shelter-reducer/shelter-reducer';
import { reducer as studentGroupings } from './student-groupings-reducer/student-groupings-reducer';
import { reducer as studentPaths } from './student-paths-reducer';
import { reducer as student } from './student-reducer';
import { reducer as studentSupports } from './student-supports-reducer';
import { reducer as supports } from './supports-reducer';
import { reducer as tiles } from './tile-reducer/tile-reducer';
import { reducer as toggles } from './toggle-reducer';
import { reducer as tools } from './tools-reducer';
import { reducer as users } from './users-reducer';
import { reducer as sdcState } from './sdc-reducer/sdc-reducer';
import { reducer as networkFociGrid } from './network-foci-grid-reducer/network-foci-grid-reducer';
import { reducer as studentMapGrowth } from './map-growth-reducer/student-map-growth-reducer';
import { reducer as studentAcadience } from './student-acadience-reducer/student-acadience-reducer';
import { reducer as objectCache } from './object-cache-reducer';
import { reducer as mockRegentsGrid } from './mock-regents-grid-reducer/mock-regents-grid-reducer';

export const reducers = combineReducers({
  school,
  toggles,
  docLogs,
  currentUser,
  dashboard,
  studentSupports,
  users,
  studentPaths,
  collegePath,
  careerPath,
  batchActions,
  configs,
  tools,
  tiles,
  focus,
  rollupGrouping,
  studentGroupings,
  student,
  csvData,
  partnerOrgs,
  patchProcessing,
  metrics,
  schoolAssessments,
  studentAssessments,
  dataLoads,
  gridData,
  supports,
  sdcState,
  networkFociGrid,
  shelter,
  studentMapGrowth,
  studentAcadience,
  objectCache,
  mockRegentsGrid,
});

export const initialState = {
  PORTAL_STORE: {
    school: {
      loaded: false,
      loading: false,
      school: {},
      updated: null,
    },
    shelter: {
      shelterId: null,
      subLocationId: null,
    },
    student: {
      loaded: false,
      loading: false,
      studentEntities: {},
    },
    patchProcessing: {
      patchProcessing: false,
    },
    toggles: {
      loading: false,
      loaded: false,
      toggleEntities: {},
    },
    docLogs: {
      loaded: false,
      loading: false,
      docLogsEntities: {},
    },
    currentUser: {
      loading: false,
      loaded: false,
      userEntity: null,
      error: null,
    },
    dashboard: {
      madlib: {},
      students: {
        studentEntities: {},
        loading: false,
        loaded: false,
      },
      studentSupports: {
        studentSupportEntities: {},
        loading: false,
        loaded: false,
      },
      graphs: {
        POST_SECONDARY_PLANS: {
          loading: false,
          loaded: false,
          data: null,
        },
        // GRAPH STATE IS CALCULATED ON THE FRONTEND
        // NO ENDPOINT EXISTS FOR THIS GRAPH
        FAILURES_WITH_NO_SUPPORT: {
          loading: false,
          loaded: false,
          data: null,
        },
      },
    },
    supports: {
      loaded: false,
      loading: false,
      supportsEntities: {},
    },
    studentSupports: {
      loaded: false,
      loading: false,
      studentSupportsEntities: {},
    },
    milestones: {
      loaded: false,
      loading: false,
      allLoaded: false,
      loadedEntities: {},
      milestoneEntities: {},
    },
    users: {
      loaded: false,
      loading: false,
      userEntities: {},
    },
    studentPaths: {
      loaded: false,
      loading: false,
      allLoaded: false,
      loadedEntities: {},
      studentPathsEntities: {},
    },
    collegePath: {
      loaded: false,
      loading: false,
      collegePathEntities: {},
    },
    careerPath: {
      loaded: false,
      loading: false,
      careerPathEntities: {},
    },
    batchActions: {
      enabled: false,
      selectedStudentIds: [],
      selectedAction: {},
    },
    // store slice to house app related configuration settings
    configs: {
      currentGrouping: null,
      // all states a user has navigated to during session, from first to last, except for current state (CM)
      previousStates: [],
    },
    tools: {
      loaded: false,
      loading: false,
      toolsEntities: {},
    },
    tiles: {
      loaded: false,
      loading: false,
      // todo: tileData
      tileList: [],
    },
    focus: {
      loaded: false,
      loading: false,
      focusData: {},
    },
    rollupGrouping: {
      loaded: false,
      loading: false,
      groupingData: {},
    },
    studentGroupings: {
      loaded: false,
      loading: false,
      studentGroupingsData: [],
    },
    csvData: {
      loaded: false,
      loading: false,
      listData: {},
      madLibSelections: {
        Dimension1: null,
        Dimension2: null,
        Groupings: null,
      },
    },
    partnerOrgs: {
      loaded: false,
      loading: false,
      partnerOrgsEntities: {},
    },
    metrics: {
      changeMade: false,
    },
    schoolAssessments: {
      loaded: false,
      loading: false,
      schoolAssessmentsEntities: {},
    },
    studentAssessments: {
      loaded: false,
      loading: false,
      studentAssessmentsEntities: {},
    },
    dataLoads: {
      loaded: false,
      loading: false,
      dataLoadsEntities: {},
    },
    gridData: {
      loaded: false,
      loading: false,
      hasInitialLoad: false,
      backgroundJobRunning: false,
      refreshGridDataRunning: false,
      schoolId: null,
      gridType: null,
      columnDefs: [],
      loadedColumnDefs: [],
      rowData: [],
      filterHash: null,
      stateHash: null,
      scrollLeft: null,
      scrollTop: null,
      nextTermPlanningGridRunning: false,
    },
    sdcState: {
      loading: false,
      loaded: false,
      // loaded projections and columnKeys across all sdc activities
      flattenedStudents: null,
      students: null,
      columnKeys: [], // what has been loaded onto each sdcStudent
      projections: [], // the projection needed to satisfy those columnKeys
      joins: [],
      // { filterModel: null, sortModel: null, columnKeys: null, columnState: null },
      gridStates: { },
    },
    networkFociGrid: {
      loaded: false,
      gridState: null,
    },
    studentMapGrowth: {
      loaded: false,
      loading: false,
      studentMapGrowthEntities: {},
    },
    studentAcadience: {
      loaded: false,
      loading: false,
      studentAcadienceEntities: {},
    },
    objectCache: {
      loaded: {},
      objectCache: {},
    },
    regentsResultsGrid: {
      loaded: false,
      gridState: null,
    },
    mockRegentsGrid: {
      loaded: false,
      gridState: null,
    },
    courseMarksGrid: {
      loaded: false,
      gridState: null,
    },
  },
} as any;
