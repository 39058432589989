import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { UtilitiesService } from './../../../shared/services/utilities/utilities.service';
import { CREATE_SUPPORT_SUCCESS, UPDATE_SUPPORT_SUCCESS } from './../../../store/actions/supports-actions';
import {
  TSortDirections,
  Direction,
  SortAndFilterService,
} from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import {
  UpdateStudentSupport,
  IUpdatePayload,
  UPDATE_STUDENT_SUPPORT_SUCCESS,
  UPDATE_STUDENT_SUPPORT_FAILURE,
  CREATE_STUDENT_SUPPORT_SUCCESS,
  CREATE_STUDENT_SUPPORT_FAILURE,
} from './../../../store/actions/student-supports-actions';
import {
  IStudentSupport,
  TValidStudentSupportBackendStatuses,
} from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { IConfirmModalComponentData } from 'Src/ng2/shared/modals/confirm/confirm-modal.component';
import { StudentSupportsPanelListDataService } from './student-supports-panel-list-data/student-supports-panel-list-data.service';
import { MadlibService } from '../services/madlib/madlib.service';
import { IFocus } from 'Src/ng2/school/tiles/interfaces/dashboard.interfaces';
import { ListHeaderService } from '../services/list-headers/list-headers.service';
import { IGroupData } from 'Src/ng2/shared/models/list-models';
import { Store, ActionsSubject } from '@ngrx/store';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Unsubscribable, throwError, of } from 'rxjs';
import { filter, switchMap, take, tap, map } from 'rxjs/operators';
import { FixedToInfiniteViewComponent } from '../../../shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { ObjectCache } from '../../../shared/services/object-cache/object-cache.service';
import { IListConfig } from '../../../shared/models/list-models';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from '../../../shared/services/background-job-notification-service/background-job-notification-service';
import { IListData } from '../../../shell/content-tools/content-tools.component';
import { ofType } from '@ngrx/effects';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import moment from 'moment';
import { ListNavigationService } from '../services/list-navigation/list-navigation.service';

@Component({
  selector: 'student-supports-panel-list',
  templateUrl: './student-supports-panel-list.component.html',
  styleUrls: ['./student-supports-panel-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// @ts-ignore
export class StudentSupportsPanelListContainer extends FixedToInfiniteViewComponent implements OnInit, OnDestroy {
  public isRefactoredList: boolean = true;
  public fociData$: Observable<any>;
  public madlibModel: FormGroup;
  public dataSource$: Observable<any>;
  public foci: IFocus;
  public fociByCategory: Array<{ categoryLabel: string; foci: IFocus[] }>;
  public activeList: 'INFINITE' | 'FIXED';

  // The background jobs does this view need to refetch on?
  public backgroundJobDependencies: TValidBackgroundJob[] = [
    'BulkStudentUpdate',
    'BulkStudentSupportUpdate',
    'SupportMiniSchemaUpdate',
  ];

  public storeEffectDependencies: any[] = [
    CREATE_SUPPORT_SUCCESS,
    UPDATE_SUPPORT_SUCCESS,
    CREATE_STUDENT_SUPPORT_SUCCESS,
    CREATE_STUDENT_SUPPORT_FAILURE,
    UPDATE_STUDENT_SUPPORT_SUCCESS,
    UPDATE_STUDENT_SUPPORT_FAILURE,
  ];

  // MetaData
  public listData: IListData;
  public pageHeaderMeta = {
    title: 'Supports',
    subTitle: null,
    icon: {
      tooltip: null,
    },
  };

  public schoolId: string;
  public showAllSchoolYears: boolean;

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function;
  public onRowClick: Function;
  public onBatchAction: Function;
  public uiRowData;
  public visualGroupingData: IGroupData;
  public onUiRowDataUpdate: Function;
  public onDynamicComponentClick: Function;

  public columns;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);

  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public updateSort: Function;

  public listConfig: IListConfig = {
    listType: 'SUPPORTS',
    noDataMessage: `No Portal supports assigned for SY${CurrentSchoolYear.START}-${CurrentSchoolYear.END}`,
    sortableColumns: true,
    allowEmptyTable: null,
  };

  public noDataMessage: any;
  public dynamicComponentInputData: any;

  // SubSinks
  public notificationServiceSub: Unsubscribable;
  public apiDependencySubscription: Unsubscribable;
  public getFociDataSub: Unsubscribable;
  public modalServiceSub: Unsubscribable;
  public actionsSubscription: Unsubscribable;
  public historicalDataSubscription: Unsubscribable;

  // NOTE: This component is required to implement every abstract member defined on super.
  // but for support settings list, we don't do anything with batchActions related code.
  // until a more ideal solution is determined, need to keep these two defined

  // Bindings
  @Input() student: IStudent;
  @Input() batchActionsMode$: Observable<any>;
  @Input() fociKey: 'STUDENT_SUPPORT_HISTORICAL_DATA' | 'SUPPORT_STUDENT_PROFILE';

  // Outputs to panel
  @Output() updateRows: EventEmitter<number> = new EventEmitter();
  @Output() historicalDataExists: EventEmitter<boolean> = new EventEmitter();

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  // DI
  constructor(
    public resolver: ComponentFactoryResolver,
    public madlibService: MadlibService,
    public objectCache: ObjectCache,
    public notificationService: BackgroundJobNotificationService,
    public studentSupportPanelDataService: StudentSupportsPanelListDataService,
    public modalsService: ModalsService,
    private listHeaderService: ListHeaderService,
    private store: Store<any>,
    private actionsSubject: ActionsSubject,
    private batchActionsService: BatchActionsService,
    private utils: UtilitiesService,
    private navigationService: ListNavigationService,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.student.isFirstChange()) {
      this.initializeStudentSupportsPanelList(this.schoolId, false);
    }
  }

  ngOnDestroy(): void {
    if (this.actionsSubscription) this.actionsSubscription.unsubscribe();
    if (this.notificationServiceSub) this.notificationServiceSub.unsubscribe();
    if (this.getFociDataSub) this.getFociDataSub.unsubscribe();
  }

  ngOnInit(): void {
    // Clear the cache on initialization. User *may* have changed schools.
    this.studentSupportPanelDataService.clearFociCache();
    // Set needed inputs since not getting them directly
    this.schoolId = this.student.schoolId;
    this.showAllSchoolYears = this.fociKey === 'STUDENT_SUPPORT_HISTORICAL_DATA';
    // Set initial state
    this.filterFormControl = new FormControl();
    this.sortKey$ = new BehaviorSubject('SUPPORT_NAME');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.asc);
    // Super props
    this.updateSort = (sortKey: string) => {
      SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
    };
    // Overwrite super methods -- how we respond to user events [batchActions & rowClick]
    this.onRowClick = ($event) => this.navigationService.goToSupportRoster(this.schoolId, $event); 
    this.onDynamicComponentClick = this.handleKebabAction;
    // Called by super; when in each of the list modes
    this.onFixed = this.updateCssForListType('FIXED');
    this.onInfinite = this.updateCssForListType('INFINITE');
    this.onUiRowDataUpdate = ($event: IGroupData) => {
      const groupings = Object.values($event);
      this.uiRowData = groupings;
    };
    // Listen for completed background jobs or patches and refetch data as needed
    this.notificationServiceSub = this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob }) => this.backgroundJobDependencies.includes(backgroundJob)),
        tap(() => this.initializeStudentSupportsPanelList(this.schoolId, false)),
      )
      .subscribe();

    // If opened from the historical modal on the profile, we don't want a duplicate subscription
    if (this.fociKey !== 'STUDENT_SUPPORT_HISTORICAL_DATA') {
      // refresh in response to create/update/delete on a student Support
      this.actionsSubscription = this.actionsSubject
        .pipe(
          ofType(
            CREATE_SUPPORT_SUCCESS,
            UPDATE_SUPPORT_SUCCESS,
            CREATE_STUDENT_SUPPORT_SUCCESS,
            UPDATE_STUDENT_SUPPORT_SUCCESS,
          ),
        )
        .subscribe(
          (data): any => {
            // @ts-ignore
            if (this.storeEffectDependencies.includes(data.type)) {
              this.initializeStudentSupportsPanelList(this.schoolId, false);
            }
          },
          err => throwError(err),
        );
    }
    // Kick off data pipeline `getFoci$---generateMadlibModel---getGrouping$---createListContainer--|`
    this.initializeStudentSupportsPanelList(this.schoolId, true);
  }

  // getFocusData---createMadlibModel---getGroupingData---createListContainer--|
  initializeStudentSupportsPanelList(schoolId: string, loadContainer: boolean): void {
    if (this.fociKey === 'SUPPORT_STUDENT_PROFILE') {
      // when displaying supports panel in the student profile, the following code will check for historical data
      this.historicalDataSubscription = this.getFociData$(schoolId)
        .pipe(
          tap(fociPartials => (this.fociByCategory = this.madlibService.getFormattedFoci(fociPartials))),
          switchMap(() => this.getFociData$(schoolId)),
          switchMap(([completeFocusData]) =>
            of(
              this.madlibService.setMadlibModel(
                this.madlibService.formatFocus(completeFocusData),
                completeFocusData.filters,
              ),
            ),
          ),
          tap(madlibModel => (this.madlibModel = madlibModel)),
          switchMap((madlibModel) => this.showHistoricalPortalDataBtn$(madlibModel))
        ).subscribe((val) => this.emitHistoricalData(val));
    }

    this.getFociDataSub = this.getFociData$(schoolId)
      .pipe(
        // Format and set the partials to fit the nv-dropdown
        tap(fociPartials => (this.fociByCategory = this.madlibService.getFormattedFoci(fociPartials))),
        // Once we know the default focus, fetch complete data to populate corresponding filter and grouping options
        switchMap(() => this.getFociData$(schoolId)),
        switchMap(([completeFocusData]) =>
          of(
            this.madlibService.setMadlibModel(
              this.madlibService.formatFocus(completeFocusData),
              completeFocusData.filters,
            ),
          ),
        ),
        // Set madlib model with focus containing all data
        tap(madlibModel => (this.madlibModel = madlibModel)),
        switchMap(madlibModel => this.getSupportGroupingData$(madlibModel)),
        // Set side-bar summary/csv-export etc.
        tap(groupingData => {
          this.updateGroupingDependentProps(groupingData);
          if (!this.showAllSchoolYears) {
            const $event = {
              groupData: this.groupingData$.value[0],
              sortKey: null,
              sortDirection: null,
            };
            if (loadContainer) this.createInfiniteStudentTableComponent($event);
            if (this.groupingData$.value[0].allowEmptyTable) {
              this.emitRowsToParent(0);
            } else {
              this.emitRowsToParent(this.groupingData$.value[0].rowData.length);
            }
          } else {
            if (loadContainer) this.createListContainer();
          }
        }),
      )
      .subscribe();
  }

  getSupportGroupingData$(madlibModel) {
    const groupingPayload: any = this.madlibService.getGroupingDataPayload(this.schoolId, madlibModel.value);
    groupingPayload.baseCollectionName = 'supports';
    groupingPayload.id = '';

    return this.studentSupportPanelDataService
      .getSupportGroupingData$({ ...groupingPayload, schoolId: this.schoolId }, this.student._id, this.showAllSchoolYears)
      .pipe(
        switchMap(({ data: { SupportGroupings } }) => {
          this.updateGroupingDependentProps(SupportGroupings);
          return of(SupportGroupings);
        }),
      );
  }

  showHistoricalPortalDataBtn$(madlibModel): Observable<any> {
    const groupingPayload: any = this.madlibService.getGroupingDataPayload(this.schoolId, madlibModel.value);
    return this.studentSupportPanelDataService.getAllHistoricalData$(groupingPayload, this.student._id).pipe(
      map(({ data: { SupportGroupings } }) => {
        return SupportGroupings.length > 0;
      }),
      take(1),
    );
  }

  updateCssForListType(listMode: 'INFINITE' | 'FIXED'): Function {
    return (instance?: ElementRef): void => {
      this.activeList = listMode;
      const addClassForListMode = listMode === 'INFINITE' ? 'infinite' : 'fixed';
      this.listContent.nativeElement.classList.add(addClassForListMode);
      const removeClassForListMode = listMode === 'INFINITE' ? 'fixed' : 'infinite';
      this.listContent.nativeElement.classList.remove(removeClassForListMode);
      this.pageHeaderMeta =
        listMode === 'INFINITE'
          ? { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getInfiniteHeaderIcon(instance) } }
          : { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getFixedHeaderIcon() } };
    };
  }

  getFociData$(schoolId: string): Observable<any> {
    return this.studentSupportPanelDataService.getSupportFocusData$(schoolId, this.fociKey).pipe(
      switchMap(({ data: { SupportFocus } }) => {
        return of(SupportFocus);
      }),
      take(1),
    );
  }

  updateGroupingDependentProps(groupingData): void {
    const { columns, columnIndexMap } = this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel);
    this.dynamicColumns.next(columns);
    this.dynamicColumnIndexMap.next(columnIndexMap);
    this.groupingData$.next(groupingData);
  }

  private updateStudentSupportStatus(id: string, status: TValidStudentSupportBackendStatuses): void {
    const patch: IUpdatePayload<IStudentSupport> = {
      id,
      patch: { status },
    };
    this.store.dispatch(new UpdateStudentSupport(patch));
  }

  private openUpdateSupportModal(studentSupport: IStudentSupport) {
    this.modalsService.openStudentSupportModalV2(studentSupport);
  }

  private getStudentName(): string {
    const {
      studentDetails: {
        name: { first, last },
      },
    } = this.student;
    const firstName = this.utils.toTitleCase(first);
    const lastName = this.utils.toTitleCase(last);
    return `${firstName} ${lastName}`;
  }

  private openMarkCompleteModal({ _id, support: { name } }) {
    const studentName = this.getStudentName();
    const data: IConfirmModalComponentData = {
      title: 'Complete support',
      message: `Are sure you want to mark <span>${studentName}</span> complete in the support <span>${name}</span>? This will set the ending day for this support for this student to today, ${moment().format('MMMM DD, YYYY')}, and you will be unable to take attendance or write notes on this support after this action is taken.`,
      subMessage: 'This action can\'t be undone.',
      confirmText: 'Complete support',
    };
    this.modalServiceSub = this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.updateStudentSupportStatus(_id, 'COMPLETED');
        }
      });
  }

  private openRemoveSupportModal({ _id, support: { name } }) {
    const studentName = this.getStudentName();
    const data: IConfirmModalComponentData = {
      title: 'Delete support record',
      message: `Are sure you want to delete the support record for <span>${studentName}</span> from the support <span>${name}</span>?`,
      subMessage: 'Any attendance logged and support-related history for this student will be deleted from their records. This action can\'t be undone.',
      confirmText: 'Delete',
    };
    this.modalServiceSub = this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.updateStudentSupportStatus(_id, 'DELETED');
        }
      });
  }

  handleKebabAction({ action, studentSupport }): void {
    switch (action) {
      case 'UPDATE_SUPPORT':
        this.openUpdateSupportModal(studentSupport);
        break;
      case 'MARK_SUPPORT_COMPLETE':
        this.openMarkCompleteModal(studentSupport);
        break;
      case 'DELETE_SUPPORT_RECORD':
        this.openRemoveSupportModal(studentSupport);
        break;
    }
  }

  emitRowsToParent(rows: number): void {
    this.updateRows.emit(rows);
  }

  emitHistoricalData(exists: boolean): void {
    this.historicalDataExists.emit(exists);
  }
}
