import { EventFormatterService } from './../../shared/services/mixpanel/event-formatter.service';
import { Injectable } from '@angular/core';
import { find, includes, result, uniq, without, reduce, mapValues } from 'lodash';
import { CurrentSchoolYear, TValidSchoolYear, getPastSchoolYearWithPrefix, getSchoolYearByDistrict } from 'Src/ng2/shared/constants/current-school-year.constant';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { IDropdownOption } from 'projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { LocalStorageService } from '../../shared/services/web-storage/local-storage/local-storage.service';
import StudentPanelsConfig, { TValidStudentTypes } from '../student-container/student-panels.config';
import { TValidFASubjectOptionKey } from './student-fa-panel/student-fa-panel.config';
import { TValidScreenersSubjectOptionKey } from './student-screener-panel/student-screeners-panel.config';
import { IStudent } from '../../shared/typings/interfaces/student.interface';
import { MixpanelService } from 'Src/ng2/shared/services/mixpanel/mixpanel.service';
import { TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';
import { Subject } from 'rxjs';

export type TValidStoredPanels =
  | 'student-snapshot-panel'
  | 'student-details-panel'
  | 'student-basic-info-panel'
  | 'student-contact-info-panel'
  | 'student-attendance-panel'
  | 'student-attendance-summer-panel'
  | 'student-supports-panel'
  | 'student-screeners-panel'
  | 'student-fa-panel'
  | 'student-academic-panel'
  | 'student-grad-planning-panel'
  | 'student-grad-panel-v3'
  | 'student-current-program-panel'
  | 'student-credits-panel'
  | 'student-credit-gaps-panel'
  | 'student-regents-panel'
  | 'student-point-people-panel'
  | 'student-postsec-panel'
  | 'student-transcript-and-exam-history-panel'
  | 'student-tasks-notes-panel'
  | 'student-notes-panel'
  | 'student-success-mentoring-panel'
  | 'attendance-panel'
  | 'attendance-summer-panel'
  | 'student-behavioral-incidents-panel';

export type TValidSubjectOptionKey = TValidScreenersSubjectOptionKey | TValidFASubjectOptionKey;

export interface IVisible {
  [propName: string]: {
    validForStudentType: boolean;
    validForSelectedSubject?: boolean;
    validForDistrict?: boolean;
  };
}

export interface ITileMessageProps {
  hasData: boolean,
  termName: string,
  tileSchoolYear?: TValidSchoolYear
}

@Injectable()
export class StudentPanelService {
  private listToggleSource$ = new Subject<number>();
  public listToggleState$ = this.listToggleSource$.asObservable();

  constructor (
    private localStorage: LocalStorageService,
    private util: UtilitiesService,
    private eventFormatterService: EventFormatterService,
    private mixpanelService: MixpanelService,
  ) { }

  callOnListToggle (index: number) {
    this.listToggleSource$.next(index);
  }

  // Deprecated, keep for reference
  getInitialPanelState (panelTitle: TValidStoredPanels): boolean {
    return includes(this.localStorage.getItem('ssvCollapsedPanels'), panelTitle);
  }

  getInitialPanelIndex (panelTitle: TValidStoredPanels): number {
    return this.localStorage.getItem('ssvPanelsIndex') ? this.localStorage.getItem('ssvPanelsIndex')[panelTitle] : 0;
  }

  setPanelIndex (panelTitle: TValidStoredPanels, index: number): void {
    const ssvPanelsIndexMap = this.localStorage.getItem('ssvPanelsIndex') || {};
    ssvPanelsIndexMap[panelTitle] = index;
    this.localStorage.setItem('ssvPanelsIndex', ssvPanelsIndexMap);
  }

  // Deprecated, keep for reference
  getOpenPanelsFromLocalStorage () {
    return this.localStorage.getItem('ssvOpenPanels');
  }

  // Deprecated, keep for reference
  getCollapsedPanelsFromLocalStorage () {
    const childPanelsToIgnoreAndReset = ['student-basic-info-panel', 'student-contact-info-panel', 'student-point-people-panel'];
    const storedCollapsedPanels = this.localStorage.getItem('ssvCollapsedPanels');
    if (!storedCollapsedPanels) return [];
    const validPanels = storedCollapsedPanels.filter((panel) => !childPanelsToIgnoreAndReset.includes(panel));
    this.localStorage.setItem('ssvCollapsedPanels', validPanels);
    return validPanels;
  }

  // Deprecated, keep for reference
  getOpenPanels () {
    const allPanels = this.getAllPanelNames();
    const collapsedPanels = this.getCollapsedPanelsFromLocalStorage() || [];
    // if a panel is not collapsed, we show it
    return allPanels.filter((panelName) => !collapsedPanels.includes(panelName));
  }

  // Deprecated, keep for reference
  getAllPanelNames () {
    return [
      'student-snapshot-panel',
      'student-details-panel',
      'student-basic-info-panel',
      'student-contact-info-panel',
      'student-attendance-panel',
      'student-attendance-summer-panel',
      'student-supports-panel',
      'student-screeners-panel',
      'student-fa-panel',
      'student-academic-panel',
      'student-grad-planning-panel',
      'student-grad-panel-v3',
      'student-current-program-panel',
      'student-credits-panel',
      'student-credit-gaps-panel',
      'student-regents-panel',
      'student-point-people-panel',
      'student-postsec-panel',
      'student-transcript-and-exam-history-panel',
      'student-tasks-notes-panel',
      'student-behavioral-incidents-panel',
      'student-success-mentoring-panel',
      'attendance-panel',
      'attendance-summer-panel',
      'student-notes-panel',
    ];
  }

  private trackPanelEvent (collapsed: boolean, panelId: string): void {
    const event = this.eventFormatterService.getStudentProfileActionEvent({
      action: 'Toggle panel',
      actionInfo: {
        panel: panelId,
        state: collapsed ? 'Expanded' : 'Collapsed',
      },
      portal: 'SCHOOL',
    });
    this.mixpanelService.trackEvents([event]);
  }

  // Deprecated, keep for reference
  togglePanelBody (collapsed: boolean, panelId: string): boolean {
    this.trackPanelEvent(collapsed, panelId);
    if (!panelId) console.warn(`Unable to find ${panelId}`);

    if (collapsed) {
      let collapsedPanels = JSON.parse(localStorage.getItem('ssvCollapsedPanels')) || [];
      let openPanels = JSON.parse(localStorage.getItem('ssvOpenPanels')) || [];

      collapsedPanels = without(collapsedPanels, panelId);
      openPanels.push(panelId);
      openPanels = uniq(openPanels);

      this.localStorage.setItem('ssvCollapsedPanels', collapsedPanels);
      this.localStorage.setItem('ssvOpenPanels', openPanels);
      return false;
    } else {
      let collapsedPanels = JSON.parse(localStorage.getItem('ssvCollapsedPanels')) || [];
      let openPanels = JSON.parse(localStorage.getItem('ssvOpenPanels')) || [];

      openPanels = without(openPanels, panelId);
      collapsedPanels.push(panelId);
      collapsedPanels = uniq(collapsedPanels);

      this.localStorage.setItem('ssvOpenPanels', openPanels);
      this.localStorage.setItem('ssvCollapsedPanels', collapsedPanels);
      return true;
    }
  }

  getStudentType (student: IStudent): TValidStudentTypes {
    if (student.isES) {
      return 'ES';
    }
    if (student.isMS) {
      return 'MS';
    }
    if (student.isHS) {
      return 'HS';
    }
  }

  getRelLvlStyle (relLvl): any {
    let style;
    switch (relLvl) {
      case '1 below':
        style = 'light-warning';
        break;
      case '2 below':
        style = 'warning';
        break;
      case '3+ below':
        style = 'medium-warning';
        break;
    }
    return style;
  }

  getPanelTitle (config: any): string {
    return config.title;
  }

  getSelectedFoci (config: any, fociOptions: IDropdownOption[]): string {
    const defaultFoci = config.fociOptions.PERFORMANCE_EACH_ADMIN.key;
    return fociOptions[0]?.key || defaultFoci;
  }

  getFociOptions (config: any): IDropdownOption[] {
    return reduce(config.fociOptions, (acc, foci) => {
      acc.push(foci);
      return acc;
    }, []);
  }

  getAssessmentDefaultSubjectOptions (studentType: TValidStudentTypes, config: any, district: string): IDropdownOption[] {
    return reduce(config.subjectOptions, (acc, { human, key, studentTypes, districts }) => {
      if (studentTypes.includes(studentType) && districts.includes(district)) {
        acc.push({
          human,
          key,
        });
      };
      return acc;
    }, []);
  }

  getSchoolYearOptions (years: TValidSchoolYear[]): IDropdownOption[] {
    const yearOptions = years.map((year) => {
      return {
        key: year,
        human: this.util.getShortenedSelectedSchoolYear(year),
      };
    });
    return yearOptions;
  }

  // TODO-jchu: currently, we use MAP Growth consts below to display the same school year options in the dropdown across screeners, but we may want to revisit the behavior at the screener's year flip and update the logic to handle it differntly based on each screener's data availability
  // TODO-rjou: set current year using postgres table once that is implemented
  getScreenersPanelYears (district?: TDistricts) : TValidSchoolYear[] {
    const currentYear: TValidSchoolYear = getSchoolYearByDistrict('map growth', district) as TValidSchoolYear;
    return ([
      currentYear,
      getPastSchoolYearWithPrefix(currentYear, 1) as TValidSchoolYear,
      getPastSchoolYearWithPrefix(currentYear, 2) as TValidSchoolYear,
      getPastSchoolYearWithPrefix(currentYear, 3) as TValidSchoolYear,
      getPastSchoolYearWithPrefix(currentYear, 4) as TValidSchoolYear,
    ]);
  }

  getVisible (config: any, opts: { studentType: TValidStudentTypes, selectedSubject?: string, district: string }): IVisible {
    const { studentType, selectedSubject, district } = opts;
    const visibles = mapValues(config, ({ studentTypes, applicableSubjectOptions, districts }) => {
      const isValidForStudentType = studentTypes.includes(studentType);
      const isValidForDistrict = districts.includes(district);
      if (applicableSubjectOptions) {
        const isValidForSelectedSubject = applicableSubjectOptions.includes(selectedSubject);
        return { 
          validForStudentType: isValidForStudentType,
          validForSelectedSubject: isValidForSelectedSubject, 
          validForDistrict: isValidForDistrict,
        };
      } else {
        return { 
          validForStudentType: isValidForStudentType,
          validForDistrict: isValidForDistrict,
        };
      }
    }) as IVisible;
    return visibles;
  }

  getSelectedSubject<T extends string> (opts: { defaultSubject: T, subjectOptions: IDropdownOption[], selectedSubject?: T }): T {
    const { defaultSubject, selectedSubject, subjectOptions } = opts;

    const prev = !!(selectedSubject && subjectOptions && subjectOptions.length);

    if (prev) {
      const found = !!subjectOptions.find((option) => option.key === selectedSubject);
      return found ? selectedSubject : defaultSubject;
    } else {
      return defaultSubject;
    }
  }

  getTileTermMessage (tileDetails: ITileMessageProps): string {
    const { hasData, termName } = tileDetails;
    const tileSchoolYear = tileDetails.tileSchoolYear ?? CurrentSchoolYear.SHORTENED_SY;
    const formattedSchoolYear = this.util.getShortenedSelectedSchoolYear(tileSchoolYear);
    if(hasData){
      return `${termName} ${formattedSchoolYear}`;
    }  
    return `No data for the ${termName} for ${formattedSchoolYear}`;
  }
}
