import { ISupportOptPayload } from './../../shared/modals/support/support-modal.interface';
import { ISchoolSupport } from './../../shared/typings/interfaces/support.interface';

import { Action } from '@ngrx/store';
/* tslint:disable */

export interface ICreateSupportPayload {
  schoolId: string;
  supportParams: ISupportOptPayload;
  requiresMadlibRefresh?: boolean;
  studentIds?: string[];
  view?: 'BATCH-ACTION' | 'STUDENT-PROFILE' | 'SETTINGS-LIST' | 'SUPPORT-LIST';
};
export const LOAD_SUPPORTS = '[Portal] Load Supports';
export const LOAD_SUPPORTS_SUCCESS = '[Portal] Load Supports Success';
export const LOAD_SUPPORTS_FAIL = '[Portal] Load Supports Failure';
export const CREATE_SUPPORT = '[Portal] Create Support';
export const CREATE_SUPPORT_SUCCESS = '[Portal] Create Support Success';
export const CREATE_SUPPORT_FAIL = '[Portal] Create Support Fail';
export const UPDATE_SUPPORT = '[Portal] Update Support';
export const UPDATE_SUPPORT_SUCCESS = '[Portal] Update Support Success';
export const UPDATE_SUPPORT_FAIL = '[Portal] Update Support Fail';

// register store actions
export class LoadSupports implements Action {
  readonly type = LOAD_SUPPORTS;
  constructor (public payload: { schoolId: string }) {}
}

export class LoadSupportsSuccess implements Action {
  readonly type = LOAD_SUPPORTS_SUCCESS;
  constructor (public payload: { data: ISchoolSupport[] }) {}
}

export class LoadSupportsFail implements Action {
  readonly type = LOAD_SUPPORTS_FAIL;
  constructor (public payload: any) {}
}

export class CreateSupport implements Action {
  readonly type = CREATE_SUPPORT;
  constructor (public payload: ICreateSupportPayload) {}
}

export class CreateSupportSuccess implements Action {
  readonly type = CREATE_SUPPORT_SUCCESS;
  constructor (public payload: any) {}
}

export class CreateSupportFail implements Action {
  readonly type = CREATE_SUPPORT_FAIL;
  constructor (public payload: any) {}
}

export class UpdateSupport implements Action {
  readonly type = UPDATE_SUPPORT;
  constructor (public payload: { support: Partial<ISchoolSupport>; patch: any; requiresMadlibRefresh: boolean }) {}
}

export class UpdateSupportSuccess implements Action {
  readonly type = UPDATE_SUPPORT_SUCCESS;
  constructor (public payload: any) {}
}

export class UpdateSupportFail implements Action {
  readonly type = UPDATE_SUPPORT_FAIL;
  constructor (public payload: any) {}
}

// action types
export type SupportsAction =
  | LoadSupports
  | LoadSupportsSuccess
  | LoadSupportsFail
  | CreateSupport
  | CreateSupportSuccess
  | CreateSupportFail
  | UpdateSupport
  | UpdateSupportSuccess
  | UpdateSupportFail;
