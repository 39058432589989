<!-- NOTE: `base-modal-shell` will be wrapped in a parent class set on modals.service.ts -->
<div class="base-modal-shell">
  <div class="dialog-header">
    <div class="close-button-right" *ngIf="!childHasSecondPage">
      <h1 class="dialog-title">
        {{ title }}
        <span *ngIf="titleTooltipInfo" nvTooltip [tooltipData] = "titleTooltipInfo">
          <nv-icon name="info-small-hover" [disabled]="isDisabled"></nv-icon>
        </span>
      </h1>
      <nv-icon-button [name]="'close-large-blue'" (clickButton)="close()"></nv-icon-button>
    </div>
    <div class="arrow-button-left" *ngIf="childHasSecondPage">
      <nv-icon-button [name]="'arrow-left-default'" (clickButton)="goBack()"></nv-icon-button>
      <h1 class="dialog-title">
        {{ title }}
        <span *ngIf="titleTooltipInfo" nvTooltip [tooltipData] = "titleTooltipInfo">
          <nv-icon name="info-small-hover" [disabled]="isDisabled"></nv-icon>
        </span>
      </h1>
    </div>
    <p class="dialog-subtitle" *ngIf="!isProfileMode">
      {{ itemCount }}
      <span [ngPlural]="itemCount">
        <ng-template ngPluralCase="=1">{{ itemType }}</ng-template>
        <ng-template ngPluralCase="other">{{ itemType }}s</ng-template>
      </span>
      selected
    </p>
    <p class="dialog-subtitle" *ngIf="subtitle">{{ subtitle }}</p>
  </div>
  <div class="dialog-body"><ng-content></ng-content></div>
  </div>
  