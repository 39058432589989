import { Component, ElementRef, forwardRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IClusterUserPermissions } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { unsubscribeComponent } from '../../../../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IGroupData, IRowData } from '../../../../../models/list-models';
import { ApiService } from '../../../../../services/api-service/api-service';
import { HelpDeskService } from '../../../../../services/help-desk/help-desk.service';
import { ImUser } from '../../../../../services/im-models/im-user';
import { SnackBarService } from '../../../../../services/snackbar/snackbar.service';
import { UserManagementSharedService } from '../../../../../services/user-management/user-management.shared.service';
import { IDropdownOption } from '../../../../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { MODALS_CONFIG_COMMON_MARKUP, MODALS_CONFIG_WIDE_NO_PADDING } from '../../../../modals.config';
import { ClusterUserModalShellService } from '../cluster-user-modal-shell.service';
import {
  CLUSTER_USER_BTN_CONFIG,
  SCHOOL_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG,
  IClusterUserModalShellData,
  TClusterUserModalViewMode,
} from '../cluster-user-modals.config';
import { ClusterUserModalsDataService } from '../cluster-user-modals.data.service';

@Component({
  templateUrl: './cluster-user-modal-shell.component.html',
  styleUrls: ['./cluster-user-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ClusterUserModalShellComponent implements OnInit {
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;

  // FOR TEMPLATE RENDERING AND INTERPOLATION
  public clusterDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  public portfolioGroupings$: BehaviorSubject<null | IGroupData[]> = new BehaviorSubject(null);
  public needsHelpLink: boolean;
  public emailDupesErrMsg: string;
  public generalErrMsg: string;
  public warningContent: string;
  public title: 'Create User' | 'Edit User';
  public iconName: string;
  public primaryBtnName: string;
  accessLevels: IDropdownOption[] = [
    {
      key: 'no_access',
      human: 'No access',
    },
    {
      key: 'network_only',
      human: 'Network level only',
    },
    {
      key: 'network_and_school',
      human: 'Network and school level',
    },
  ];

  public selectedAccessLevelKey: string;
  public originalUserAccessLevelKey: string;
  public userBasicForm: FormGroup;
  public reqEmailPlaceholder: string;
  public reqEmailType: string;
  public optEmailPlaceholder: string | null = null;
  public optEmailType: string | null = null;
  public showPrivilegeBox: boolean;

  // PASSDOWN TO CHILD
  user: {
    name: string;
    id: string | null;
  };

  public portfolioSeconIcon: 'close-large-blue' | 'arrow-left-default';
  portfolioPrimaryBtn: string;

  // NOT FOR VIEW - LOCAL STATE MAINTAINING AND MANAGEMENT
  private prevViewMode: TClusterUserModalViewMode;
  private emailDupes: string[] = [];
  private currentGrouping: IGroupData[]; // keep track of cluster user schools groupings, api related, shape of list row data
  private permissions: IClusterUserPermissions; // keep track of portfolio permissions, api related, shape of portfolio schema
  private apiMode: TClusterUserModalViewMode;

  // CONSTANTS
  readonly DOE_EMAIL_DOMAINS = ['schools.nyc.gov'];
  readonly CUSTOM_ERRS = {
    'User already exists in this network.': { needsSupport: false },
    'User already exists in another school network.': { needsSupport: true },
    'User already exists as a school user.': { needsSupport: true },
  };

  readonly commonMarkupClass = MODALS_CONFIG_COMMON_MARKUP.panelClass;
  readonly noPaddingWideClass = MODALS_CONFIG_WIDE_NO_PADDING.panelClass;
  private helpDeskService: HelpDeskService;
  readonly portfolioListComponentConfig = SCHOOL_CLUSTER_PORTFOLIO_LIST_COMPONENT_CONFIG;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IClusterUserModalShellData,
    @Inject(forwardRef(() => HelpDeskService)) helpDeskService: HelpDeskService,
    private apiService: ApiService,
    private imUser: ImUser,
    private shellService: ClusterUserModalShellService,
    private snackBarService: SnackBarService,
    private userMgmtSharedService: UserManagementSharedService,
    public dataService: ClusterUserModalsDataService,
    public dialogRef: MatDialogRef<ClusterUserModalShellComponent>,
  ) {
    this.helpDeskService = helpDeskService;
  }

  ngOnInit (): void {
    switch (this.data.mode) {
      case 'CREATE':
        this.getClusterDetail();
        break;
      case 'EDIT':
        this.getClusterUserDetail();
        break;
      case 'PORTFOLIO':
        this.getPortfolioGroupings();
        break;
      default:
        break;
    }
  }

  private getClusterDetail (): void {
    const params = {
      type: 'school',
      clusterId: this.data.clusterId,
    };
    const projections = ['approvedDomains', 'loginMethod'];
    this.apiService
      .getCluster({ params, projections })
      .pipe(
        tap((res: any) => {
          const { approvedDomains, loginMethod } = res;
          const clusterDetail = {
            loginMethod,
            approvedDomains,
          };
          this.initViewForBasicModal({ clusterDetail });
          this.clusterDetail$.next(clusterDetail);
        }),
      )
      .subscribe();
  }

  private getClusterUserDetail (): void {
    const userId = this.data.user ? this.data.user.id : null;
    this.dataService
      .getClusterUserDetail({ userId })
      .pipe(
        tap((res: any) => {
          const {
            data: {
              SchoolClusterUserDetail: { userBasic, approvedDomains, loginMethod },
            },
          } = res;
          const clusterDetail = {
            loginMethod,
            approvedDomains,
          };
          this.initViewForBasicModal({ clusterDetail, userBasic });
          this.clusterDetail$.next(clusterDetail);
        }),
      )
      .subscribe();
  }

  private initViewForBasicModal ({ clusterDetail, userBasic = null }): void {
    switch (this.data.mode) {
      case 'CREATE':
        this.title = 'Create User';
        this.primaryBtnName = CLUSTER_USER_BTN_CONFIG.CREATE;
        this.iconName = 'close-large-blue';
        this.apiMode = 'CREATE';
        break;
      case 'EDIT':
        this.title = 'Edit User';
        this.primaryBtnName = CLUSTER_USER_BTN_CONFIG.EDIT;
        this.iconName = 'close-large-blue';
        this.apiMode = 'EDIT';
        break;
      default:
        break;
    }
    this.initFormControls({ clusterDetail, userBasic });
    this.selectedAccessLevelKey = this._getAccessLevelDropdown({ userBasic });
    this.originalUserAccessLevelKey = this.selectedAccessLevelKey;
    this.showPrivilegeBox = this._shouldShowPrivilegeBox();
  }

  private getPortfolioGroupings (): void {
    const userId = this.data.user ? this.data.user.id : null;
    const clusterId = this.isMultiMode() && this.fromCreateMode() ? this.data.clusterId : null;
    this.dataService
      .getPortfolioGroupings({ userId, clusterId })
      .pipe(
        tap((res: any) => {
          const {
            data: { ClusterUserSchools: groupings },
          } = res;
          this.initViewForPortfolioModal();
          this.portfolioGroupings$.next(groupings);
        }),
      )
      .subscribe();
  }

  private isMultiMode (): boolean {
    return this.prevViewMode && this.prevViewMode !== this.data.mode;
  }

  private fromCreateMode (): boolean {
    return this.prevViewMode && this.prevViewMode === 'CREATE';
  }

  private initViewForPortfolioModal (): void {
    if (this.isMultiMode()) {
      if (this.fromCreateMode()) {
        this.portfolioPrimaryBtn = CLUSTER_USER_BTN_CONFIG.CREATE_LONG;
        const firstNameFormControl = this.userBasicForm.controls.firstName;
        const lastNameFormControl = this.userBasicForm.controls.lastName;
        this.user = {
          name: this.shellService.getUserFullName({ firstNameFormControl, lastNameFormControl }),
          id: null,
        };
      } else {
        this.portfolioPrimaryBtn = CLUSTER_USER_BTN_CONFIG.EDIT_LONG;
        this.user = this.data.user;
      }
      this.portfolioSeconIcon = 'arrow-left-default';
    } else {
      this.portfolioSeconIcon = 'close-large-blue';
      this.portfolioPrimaryBtn = CLUSTER_USER_BTN_CONFIG.EDIT_LONG;
      this.user = this.data.user;
      this.apiMode = 'EDIT';
    }
  }

  private initFormControls ({ clusterDetail, userBasic }): void {
    this.userBasicForm = new FormGroup({});
    const {
      firstNameControl,
      lastNameControl,
      titleControl,
      canManageUsersControl,
    } = this.shellService.getNonEmailFormControls({ userBasic });
    const emailDupes = this.emailDupes;
    this.userBasicForm.registerControl('firstName', firstNameControl);
    this.userBasicForm.registerControl('lastName', lastNameControl);
    this.userBasicForm.registerControl('title', titleControl);
    this.userBasicForm.registerControl('canManageUsers', canManageUsersControl);
    const {
      requireEmailControl: { emailType: reqEmailType, placeholder: reqEmailPlaceholder, formControl: reqEmailControl },
      optionalEmailControl,
    } = this.shellService.getEmailFormControls({ userBasic, clusterDetail, emailDupes });
    this.reqEmailType = reqEmailType;
    this.reqEmailPlaceholder = reqEmailPlaceholder;
    this.userBasicForm.registerControl(reqEmailType, reqEmailControl);
    if (optionalEmailControl) {
      const {
        emailType: optEmailType,
        placeholder: optEmailPlaceholder,
        formControl: optEmailControl,
      } = optionalEmailControl;
      this.optEmailType = optEmailType;
      this.optEmailPlaceholder = optEmailPlaceholder;
      this.userBasicForm.registerControl(optEmailType, optEmailControl);
    }
  }

  private _getAccessLevelDropdown ({ userBasic }): string {
    // if it is create mode, userBasic is not defined, set to 'no_access' by default
    // if it is edit mode, userBasic is defined, if delegatedRole is 'null', set to 'no_access'
    return (userBasic && userBasic.delegatedRole) || 'no_access';
  }

  // Decide whether to show privilege check-box
  private _shouldShowPrivilegeBox (): boolean {
    // only show it when:
    // 1. current user is cluster admin
    // 2. and, the new user to be created has access level - i.e. not `no access` type
    const currentUserIsAdmin =
      this.imUser.isSuperAdmin(this.data.currentUser) || this.imUser.isClusterAdmin(this.data.currentUser);
    const userHasAccessLevel = this.selectedAccessLevelKey !== 'no_access';
    return currentUserIsAdmin && userHasAccessLevel;
  }

  // EVENT HANDLERS

  public onSelectAccessLevel ($key: string): void {
    if (this.userBasicForm.pristine) {
      this.userBasicForm.markAsDirty();
    }
    this.selectedAccessLevelKey = $key;
    this.showPrivilegeBox = this._shouldShowPrivilegeBox();
    this._setWarningContent();
    if ($key === 'network_and_school') {
      this.primaryBtnName = CLUSTER_USER_BTN_CONFIG.NEXT;
    } else {
      this.primaryBtnName = this.data.mode === 'CREATE' ? CLUSTER_USER_BTN_CONFIG.CREATE : CLUSTER_USER_BTN_CONFIG.EDIT;
    }
  }

  public onClickPrimaryBtn (): void {
    switch (this.primaryBtnName) {
      case CLUSTER_USER_BTN_CONFIG.CREATE:
      case CLUSTER_USER_BTN_CONFIG.EDIT:
        this._prepPayloadForApi();
        break;
      case CLUSTER_USER_BTN_CONFIG.NEXT:
        this.prevViewMode = this.data.mode;
        this.data.mode = 'PORTFOLIO';
        this.dialogRef.removePanelClass(this.commonMarkupClass);
        this.dialogRef.addPanelClass(this.noPaddingWideClass);
        if (this.currentGrouping) {
          this.portfolioGroupings$.next(this.currentGrouping);
        } else {
          this.getPortfolioGroupings();
        }
        break;
      default:
        break;
    }
  }

  public onPortfolioPrimaryBtn ({ permissions }): void {
    this.permissions = permissions;
    this._prepPayloadForApi();
  }

  public onPortfolioSeconBtn ({ currentGrouping }): void {
    if (this.isMultiMode()) {
      this._goToDetailsView();
      this.currentGrouping = currentGrouping;
    } else {
      this.dialogRef.close();
    }
  }

  private _goToDetailsView (): void {
    this.data.mode = this.prevViewMode;
    this.dialogRef.removePanelClass(this.noPaddingWideClass);
    this.dialogRef.addPanelClass(this.commonMarkupClass);
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onClearInput (controlName: string): void {
    this.userBasicForm.controls[controlName].setValue('');
    this.userBasicForm.controls[controlName].markAsDirty();
  }

  public getHelp (): void {
    this.helpDeskService.showHelp();
  }

  // API CALLS

  private _prepPayloadForApi (): void {
    let userPayload = {};
    if (this.userBasicForm) {
      userPayload = {
        ...this.userBasicForm.value,
        delegatedRole: this.selectedAccessLevelKey,
      };
    }
    userPayload = { ...userPayload, permissions: this.permissions };
    if (this.data.user) {
      const userId = this.data.user.id;
      userPayload = { ...userPayload, userId };
    }
    if (
      this.primaryBtnName === CLUSTER_USER_BTN_CONFIG.CREATE ||
      this.portfolioPrimaryBtn === CLUSTER_USER_BTN_CONFIG.CREATE_LONG
    ) {
      userPayload = { ...userPayload, clusterId: this.data.clusterId };
    }
    this._sendToApi({ userPayload });
  }

  private _sendToApi ({ userPayload }): void {
    this.apiService
      .mutateClusterUser({ userPayload, columns: this.data.schoolClusterUserGroupingColumns }, this.apiMode)
      .pipe(
        catchError(error => {
          this.snackBarService.showDangerToastWithCloseButton({
            toastText: 'Unable to update the user’s portfolio. Please try again.',
            isDanger: true,
          });
          return throwError(error);
        }),
        tap((res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }) => {
          this._onApiRes(res);
        }),
      )
      .subscribe();
  }

  private _onApiRes (res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }): void {
    const { data, errors } = res;
    if (errors && errors[0]) {
      const {
        extensions: {
          exception: { data },
        },
        message: errMessage,
      } = errors[0];
      this._resetErrMessages();
      const isDupesErr = !!this.CUSTOM_ERRS[errMessage];
      if (isDupesErr) {
        this._setDupesErr(errMessage, data.devMessage);
      } else {
        this.generalErrMsg = errMessage;
      }
      if (this.isMultiMode()) {
        this._goToDetailsView();
        // TODO-JCHU: what if there is an error when trying updaging the protfolio on portfolio mode (does not have this.prevViewMode)
      }
    } else if (data && Object.keys(data).length) {
      const groupData: IRowData[][] = data.createSchoolClusterUser || data.updateSchoolClusterUser;
      this.dialogRef.close(groupData);
    }
  }

  private _resetErrMessages (): void {
    this.generalErrMsg = '';
    this.emailDupesErrMsg = '';
  }

  private _setDupesErr (errMessage, devMessage): void {
    this.needsHelpLink = this.CUSTOM_ERRS[errMessage].needsSupport;
    this.emailDupesErrMsg = errMessage;
    const emailType = this._getEmailTypeForDupesErr(devMessage);
    this.userBasicForm.controls[emailType].setErrors({ dupes: true });
    this.emailDupes.push(this.userBasicForm.controls[emailType].value.trim().toLowerCase());
  }

  private _getEmailTypeForDupesErr (devMessage): string {
    let emailType = '';
    if (devMessage.match('gafeEmail')) {
      emailType = 'gafeEmail';
    }
    if (devMessage.match('doeEmail')) {
      emailType = 'doeEmail';
    }
    return emailType;
  }

  private _setWarningContent (): void {
    const settingUserToNoAccess =
      this.originalUserAccessLevelKey !== this.selectedAccessLevelKey && this.selectedAccessLevelKey === 'no_access';
    const warningContent = settingUserToNoAccess
      ? this.userMgmtSharedService.getHybridClusterUserWarningContent({
        // .shelterClusterAdmins will be null if the target user is not hybrid cluster type
        admins: this.data.hybridClusterUserContext.shelterClusterAdmins,
        contextPartnerType: 'school',
        mode: 'EDIT',
      })
      : '';
    this.warningContent = warningContent;
  }
}
