export const DASHBOARD_STUDENTS_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.classOf': true,
  'studentDetails.currGradeLevel': true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  schoolStatus: true,
  'studentDetails.officialClass': true,
  'studentDetails.grade': true,
  isMS: true,
  isHS: true,
  isES: true,
  pointPeople: true,
  'spedDetails.isSped': true,
  'ellDetails.isEll': true,
  otherStaff: true,
  'att.ytd': true,
  'att.today': true,
  'creditDetails.GPA': true,
  'postsecondary.isELACR': true,
  'postsecondary.isMatchCR': true,
  'postsecondary.PSAT.totalMax': true,
  'postsecondary.SAT.total1600Max': true,
  'postsecondary.ACT.compositeMax': true,
  'regentsDetails.byCategory5.ela': true,
  'regentsDetails.byCategory5.math': true,
};
export const DASHBOARD_STUDENTS_JOINS = [];

export const DASHBOARD_STUDENT_SUPPORTS_PROJECTION = {
  _id: true,
  'student.studentId': true,
  'support.category': true,
  'support.metaData': true,
};
export const DASHBOARD_STUDENT_SUPPORTS_JOINS = [];

export interface IDashboardStudent {
  _id: string;
  studentId: string;
  currProgram: any;
  studentDetails: {
    classOf: string | null;
    currGradeLevel: string | null;
    officialClass: string | null;
    grade: string | null;
  };
  gradPlanningDetails: {
    plannedGraduationDate: string;
    plannedDiplomaType: string;
  };
  schoolStatus: string;
  isMS: boolean;
  isHS: boolean;
  isES: boolean;
  pointPeople: any;
  spedDetails: {
    isSped: boolean | null;
  };
  ellDetails: {
    isEll: boolean | null;
  };
  otherStaff: any;
  att: {
    ytd: string;
    today: string;
  };
  creditDetails: {
    GPA: number;
  };
  postSecondary: {
    isELACR: boolean;
    isMatchCR: boolean;
    PSAT: { totalMax: number | null };
    SAT: { total1600Max: number | null };
    ACT: { compositeMax: number | null };
  };
}
