import { Injectable } from '@angular/core';
import { IDropdownOption } from '../../../../../projects/shared/nvps-libraries/design/interfaces/design-library.interface';
import { TValidMockRegentsReports } from '../../constants/pdf-reports.constant';

@Injectable()
export class MockRegentsService {
  constructor () {};

  public removeOtherMockRegents (reportOptions: Array<IDropdownOption>, currentExam) : Array<IDropdownOption> {
    return reportOptions.map((option) => {
      if (option.key === 'mockRegents') {
        option.options = option.options.filter((option) => option.key === currentExam);
      }
      return option;
    });
  };
}
