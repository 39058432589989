/* tslint:disable */
import { Action } from '@ngrx/store';
import { IStudentSupport } from '../../shared/typings/interfaces/student-support.interface';
import { ISchoolSupport } from 'Src/ng2/shared/typings/interfaces/support.interface';
import { IStudentSupportUpdatePayload } from '..';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/nv-actions/nv-actions.interface';

export const LOAD_STUDENT_SUPPORTS = '[Portal] Load Student Supports';
export const LOAD_STUDENT_SUPPORTS_SUCCESS = '[Portal] Load Student Supports Success';
export const LOAD_STUDENT_SUPPORTS_FAIL = '[Portal] Load Student Supports Failure';
export const CREATE_STUDENT_SUPPORT = '[Portal] Create Student Support';
export const CREATE_STUDENT_SUPPORT_SUCCESS = '[Portal] Create Student Support Success';
export const CREATE_STUDENT_SUPPORT_FAILURE = '[Portal] Create Student Support Failure';
export const UPDATE_STUDENT_SUPPORT = '[Portal] Update Student Support';
export const UPDATE_STUDENT_SUPPORT_SUCCESS = '[Portal] Update Student Support Success';
export const UPDATE_STUDENT_SUPPORT_FAILURE = '[Portal] Update Student Support Failure';
export const BULK_CREATE_STUDENT_SUPPORTS = '[Portal] Bulk Create Student Supports';
export const BULK_CREATE_STUDENT_SUPPORTS_SUCCESS = '[Portal] Bulk Create Student Supports Success';
export const BULK_CREATE_STUDENT_SUPPORTS_FAILURE = '[Portal] Bulk Create Student Supports Failure';
export const BULK_UPDATE_STUDENT_SUPPORTS = '[Portal] Bulk Update Student Supports';
export const BULK_UPDATE_STUDENT_SUPPORTS_SUCCESS = '[Portal] Bulk Update Student Supports Success';
export const BULK_UPDATE_STUDENT_SUPPORTS_FAILURE = '[Portal] Bulk Update Student Supports Failure';
export const STUDENT_SUPPORTS_BACKGROUND_JOB_STARTED = '[Portal] Student Supports Background Job Started';
export const STUDENT_SUPPORTS_BACKGROUND_JOB_COMPLETED = '[Portal] Student Supports Background Job Completed';

export interface IUpdatePayload<T> {
  id: string;
  patch: Partial<T>;
}

export interface IBulkCreateStudentSupports {
  support: ISchoolSupport;
  startsOn: string;
  endsOn: string;
  studentIds: string[];
  origin?: TBatchActionsOrigin;
}

// register store actions
export class LoadStudentSupports implements Action {
  readonly type = LOAD_STUDENT_SUPPORTS;
  constructor(public payload: any) {}
}

export class LoadStudentSupportsSuccess implements Action {
  readonly type = LOAD_STUDENT_SUPPORTS_SUCCESS;
  constructor(public payload: IStudentSupport[]) {}
}

export class LoadStudentSupportsFail implements Action {
  readonly type = LOAD_STUDENT_SUPPORTS_FAIL;
  constructor(public payload: any) {}
}

export class UpdateStudentSupport implements Action {
  readonly type = UPDATE_STUDENT_SUPPORT;
  constructor(public payload: any) {}
}

export class UpdateStudentSupportSuccess implements Action {
  readonly type = UPDATE_STUDENT_SUPPORT_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateStudentSupportFail implements Action {
  readonly type = UPDATE_STUDENT_SUPPORT_FAILURE;
  constructor(public payload: any) {}
}

export class CreateStudentSupport implements Action {
  readonly type = CREATE_STUDENT_SUPPORT;
  constructor(
    public payload: { support: ISchoolSupport; studentId: string; schoolId: string; startsOn: string; endsOn: string },
  ) {}
}
export class CreateStudentSupportSuccess implements Action {
  readonly type = CREATE_STUDENT_SUPPORT_SUCCESS;
  constructor(public payload: IStudentSupport) {}
}

export class CreateStudentSupportFailure implements Action {
  readonly type = CREATE_STUDENT_SUPPORT_FAILURE;
  constructor(public payload: any) {}
}

// BULK
export class BulkCreateStudentSupports implements Action {
  readonly type = BULK_CREATE_STUDENT_SUPPORTS;
  constructor(public payload: IBulkCreateStudentSupports) {}
}

export class BulkCreateStudentSupportsSuccess implements Action {
  readonly type = BULK_CREATE_STUDENT_SUPPORTS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkCreateStudentSupportsFail implements Action {
  readonly type = BULK_CREATE_STUDENT_SUPPORTS_FAILURE;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentSupports implements Action {
  readonly type = BULK_UPDATE_STUDENT_SUPPORTS;
  constructor(public payload: IStudentSupportUpdatePayload) {}
}

export class StudentSupportScheduleUpdates implements Action {
  readonly type = BULK_UPDATE_STUDENT_SUPPORTS;
  constructor (public payload: IStudentSupportUpdatePayload) {};
};

export class BulkUpdateStudentSupportsSuccess implements Action {
  readonly type = BULK_UPDATE_STUDENT_SUPPORTS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentSupportsFail implements Action {
  readonly type = BULK_UPDATE_STUDENT_SUPPORTS_FAILURE;
  constructor(public payload: any) {}
}
export class StudentSupportsBackgroundJobStarted implements Action {
  readonly type = STUDENT_SUPPORTS_BACKGROUND_JOB_STARTED;
  constructor(public payload: { status: boolean }) {}
}

export class StudentSupportsBackgroundJobCompleted implements Action {
  readonly type = STUDENT_SUPPORTS_BACKGROUND_JOB_COMPLETED;
  constructor(public payload: { status: boolean }) {}
}
// action types
export type StudentSupportsAction =
  | LoadStudentSupports
  | LoadStudentSupportsFail
  | LoadStudentSupportsSuccess
  | UpdateStudentSupport
  | UpdateStudentSupportSuccess
  | UpdateStudentSupportFail
  | CreateStudentSupport
  | CreateStudentSupportSuccess
  | CreateStudentSupportFailure
  | BulkCreateStudentSupports
  | BulkCreateStudentSupportsSuccess
  | BulkCreateStudentSupportsFail
  | BulkUpdateStudentSupports
  | BulkUpdateStudentSupportsSuccess
  | BulkUpdateStudentSupportsFail
  | StudentSupportsBackgroundJobStarted
  | StudentSupportsBackgroundJobCompleted;
