import { Injectable } from '@angular/core';
import { IListColumnData } from '../../shared/typings/interfaces/list-view.interface';
import { COLUMN_CALCULATION_TYPE, COLUMN_DATA_TYPE } from './../../shared/constants/list-view/cell-type.constant';
import { ListCellFormattingService } from './../lists/list-display/list-cell-formatting.service';

@Injectable()
export class GraphListService {
  constructor(
    private listCellFormattingService: ListCellFormattingService,
  ) {}

  formatColumnData(columnType, columnData, cellConfig) {
    columnData = columnData ? columnData.data : columnData;
    switch (columnType) {
      case COLUMN_DATA_TYPE.GENERIC:
        return this.listCellFormattingService.genericFormatter(columnData);

      case COLUMN_DATA_TYPE.ARRAY:
        return this.listCellFormattingService.arrayFormatter(columnData);

      case COLUMN_DATA_TYPE.PERCENTAGE:
        return this.listCellFormattingService.percentageFormatter(columnData);

      case COLUMN_DATA_TYPE.POINT_PERSON:
        return this.listCellFormattingService.pointPersonFormatter(columnData);

      default:
        let formattedData: IListColumnData = {
          data: columnData,
          style: 'center',
        };
        return formattedData;
    }
  }
}
