import { NvSecondaryTileModule } from '../nv-category-container-items/nv-secondary-tile/nv-secondary-tile.module';
import { NvCategoryContainerComponent } from './nv-category-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTertiaryActionTileModule } from '../nv-category-container-items/nv-tertiary-action-tile/nv-tertiary-action-tile.module';
import { NvTertiarySummaryTileModule } from '../nv-category-container-items/nv-tertiary-summary-tile/nv-tertiary-summary-tile.module';
import { NvPrimaryTileModule } from '../nv-category-container-items/nv-primary-tile/nv-primary-tile.module';
import { NvVizTileModule } from '../nv-category-container-items/nv-viz-tile/nv-viz-tile.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NvSharedTooltipModule } from '../../../../projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NvIconModule } from '../../../../projects/shared/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvNoteSummerModule } from '../nv-note-summer/nv-note-summer.module';

@NgModule({
  imports: [
    CommonModule,
    NvTertiaryActionTileModule,
    NvTertiarySummaryTileModule,
    NvPrimaryTileModule,
    NvSecondaryTileModule,
    NvVizTileModule,
    NgxSkeletonLoaderModule,
    NvSharedTooltipModule,
    NvIconModule,
    NvNoteSummerModule,
  ],
  declarations: [NvCategoryContainerComponent],
  exports: [NvCategoryContainerComponent],
})
export class NvCategoryContainerModule {}
