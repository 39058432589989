import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTertiarySummaryTileComponent } from './nv-tertiary-summary-tile.component';
import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [CommonModule, NvSharedTooltipModule, NgxSkeletonLoaderModule],
  declarations: [NvTertiarySummaryTileComponent],
  exports: [NvTertiarySummaryTileComponent],
})
export class NvTertiarySummaryTileModule {}
