import { find } from 'lodash';
import { ISubjectArea, SubjectAreas } from '../../../ng2/shared/constants/subject-areas.constant';
import { IStudentSupport } from '../typings/interfaces/student-support.interface';

function _getSubjectsWithSupportMap(student: { activeStudentSupports: IStudentSupport[] }): { [key: string]: boolean } {
  const { activeStudentSupports } = student;
  return activeStudentSupports.reduce((subjectsWithSupport, studentSupport) => {
    const {
      support: { category, metaData },
    } = studentSupport;

    const isAcademicSupport = category === 'ACADEMIC';
    const hasMetaData = metaData;

    if (!isAcademicSupport || !hasMetaData) return subjectsWithSupport;
    // get subjectArea constant from support.metaData.subject --
    // these are misaligned to currProgram.grade, so mapping is necessary
    const subjectAreaData: ISubjectArea = find(SubjectAreas, { humanShort: metaData.subject });
    if (!subjectAreaData) return subjectsWithSupport;

    const { key: subjectKey } = subjectAreaData;
    subjectsWithSupport[subjectKey] = true;

    return subjectsWithSupport;
  }, {});
}

function _getFailuresWithNoSupportCount(
  student: {
    currProgram: {
      grades: Array<{ subj: string; pf: string; mostRecent: boolean; priority: string }>;
    };
  },
  subjectsWithSupportMap: { [key: string]: boolean },
): number {
  const {
    currProgram: { grades },
  } = student;
  return grades.reduce((failedWithoutSupportCount, { subj, pf, mostRecent, priority }) => {
    const isFailing = pf === 'F';
    const hasNoSupport = !subjectsWithSupportMap[subj];
    const isHighPriority = priority === 'high';
    const isMostRecentGrade = mostRecent;
    if (isFailing && hasNoSupport && isHighPriority && isMostRecentGrade) failedWithoutSupportCount++;
    return failedWithoutSupportCount;
  }, 0);
}

export function getHelperFunctionsForTests() {
  return {
    _getFailuresWithNoSupportCount,
    _getSubjectsWithSupportMap,
  };
}

export const DEPRECATED_GRAPHS = {
  CREATED_POSTSECONDARY_LIST: true,
  APPLIED_TO_ALL_COLLEGES: true,
  ACCEPTED_TO_ONE_OR_MORE_COLLEGES: true,
  HAVE_FINAL_DECISION: true,
  DAILY_ATT: true,
  ABSENT_TODAY: true,
  NEEDS_ATT_SUPPORT: true,
  NEEDS_MATH_SUPPORT: true,
  NEEDS_ELA_SUPPORT: true,
  ON_TRACK_IN_CREDITS: true,
};
