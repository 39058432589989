import { Injectable } from '@angular/core';
import { each, filter, find, findIndex, get, has, includes, reduce, startCase, toLower, uniqBy, without } from 'lodash';
import { Observable } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';
import * as moment from 'moment';
import { DateHelpers } from '../../../../../projects/shared/services/date-helpers/date-helpers.service';

import { SubjectAreas } from '../../../shared/constants/subject-areas.constant';
import { CLASS_OF_MAPPING } from '../../constants/class-of-mapping.constant';
import { CurrentSchoolYear } from '../../constants/current-school-year.constant';
import { GRADE_MAPPING } from '../../constants/grade-mapping.constant';
import { COLUMN_DATA_TYPE } from '../../constants/list-view/cell-type.constant';
import {
  STUDENT_PATH_APPLIED_STATUSES,
  STUDENT_PATH_FINAL_DECISION_STATUSES,
  STUDENT_PATH_GROUP,
} from '../../constants/student-paths.constant';
import { IListViewData } from '../../typings/interfaces/list-view.interface';
import { IStudentPath } from '../../typings/interfaces/studentPaths.interface';
import { PATH_TYPE } from '../api-service/api-service';
import { ImStudent } from '../im-models/im-student.service';
import { GRAD_PLAN_MAPPING } from './../../constants/grad-plan-mapping.constant';
import { FORMATTED_GROUPING_LISTS } from './mad-lib-groupings.constant';
import { MadLibHelpers } from './mad-lib-helpers';

import { SPEC_OPP_KEY_MAP } from '../../../school/lists/list-display/list-display-path/list-display-college-path/list-display-college-path.component';
import { IPointPerson } from '../../../shared/typings/interfaces/student.interface';

const ATTENDANCE_TREND_MAPPING = {
  TODAY: 'att.today',
  '5_DAYS': 'att.last5.trend',
  '20_DAYS': 'att.last20.trend',
  THIS_TERM: 'att.currTerm.trend',
  THIS_YEAR: 'att.currSy.trend',
};

/**
 * TODO!
 * Since this file will only continue to grow
 * we should pull each of these functions out into their own folder with the method and test file
 * then we can import only the grouping fn that we need and not an entire libarary of grouping methods
 * This will allow us to better test these functions
 * JJ
 */

/* istanbul ignore next */
@Injectable()
export class MadLibGroupings {
  constructor (private madLibHelpers: MadLibHelpers, private ImStudent: ImStudent, private dateHelpers: DateHelpers) {}

  getAttRiskGroupings (filteredStudents$, madLibsModel, columns, listType): Observable<any> {
    const path = 'att.riskGroup';
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.att_risk_group.getFormattedList({
          columns,
          listType,
        });

        return students.reduce((formattedList, student) => {
          const studentRiskGroup = get(student, path);
          const studentRow = this.convertColumnsToRow(student, columns, madLibsModel, listType);

          if (!studentRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: studentRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, studentRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getSchoolRiskGroupings (filteredStudents$, madLibModel, columns, listType, isIreadySchool?): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.academic_risk_groups.getFormattedList({
          columns,
          listType,
          madLibModel,
        });

        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(
            student,
            columns,
            madLibModel,
            listType,
            undefined,
            isIreadySchool,
          );
          let doeRiskGroup = null;
          let schoolRiskGroup = null;

          if (has(studentRow, 'iReady')) {
            doeRiskGroup = studentRow.iReady.dependencies.doeRiskGroup;
            // schoolRiskGroup is aligned to the dimension2 selection on the madLibModel
            schoolRiskGroup = studentRow.iReady.dependencies.schoolRiskGroup;
            // if there is no schoolRiskGroup for the selected IReady subject, we default to the doeRiskGroup
          }

          if (madLibModel.Groupings.validString.includes('ELA')) {
            if (has(studentRow, 'elaStateExam')) {
              doeRiskGroup = studentRow.elaStateExam.dependencies.doeRiskGroup;
              schoolRiskGroup = studentRow.elaStateExam.dependencies.schoolRiskGroup;
            }
          }

          if (madLibModel.Groupings.validString.includes('MATH')) {
            if (has(studentRow, 'mathStateExam')) {
              doeRiskGroup = studentRow.mathStateExam.dependencies.doeRiskGroup;
              schoolRiskGroup = studentRow.mathStateExam.dependencies.schoolRiskGroup;
            }
          }

          const studentRiskGroup = schoolRiskGroup || doeRiskGroup;

          if (!studentRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: studentRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, studentRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getDoeRiskGroupings (filteredStudents$, madLibModel, columns, listType, isIreadySchool?): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.academic_risk_groups.getFormattedList({
          columns,
          listType,
          madLibModel,
        });
        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(
            student,
            columns,
            madLibModel,
            listType,
            undefined,
            isIreadySchool,
          );
          let doeRiskGroup = null;

          if (has(studentRow, 'iReady')) {
            doeRiskGroup = studentRow.iReady.dependencies.doeRiskGroup;
          }

          if (madLibModel.Groupings.validString.includes('ELA')) {
            if (has(studentRow, 'elaStateExam')) {
              doeRiskGroup = studentRow.elaStateExam.dependencies.doeRiskGroup;
            }
          }

          if (madLibModel.Groupings.validString.includes('MATH')) {
            if (has(studentRow, 'mathStateExam')) {
              doeRiskGroup = studentRow.mathStateExam.dependencies.doeRiskGroup;
            }
          }

          if (!doeRiskGroup) {
            const noDataIndex = formattedList.sections.length - 1;
            formattedList.sections[noDataIndex].data.push(studentRow);
            formattedList.sections[noDataIndex].count++;
            return formattedList;
          }

          let sectionIndex = findIndex(formattedList.sections, { name: doeRiskGroup });
          if (sectionIndex === -1) {
            this.appendNewGrouping(formattedList, sectionIndex, doeRiskGroup);
            sectionIndex = 0;
          }

          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getHasPointPeopleGroupings (filteredStudents$, madLibModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList = FORMATTED_GROUPING_LISTS.has_point_people.getFormattedList({ columns, listType });
        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);
          const { pointPeople } = student;
          if (pointPeople.length) {
            formattedList.sections[1].data.push(studentRow);
            formattedList.sections[1].count++;
            return formattedList;
          }

          formattedList.sections[0].data.push(studentRow);
          formattedList.sections[0].count++;

          return formattedList;
        }, formattedList);
      }),
    );
  }

  getProgressGroupings (filteredStudents$, madLibModel, columns, listType): Observable<any> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.progress.getFormattedList({ columns, listType });
        const {
          Dimension2: { key },
        } = madLibModel;

        return students.reduce((formattedList, student) => {
          const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType);
          const studentProgress =
            key === 'COURSE_GRADES' ? student.currProgram.progress : student.currProgram.progressHP;
          switch (studentProgress) {
            case 'Needs attention':
              formattedList.sections[0].data.push(studentRow);
              formattedList.sections[0].count++;
              break;
            case 'Passing':
              formattedList.sections[1].data.push(studentRow);
              formattedList.sections[1].count++;
              break;
            case 'No grades':
              formattedList.sections[2].data.push(studentRow);
              formattedList.sections[2].count++;
              break;
            case 'No courses':
              formattedList.sections[3].data.push(studentRow);
              formattedList.sections[3].count++;
              break;
            default:
              break;
          }
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getPostsecondaryApplicationStatusGrouping (filteredStudents$, columns, listType): Observable<Partial<IListViewData>> {
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.postsecondary_group_app_status.getFormattedList({
          columns,
          listType,
        });
        return students.reduce((formattedList, student) => {
          const calculatedFields = student.calculatedFields[0];
          const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
            studentRow[key] = getValueAtPath(calculatedFields);
            return studentRow;
          }, {});

          let name;
          let sectionIndex;

          const appliedTotalPaths = calculatedFields.applied.totalPaths;
          const appliedTotalPathConditions = {
            hasFinalDecision: false,
            hasAppliedToAllPaths: true,
            hasAppliedToAllPathSoFar: true,
          };

          const { hasFinalDecision, hasAppliedToAllPaths } = this.groupByAppliedTotalPaths(
            appliedTotalPaths,
            appliedTotalPathConditions,
          );
          const needtoApply =
            !calculatedFields.applied.totalPaths.length || (!hasAppliedToAllPaths && !hasFinalDecision);
          const needFinalDecision = hasAppliedToAllPaths && !hasFinalDecision;
          const committed = hasFinalDecision;

          if (needtoApply) name = STUDENT_PATH_GROUP.NEED_TO_APPLY;
          else if (needFinalDecision) name = STUDENT_PATH_GROUP.NEED_FINAL_DECISION;
          else if (committed) name = STUDENT_PATH_GROUP.COMMITTED;

          sectionIndex = findIndex(formattedList.sections, { name });

          if (sectionIndex === -1) {
            const section = {
              name,
              count: 0,
              data: [],
              defaultDisplayCount: 10,
            };
            formattedList.sections.push(section);
            sectionIndex = formattedList.sections.length - 1;
          }
          formattedList.sections[sectionIndex].data.push(studentRow);
          formattedList.sections[sectionIndex].count++;
          return formattedList;
        }, formattedList);
      }),
    );
  }

  groupByAppliedTotalPaths = (appliedTotalPaths, appliedTotalPathConditions) => {
    return appliedTotalPaths.reduce((accumulator, path, currentIndex): {
      hasFinalDecision: boolean;
      hasAppliedToAllPaths: boolean;
      hasAppliedToAllPathSoFar: boolean;
    } => {
      if (!accumulator.hasFinalDecision) {
        accumulator.hasFinalDecision = includes(STUDENT_PATH_FINAL_DECISION_STATUSES, path.status);
      }

      if (accumulator.hasAppliedToAllPathSoFar) {
        const includesAppliedStatuses = includes(STUDENT_PATH_APPLIED_STATUSES, path.status);
        accumulator.hasAppliedToAllPathSoFar = accumulator.hasAppliedToAllPathSoFar && includesAppliedStatuses;
        accumulator.hasAppliedToAllPaths = accumulator.hasAppliedToAllPathSoFar;
      }
      if (currentIndex === appliedTotalPaths.length) {
        accumulator.hasAppliedToAllPaths = accumulator.hasAppliedToAllPaths && accumulator.hasAppliedToAllPathSoFar;
      }
      return accumulator;
    }, appliedTotalPathConditions);
  };

  convertColumnsToRow (student, columns, madLibsModel, listType, code?, isIreadySchool?) {
    const studentRow = columns.reduce((studentRow, { key, getValueAtPath }) => {
      studentRow[key] = getValueAtPath(student, madLibsModel, listType, code, isIreadySchool);
      return studentRow;
    }, {});
    return studentRow;
  }

  appendNewGrouping (formattedList, sectionIndex, groupName) {
    const section = {
      name: groupName,
      count: 0,
      data: [],
      defaultDisplayCount: 10,
    };
    formattedList.sections.unshift(section);
    return formattedList;
  }

  formatColumnsForList (columns) {
    const formattedColumns = columns.reduce((formattedList, col) => {
      if (col.cellType !== COLUMN_DATA_TYPE.SECTION_HEADER) {
        formattedList[col.key] = {
          name: col.human,
          cellType: col.cellType,
          cellConfig: col.cellConfig,
          dataType: col.dataType,
          orderBy: col.orderBy,
          tooltip: col.tooltip,
        };
      }
      return formattedList;
    }, {});
    return formattedColumns;
  }

  getCollegePathGrouping (studentPaths$, columns, listType, currentStudentId): Observable<any> {
    return studentPaths$.pipe(
      rxMap(path => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.college_path.getFormattedList({
          columns,
          listType,
        });

        const allPaths = [];
        Object.keys(path).map(key => {
          const stuPath = path[key];
          if (
            stuPath.studentId === currentStudentId &&
            stuPath.path.type === PATH_TYPE.COLLEGE &&
            stuPath.status !== 'DELETED' &&
            stuPath.status !== 'CLOSED'
          ) {
            allPaths.push(stuPath);
          }
        });

        return allPaths.reduce((formattedList, stuPath) => {
          const dueDate = stuPath.dueAt ? moment(stuPath.dueAt).format('MMM DD, YYYY') : '-';
          const parsedStatus = SPEC_OPP_KEY_MAP[stuPath.status];
          const stuPathStatus = parsedStatus || stuPath.status;
          const dataObj = {
            collegeName: {
              data: stuPath.path.name,
              dependencies: { city: stuPath.path.city, state: stuPath.path.state },
            },
            status: { data: stuPathStatus, dependencies: { studentPathId: stuPath._id } },
            dueBy: { data: dueDate },
          };
          formattedList.sections[0].count++;
          formattedList.sections[0].data.push(dataObj);
          return formattedList;
        }, formattedList);
      }),
    );
  }

  getSubjectSupportGroupings (filteredStudents$, columns, listType, madLibModel): Observable<any> {
    const {
      Dimension2: { subject },
    } = madLibModel;
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.subjectSupports.getFormattedList({
          columns,
          listType,
          subject,
        });

        return students.reduce((formattedList, student) => {
          const {
            activeStudentSupports,
            currProgram: { grades },
          } = student;
          const selectedSubject = madLibModel.Dimension2.key;
          const selectedSubjectHumanShort = SubjectAreas[selectedSubject].humanShort;
          const studentSupportsForCat = activeStudentSupports.filter(
            studentSupport => studentSupport.support.metaData && studentSupport.support.category === listType,
          );

          const supportsForSubject = studentSupportsForCat.reduce(
            (
              acc,
              {
                support: {
                  name,
                  metaData: { subject },
                },
              },
            ) => {
              // not all academic supports will have a subject
              if (!subject) return acc;
              const subjectKey = find(SubjectAreas, { humanShort: subject }).key;
              if (subject === selectedSubjectHumanShort) {
                if (!acc[subjectKey]) acc[subjectKey] = [];
                acc[subjectKey].push(name);
              }
              return acc;
            },
            {},
          );

          const gradesForSelectedSubject = grades.filter(
            ({ subj: courseSubj, pf, mostRecent }) => courseSubj === selectedSubject && pf === 'F' && mostRecent,
          );

          gradesForSelectedSubject.forEach(grade => {
            const studentRow = this.convertColumnsToRow(student, columns, madLibModel, listType, grade.code);
            const supportNames = supportsForSubject[grade.subj];

            if (supportNames) {
              supportNames.forEach(name => {
                let sectionIndex = findIndex(formattedList.sections, { name });
                if (sectionIndex === -1) {
                  this.appendNewGrouping(formattedList, sectionIndex, name);
                  sectionIndex = 0;
                }
                formattedList.sections[sectionIndex].data.push(studentRow);
                formattedList.sections[sectionIndex].count++;
              });
            } else {
              const noDataIndex = formattedList.sections.length - 1;
              formattedList.sections[noDataIndex].data.push(studentRow);
              formattedList.sections[noDataIndex].count++;
            }
          });
          return formattedList;
        }, formattedList);
      }),
    );
  }

  // todo: add spec
  getCareerPathGrouping (studentPaths$, columns, listType, currentStudentId): Observable<any> {
    return studentPaths$.pipe(
      rxMap(path => {
        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.career_path.getFormattedList({
          columns,
          listType,
        });

        const allPaths = [];
        Object.keys(path).map(key => {
          const stuPath = path[key];
          if (
            stuPath.studentId === currentStudentId &&
            stuPath.path.type === PATH_TYPE.CAREER &&
            stuPath.status !== 'DELETED' &&
            stuPath.status !== 'CLOSED'
          ) { allPaths.push(stuPath); }
        });

        return allPaths.reduce((formattedList, stuPath) => {
          const parsedStatus = SPEC_OPP_KEY_MAP[stuPath.status];
          const stuPathStatus = parsedStatus || stuPath.status;
          const dataObj = {
            careerName: { data: stuPath.path.name },
            status: { data: stuPathStatus, dependencies: { studentPathId: stuPath._id } },
          };
          formattedList.sections[0].count++;
          formattedList.sections[0].data.push(dataObj);
          return formattedList;
        }, formattedList);
      }),
    );
  }
}
