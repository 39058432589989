<header class="sidebar-header">
  <nv-logo (click)="navigateToHomepage({ contextPartnerType: this.contextPartnerType, contextPartnerId: this.contextPartnerId })" [pointerOnHover]="true" class="logo" type="portal"></nv-logo>
  <portal-switcher [currentUser]="currentUser" [selectedView]="selectedView" [label]="portalSwitcherLabel"></portal-switcher>
</header>
<div class="side-content-wrap" *ngIf="sidenav?.tools && sidenav?.contentArea && sidenav?.smallNav">
  <nv-sidebar-list
    [listData]="sidenav.tools"
    [selectedKey]="selectedSidebarItem"
    [expandedNodes]="openSidebarItems"
    (sidebarListItemSelect)="onNavItemSelect(sidenav.tools, { selectedItemKey: $event })"
  ></nv-sidebar-list>

  <nv-sidebar-list
    [listData]="sidenav.contentArea"
    [selectedKey]="selectedSidebarItem"
    [expandedNodes]="openSidebarItems"
    (sidebarListItemSelect)="onNavItemSelect(sidenav.contentArea, { selectedItemKey: $event })"
  ></nv-sidebar-list>

  <nav-content class="nv-sidebar-nav-menu-small">
    <item-list
      *ngFor="let option of sidenav.smallNav"
      class="nv-sidebar-list-item nv-sidebar-list-item-small"
      [ngClass]="{ 'selected': option.relativeUrl === activeUrl }"
      (click)="trackLefthandNavEvent(option.key, selectedView)"
    >
      <a *ngIf="option.key === 'PRIVACY_AND_TERMS'"
        class="unformatted-link"
        [href]="publicConfig.PRIVACY_POLICY" target="_blank"
      >
        <label for="{{ option.human }}">
          {{ option.human }}
        </label>
      </a>
      <a *ngIf="option.url" class="unformatted-link" [routerLink]="option.url">
        <label for="{{ option.human }}">
          {{ option.human }}
        </label>
        <span id="{{ option.human }}"></span>
      </a>
      <a *ngIf="option.key !== 'PRIVACY_AND_TERMS' && !option.url">
        <label for="{{ option.human }}" (click)="goExternal(option.human)">
          {{ option.human }}
        </label>
        <span id="{{ option.human }}"></span>
      </a>
    </item-list>
    <item-list class="nv-sidebar-list-item nv-sidebar-list-item-small"> <nvps-log-out portalType="{{ selectedView }}"></nvps-log-out> </item-list>
  </nav-content>
</div>
