import { ICollegeNowMetadata } from './event-interfaces/college-now-action';
import { ITaskMetadata, TActionInfo, TFormatTaskEventArgs } from './event-interfaces/task-action';
import { IFamilyListMetadata, TFamilyListAction } from './event-interfaces/family-list-action';
import { IFamilyProfileMetadata, TFormatFamilyProfileEventArgs } from './event-interfaces/family-profile-action';
import { IBatchActionMetadata, IFormatBatchActionEventArgs } from './event-interfaces/batch-action';
import { EVENT_NAMES, EVENT_TYPES, TEventType } from './../../typings/interfaces/mixpanel.interface';
import { Injectable } from '@angular/core';
import { TMixpanelEvent } from './mixpanel.service';
import { IFormatTileClickEventArgs, IHomepageActionMetadata, ITileClickMetadata } from './event-interfaces/dashboard-tile';
import { IExperienceMetadata, TFormatExperienceEventArgs } from './event-interfaces/experience-action';
import { INoteMetadata, TFormatNoteEventArgs } from './event-interfaces/note-action';
import { IStudentPathMetadata, TFormatStudentPathEventArgs } from './event-interfaces/student-path-action';
import { ISupportsMetadata, TFormatSupportEventArgs } from './event-interfaces/supports-action';
import { IViewContentAreaDashboardMetadata, TFormatDashboardEventArgs, THorizontalNavNames, TViewInfo } from './event-interfaces/view-content-area-dashboard';
import { IViewContentAreaListMetadata } from './event-interfaces/view-content-area-list';
import { IGenerateReportMetadata } from './event-interfaces/generate-report';
import { IStudentProfileActionMetadata, TStudentProfileAction } from './event-interfaces/student-profile-action';
import { IGridColumnMetadata, IToDataGridNavMetadata } from './event-interfaces/data-grid';
import { PORTAL_TYPES } from '../../typings/interfaces/portal.interface';
import { IExportedCsvMetadata } from './event-interfaces/export-csv';
import { ILeftHandNavMetadata } from './event-interfaces/lefthand-nav';
import { IEditTaskPayload } from '../../modals/task/task-modal.component';
import { ITask } from '../../typings/interfaces/task.interface';
import { IOtherRegentsMetadata, IRegentsResultsActionsMetadata, TOtherRegentsPages } from './event-interfaces/other-regents';
import { IPassRatesMetadata, IPassRatesActionsMetadata, TPassRatesPages } from './event-interfaces/pass-rates-action';
import { IMockRegentsActionsMetadata } from './event-interfaces/mock-regents-actions';
import { IMiscPageActionMetadata, IMiscPageViewMetadata } from './event-interfaces/misc-page';


function getBatchActionEvent ({ item, view, portal, currentExam }: IFormatBatchActionEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<IBatchActionMetadata> {
  const mixpanelEvent: TMixpanelEvent<IBatchActionMetadata> = {
    event: EVENT_NAMES.COMPLETED_BATCH_ACTION,
    metaData: {
      item,
      type: actionType,
      view,
      portal,
    },
  };
  if (currentExam) mixpanelEvent.metaData.currentExam = currentExam;
  return mixpanelEvent;
}
@Injectable()
export class EventFormatterService {
  constructor () { }

  getClickTileEvent ({ tileName, portal }: IFormatTileClickEventArgs): TMixpanelEvent<ITileClickMetadata> {
    return {
      event: EVENT_NAMES.CLICKED_TILE,
      metaData: {
        tileName,
        portal,
      },
    };
  }

  public getHomePageEvent ({ filterName, action, portal }: {filterName: string, action: TEventType, portal: keyof typeof PORTAL_TYPES}): TMixpanelEvent<IHomepageActionMetadata> {
    return {
      event: EVENT_NAMES.HOMEPAGE_EVENT,
      metaData: {
        filterName,
        action,
        portal,
      },
    };
  }

  getNavToGridEvent ({ originComponent, originId, portal }: IToDataGridNavMetadata): TMixpanelEvent<IToDataGridNavMetadata> {
    let eventName;
    const eventMeta: {portal: keyof typeof PORTAL_TYPES, originId?: string, tileName?: string } = { portal };
    switch (originComponent) {
      case 'contentAreaDashboard':
        eventName = EVENT_NAMES.CAD_TO_GRID;
        eventMeta.originId = originId;
        break;
      case 'homepageTile':
        eventName = EVENT_NAMES.CLICKED_TILE;
        eventMeta.tileName = originId;
        break;
      case 'myGridViewsDropdown':
        eventName = EVENT_NAMES.MY_GRID_VIEWS;
        eventMeta.originId = originId;
        break;
      default:
        eventName = EVENT_NAMES.SERVERSIDE_GRIDVIEW;
        eventMeta.originId = originId;
    }

    return {
      event: eventName,
      metaData: eventMeta,
    };
  }

  getViewDashboardEvent <T extends THorizontalNavNames> ({ horizontalNav, contentArea, portal }: TFormatDashboardEventArgs<T>, viewInfo: TViewInfo<T>): TMixpanelEvent<IViewContentAreaDashboardMetadata<T>> {
    return {
      event: EVENT_NAMES.VIEWED_CONTENT_AREA_DASHBOARD,
      metaData: {
        horizontalNav,
        viewInfo,
        contentArea,
        portal,
      },
    };
  }

  getViewListEvent (metaData: IViewContentAreaListMetadata): TMixpanelEvent<IViewContentAreaListMetadata> {
    return {
      event: EVENT_NAMES.VIEWED_CONTENT_AREA_LIST,
      metaData,
    };
  }

  getGridColumnActionEvent <T extends keyof typeof PORTAL_TYPES> (metaData: IGridColumnMetadata<T>): TMixpanelEvent<IGridColumnMetadata<T>> {
    return {
      event: EVENT_NAMES.GRID_COLUMNS_ACTION,
      metaData,
    };
  }

  getCreateSupportEvent (args: TFormatSupportEventArgs) {
    return this.getSupportActionEvent(args, EVENT_TYPES.CREATED);
  }

  getAssignSupportEvent (args: TFormatSupportEventArgs) {
    return this.getSupportActionEvent(args, EVENT_TYPES.ASSIGNED);
  }

  getClickedSupportButtonEvent (metaData) {
    return {
      event: EVENT_NAMES.SUPPORTS_ACTION,
      metaData,
    };
  }

  private getSupportActionEvent ({ view, portal, categories }: TFormatSupportEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<ISupportsMetadata> {
    return {
      event: EVENT_NAMES.SUPPORTS_ACTION,
      metaData: {
        action: actionType,
        view,
        portal,
        categories,
      },
    };
  }

  getCreateStudentPathEvent (args: TFormatStudentPathEventArgs) {
    return this.getStudentPathActionEvent(args, EVENT_TYPES.CREATED);
  }

  private getStudentPathActionEvent ({ view, portal }: TFormatStudentPathEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<IStudentPathMetadata> {
    return {
      event: EVENT_NAMES.STUDENT_PATH_ACTION,
      metaData: {
        action: actionType,
        view,
        portal,
      },
    };
  }

  getViewNoteEvent (args: TFormatNoteEventArgs) {
    return this.getNoteActionEvent(args, EVENT_TYPES.VIEWED);
  }

  getCreateNoteEvent (args: TFormatNoteEventArgs) {
    return this.getNoteActionEvent(args, EVENT_TYPES.CREATED);
  }

  getUpdateNoteEvent (args: TFormatNoteEventArgs) {
    return this.getNoteActionEvent(args, EVENT_TYPES.UPDATED);
  }

  getDeleteNoteEvent (args: TFormatNoteEventArgs) {
    return this.getNoteActionEvent(args, EVENT_TYPES.DELETED);
  }

  private getNoteActionEvent ({ view, portal, categories }: TFormatNoteEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<INoteMetadata> {
    return {
      event: EVENT_NAMES.NOTE_ACTION,
      metaData: {
        action: actionType,
        view,
        portal,
        categories,
      },
    };
  }

  getCreateExperienceEvent (args: TFormatExperienceEventArgs) {
    return this.getExperienceActionEvent(args, EVENT_TYPES.CREATED);
  }

  private getExperienceActionEvent ({ view, portal }: TFormatExperienceEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<IExperienceMetadata> {
    return {
      event: EVENT_NAMES.EXPERIENCE_ACTION,
      metaData: {
        action: actionType,
        view,
        portal,
      },
    };
  }

  getCreateTaskEvent (args: TFormatTaskEventArgs) {
    return this.getTaskActionEvent(args, EVENT_TYPES.CREATED);
  }

  getUpdateTaskEvent (payload: IEditTaskPayload, task: ITask, { view, portal }: Partial<ITaskMetadata>) {
    const mapPayloadKeyActionInfo: {[key: string]: TActionInfo} = {
      comment: 'EDITED-COMMENT',
      categories: 'EDITED-CATEGORIES',
      description: 'EDITED-DESCRIPTION',
      status: 'MARKED-STATUS-COMPLETE',
      assigneeId: 'REASSIGNED-TASK',
    };

    const actionInfo = Object.entries(payload).reduce(
      (acc, [key, value]) => {
        const updateAction = mapPayloadKeyActionInfo[key]; // exclude _id
        const isTaskPropEdited = task[key] !== value;
        if (isTaskPropEdited && updateAction) {
          acc.push(updateAction);
        }
        return acc;
      }, []);

    const args: TFormatTaskEventArgs = {
      actionInfo,
      view,
      portal,
    };

    return this.getTaskActionEvent(args, EVENT_TYPES.UPDATED);
  }

  getDeleteTaskEvent (args: TFormatTaskEventArgs) {
    return this.getTaskActionEvent(args, EVENT_TYPES.DELETED);
  }

  private getTaskActionEvent <T extends EVENT_TYPES> (metaData: TFormatTaskEventArgs, actionType: T): TMixpanelEvent<ITaskMetadata> {
    return {
      event: EVENT_NAMES.TASK_ACTION,
      metaData: {
        action: actionType,
        ...metaData,
      },
    };
  }

  getCreateGuardianEvent () {
    return this.getFamilyProfileEvent({ attribute: 'GUARDIANS' }, EVENT_TYPES.CREATED);
  }

  getUpdateGuardiansEvent () {
    return this.getFamilyProfileEvent({ attribute: 'GUARDIANS' }, EVENT_TYPES.UPDATED);
  }

  getUpdateFamilyLanguagesEvent () {
    return this.getFamilyProfileEvent({ attribute: 'LANGUAGES' }, EVENT_TYPES.UPDATED);
  }

  private getFamilyProfileEvent ({ attribute }: TFormatFamilyProfileEventArgs, actionType: EVENT_TYPES): TMixpanelEvent<IFamilyProfileMetadata> {
    return {
      event: EVENT_NAMES.FAMILY_PROFILE_ACTION,
      metaData: {
        action: actionType,
        attribute,
        portal: 'SCHOOL',
      },
    };
  }

  getFamilyListEvent <T extends TFamilyListAction> (metaData: IFamilyListMetadata<T>): TMixpanelEvent<IFamilyListMetadata<T>> {
    return {
      event: EVENT_NAMES.FAMILY_LIST_ACTION,
      metaData,
    };
  }

  public getViewBatchActionEvent (_, args: IFormatBatchActionEventArgs) {
    return getBatchActionEvent(args, EVENT_TYPES.VIEWED);
  }

  public getCreateBatchActionEvent (_, args: IFormatBatchActionEventArgs) {
    return getBatchActionEvent(args, EVENT_TYPES.CREATED);
  }

  public getUpdateBatchActionEvent (_, args: IFormatBatchActionEventArgs) {
    return getBatchActionEvent(args, EVENT_TYPES.UPDATED);
  }

  public getAssignedBatchActionEvent (_, args: IFormatBatchActionEventArgs) {
    return getBatchActionEvent(args, EVENT_TYPES.ASSIGNED);
  }

  public getDeletedBatchActionEvent (_, args: IFormatBatchActionEventArgs) {
    return getBatchActionEvent(args, EVENT_TYPES.DELETED);
  }

  getReportEvent ({ report, view, portal }: IGenerateReportMetadata): TMixpanelEvent<IGenerateReportMetadata> {
    return {
      event: EVENT_NAMES.GENERATED_REPORT,
      metaData: {
        report,
        view,
        portal,
      },
    };
  }

  getStudentProfileActionEvent <T extends TStudentProfileAction> (metaData: IStudentProfileActionMetadata<T>): TMixpanelEvent<IStudentProfileActionMetadata<T>> {
    return {
      event: EVENT_NAMES.STUDENT_PROFILE_ACTION,
      metaData,
    };
  }

  getViewSupplementalAdvisingEvent (): TMixpanelEvent<any> {
    return {
      event: EVENT_NAMES.VIEWED_SUPPLEMENTAL_ADVISING,
      metaData: {
        portal: 'SCHOOL',
      },
    };
  }

  getExportedCsvEvent (metaData: IExportedCsvMetadata): TMixpanelEvent<IExportedCsvMetadata> {
    return {
      event: EVENT_NAMES.EXPORTED_CSV,
      metaData,
    };
  };

  getLefthandNavEvent (metaData: ILeftHandNavMetadata): TMixpanelEvent<ILeftHandNavMetadata> {
    return {
      event: EVENT_NAMES.LEFTHAND_NAV,
      metaData,
    };
  };

  getProfileSideNavEvent (metaData: ILeftHandNavMetadata): TMixpanelEvent<ILeftHandNavMetadata> {
    return {
      event: EVENT_NAMES.PROFILE_NAV,
      metaData,
    };
  };

  getOtherRegentsViewEvent ({ portal, page } : { portal: keyof typeof PORTAL_TYPES, page: TOtherRegentsPages}): TMixpanelEvent<IOtherRegentsMetadata> {
    const metaData: IOtherRegentsMetadata = {
      portal,
      page,
    };
    return {
      event: EVENT_NAMES.VIEWED_OTHER_REGENTS,
      metaData,
    };
  };

  getRegentsResultsEvent (metaData: IRegentsResultsActionsMetadata): TMixpanelEvent<IRegentsResultsActionsMetadata> {
    return {
      event: EVENT_NAMES.REGENTS_RESULTS_ACTION,
      metaData,
    };
  }

  getPassRatesViewEvent ({ portal, page } : { portal: keyof typeof PORTAL_TYPES, page: TPassRatesPages}): TMixpanelEvent<IPassRatesMetadata> {
    const metaData: IPassRatesMetadata = {
      portal,
      page,
    };
    return {
      event: EVENT_NAMES.VIEWED_PASS_RATES,
      metaData,
    };
  };

  getPassRatesEvent (metaData: IPassRatesActionsMetadata): TMixpanelEvent<IPassRatesActionsMetadata> {
    return {
      event: EVENT_NAMES.PASS_RATES_ACTION,
      metaData,
    };
  }

  public getViewChangeEvent (metaData: IMockRegentsActionsMetadata): TMixpanelEvent<IMockRegentsActionsMetadata> {
    return {
      event: EVENT_NAMES.MOCK_REGENTS_ACTION,
      metaData,
    };
  }

  getCollegeNowEvent (metaData: ICollegeNowMetadata): TMixpanelEvent<ICollegeNowMetadata> {
    return {
      event: EVENT_NAMES.COLLEGE_NOW_ACTION,
      metaData,
    };
  }

  getMiscPageViewEvent (metaData: IMiscPageViewMetadata): TMixpanelEvent<IMiscPageViewMetadata> {
    return {
      event: EVENT_NAMES.MISC_PAGE_ACTION,
      metaData,
    };
  }

  getMiscPageActionEvent (metaData: IMiscPageActionMetadata): TMixpanelEvent<IMiscPageActionMetadata> {
    return {
      event: EVENT_NAMES.MISC_PAGE_ACTION,
      metaData,
    };
  }
}
