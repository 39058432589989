import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AfterViewInit, Component } from '@angular/core';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';

@Component({
  selector: 'pill-cell-renderer',
  templateUrl: './pill-cell-renderer.component.html',
  styleUrls: ['./pill-cell-renderer.component.scss'],
})
export class PillCellRenderer implements ICellRendererAngularComp, AfterViewInit {
  public pillLabel: string;
  public emptyCell: string = EM_DASH;
  public color: string = 'blue';
  public gridConfigParams;

  /**
   * This component supports a BE and FE manner of "getting" a pill color:
   *   1. getPillColor is a function that FE callers must define themeselves.
   *   2. gridConfigParams is a map of rules defined in postgres.
   */
  agInit (params: ICellRendererParams): void {
    const {
      value,
      data,
      colDef: {
        cellRendererParams: {
          getPillColor,
          getFormattedStatus,
          params: gridConfigParams,
        },
      },
    } = params;

    this.pillLabel = value;
    this.gridConfigParams = gridConfigParams;

    if (getPillColor) this.color = getPillColor(data);
    if (getFormattedStatus) this.pillLabel = getFormattedStatus(data);
  }

  ngAfterViewInit (): void {
    if (this.gridConfigParams) {
      this.color = this.getPillColorRule(this.pillLabel, this.gridConfigParams);
    }
  }

  public refresh (_: ICellRendererParams): boolean {
    return false;
  }

  public getPillColorRule (cellValue: string, gridConfigParams: string) {
    const colorMap = JSON.parse(gridConfigParams);
    const color = colorMap[cellValue];

    switch (color) {
      case 'red':
        return 'red-light-1 dark-red-text font-weight-normal';
      case 'light-green':
        return 'green-light-1 dark-green-text font-weight-normal';
      case 'dark-green':
        return 'green-dark-1 white-text font-weight-normal';
      case 'grey':
        return 'grey-light-8 dark-grey-text font-weight-normal';
      default:
        return 'blue';
    }
  }
}
