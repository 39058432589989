import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap, filter } from 'rxjs/operators';
import NvMaskFormats from '../../../constants/NvMaskFormats';

import { IACOption } from './nv-textbox.interface';
import { TMatDisplayOption } from './pipes/transform-to-mat-display-options/transform-to-mat-display-options.pipe';

//
// https://stackoverflow.com/questions/34948961/angular-2-custom-form-input/34998780#34998780
//

/**
 *
 * The Textbox allows users to enter a single line of text.
 *
 * It's typically used with Autocomplete options to allow users to select from a predefined set of options.
 *
 * If the set of options is small, and the user _must_ select a option (not a custom value), consider using a Dropdown.
 */
@Component({
  selector: 'nv-textbox',
  templateUrl: './nv-textbox.component.html',
  styleUrls: ['./nv-textbox.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvTextboxComponent implements OnInit, AfterViewInit, AfterContentInit {
  /**
   *
   * The FormControl defining the text content.
   *
   * Add [Validators](https://angular.io/guide/form-validation) to the FormControl to limit/restrict input
   * @required
   */
  @Input() textControl: FormControl;

  /**
   *
   * The name of the left icon (as `nv-icon`)
   */
  @Input() icon: string = null;

  /**
   *
   * Determines whether to show the clear button
   */
  @Input() hasClearBtn: boolean = false;

  /**
   *
   * The placeholder text
   */
  @Input() placeholder: string | null = null;

  /**
   *
   * Determines whether to highlight invalid inputs as red.
   *
   * Use [Validators](https://angular.io/guide/form-validation) to define what inputs are valid.
   */
  @Input() showInvalid: boolean = false;

  /**
   *
   * Determines whether to highlight invalid inputs as red on a pristine(untouched) required input.
   *
   * Use with Angular built-in validators [Validators.required](https://angular.io/api/forms/Validators#required).
   */
  @Input() showInvalidOnPristine: boolean = true;

  /**
   *
   * Restricts the input to certain values
   */
  @Input() restrictToOptions: boolean = false;

  /**
   *
   * The array of autocomplete options
   * ~~~~js
   * interface IACOption {
   *  key: string;
   *  human: string;
   *  tags: {
   *    key: string;
   *    human: string;
   *  }
   * }
   * ~~~~
   */
  @Input() autocompleteOptions: IACOption[];

  /**
   *
   * The string to display when no autocomplete options are available
   */
  @Input() emptyStateText: string = 'No options found';

  /**
   *
   * The string to display to indicate that autocomplete options
   * have been trimmed due to the length of the list. This displays
   * if a value is provided, and is handled by the parent component
   */
  @Input() moreOptionsText: string;

  /**
   *
   * Temporary prop to return either a IACOption or string
   *
   * When refactoring, this should be always true
   */
  @Input() returnObject: boolean = false; // TODO -  REMOVE THIS ON REFACTOR - AT

  /**
   *
   * Determines the mask format for the input
   *
   */
  @Input() inputType: 'phoneNumber' | null = null;

  /**
   *
   * Emits when the `clear` button is pressed.
   *
   * The parent component must handle clearing the FormControl
   *
   */
  @Output() clearValue: EventEmitter<void> = new EventEmitter<void>();

  //
  /**
   *
   * Emits a string or IACOption only when an element is selected
   *
   * Note: currently fires when selecting text. TODO - rename this event to remove collision with native event
   */
  @Output() selectOption: EventEmitter<IACOption | string> = new EventEmitter<IACOption | string>();

  /**
   * Description: a pure function that matches a token against a display option. It returns a boolean
   * that denotes a match.
   */
  @Input() optionsPredicateCb: (option: TMatDisplayOption, searchValue: string) => boolean;

  /**
   * Description: a number denoting the maximum number of display options that may appear in the dropdown UI at a time.
   * Note that if you pass in an 'optionsLimit', it is unnecessary to also pass in 'moreOptionsText'.
   */
  @Input() optionsLimit: number;

  /**
   * Description: a boolean to determine whether the options will be sorted based on how closely they match an input.
   * NvTextboxComponent does not sort options this way by default.
   */
  @Input() weightedOptionsSortingEnabled: boolean = false;

  @ViewChild('input', { static: true }) inputElement: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;

  @Input() maxLength : number;

  // TODO make functions more pure?
  hasAutocomplete: boolean = false;
  hasTags: boolean = false;
  isTextValid: boolean = false;
  isClearBtnVisible: boolean = false;
  isAutocompleteActionVisible: boolean = false;
  isAutocompleteEmptyStateEnabled: boolean = false;
  maskFormat: string;
  public newSkinModeIsOn: boolean = false;

  // valid: boolean = true;
  private debounceTime: number = 50; // millis
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private shouldOpenPanel: boolean = true;
  private justSelectedOption: boolean = false;

  // TODO: Figure out how to efficiently do the highlighting
  constructor () {}

  ngOnInit (): void {
    this.hasAutocomplete = this.getIsAutocompleteEnabled();
    this.hasTags = this.getDoesAutocompleteHaveTags();
    // Set up the subscription to Value Changes
    this.textControl.valueChanges
      .pipe(
        filter((value) => (typeof value === 'string')), // if we get passed in an autocomplete object, ignore it
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(value => this.handleValueChange(value)),
        tap(value => {
          if (this.hasAutocomplete && this.shouldOpenPanel && !this.autocompleteTrigger.panelOpen) {
            this.autocompleteTrigger.openPanel();
          }
        }),
      )
      .subscribe(null);

    this.textControl.statusChanges
      .pipe(
        filter(value => typeof value === 'string'), // if we get passed in an autocomplete object, ignore it
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap(() => {
          this.isTextValid = this.getIsTextValid();
        }),
      )
      .subscribe(null);

    // For a passed in textControl with a non-empty value
    // we also want to show the clear btn (JCHU)
    this.isClearBtnVisible = !this.textControl.disabled && !!this.textControl.value;
    this.isTextValid = this.getIsTextValid();

    this.initMaskAndPlaceholderFormat();
  }

  initMaskAndPlaceholderFormat () {
    switch (this.inputType) {
      case 'phoneNumber':
        if (this.placeholder === null) {
          this.placeholder = NvMaskFormats.phoneNumber.placeholder;
        }
        this.maskFormat = NvMaskFormats.phoneNumber.maskFormat;
        break;
      default: {
        if (this.placeholder === null) {
          this.placeholder = 'Search...';
        }
        this.maskFormat = '';
      }
    }
  }

  ngAfterContentInit () {
    this.initAutocomplete();
  }

  ngAfterViewInit () {
    if (this.hasAutocomplete) {
      this.setupAutocompleteSubscriptions();
    }
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.autocompleteOptions && !changes.autocompleteOptions.isFirstChange()) {
      this.initAutocomplete();
    }
    this.initMaskAndPlaceholderFormat();
  }

  initAutocomplete (): void {
    this.hasAutocomplete = this.getIsAutocompleteEnabled();
    this.hasTags = this.getDoesAutocompleteHaveTags();

    // Disable autocomplete if necessary
    if (this.hasAutocomplete) {
      this.autocompleteTrigger.autocompleteDisabled = false;
      this.isAutocompleteEmptyStateEnabled = this.getIsAutocompleteEmptyStateEnabled();
      this.isAutocompleteActionVisible = this.getIsAutocompleteActionVisible('');
    } else {
      this.autocompleteTrigger.autocompleteDisabled = true;
    }
  }

  setupAutocompleteSubscriptions (): void {
    if (this.autocompleteTrigger && this.autocompleteTrigger.autocomplete) {
      // set up subscription to autocomplete
      this.autocompleteTrigger.autocomplete.optionSelected
        .pipe(
          takeUntil(this.destroy$),
          tap((event: MatAutocompleteSelectedEvent) => {
            this.onSelectOption(event);
          }),
        )
        .subscribe();

      // Restrict textbox to options
      if (this.restrictToOptions) {
        // New event when user clicks away from textbox (closes the AC menu)
        this.autocompleteTrigger.panelClosingActions.subscribe(event => {
          if (!event || !event.source) {
            this.onClosePanel(this.textControl.value, this.autocompleteOptions);
          }
        });
      }
    }
  }

  // Subscription handlers
  handleValueChange (textValue: string) {
    this.isTextValid = this.getIsTextValid();
    this.isClearBtnVisible = this.getIsClearButtonVisible(textValue);
  }

  getIsTextValid (): boolean {
    let isValid = true;
    if (this.showInvalid) {
      isValid = this.textControl.valid;
      if (this.textControl.pristine) {
        if (!(this.showInvalidOnPristine || this.showInvalidOnPristine === undefined)) {
          isValid = true;
        }
      }
    }
    return isValid;
  }

  getIsClearButtonVisible (textValue: string): boolean {
    if (!!textValue && textValue.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getIsAutocompleteEnabled (): boolean {
    return (this.autocompleteOptions && this.autocompleteOptions.length >= 0);
  }

  getDoesAutocompleteHaveTags (): boolean {
    return this.hasAutocomplete && this.autocompleteOptions.some(opt => {
      return opt.tags && opt.tags.length > 0;
    });
  }

  getIsAutocompleteActionVisible (textValue: string): boolean {
    if (this.autocompleteOptions) {
      const isMatchFound = this.isExactMatch(textValue, this.autocompleteOptions);
      return !isMatchFound || this.autocompleteOptions.length === 0;
    } else return false;
  }

  getIsAutocompleteEmptyStateEnabled (): boolean {
    return (
      this.autocompleteOptions &&
      this.autocompleteOptions.length === 0 &&
      !(this.emptyStateText === '' || this.emptyStateText === undefined)
    );
  }

  onSelectOption (event: MatAutocompleteSelectedEvent): void {
    this.shouldOpenPanel = false;
    this.justSelectedOption = true;
    event.option.deselect(); // deselect the element from the dropdown
    this.emitSelectEvent(event.option.value);
    this.autocompleteTrigger.closePanel();

    setTimeout(() => {
      this.shouldOpenPanel = true;
      this.justSelectedOption = false;
    }, 300);
  }

  onClosePanel (currentValue: string, options: IACOption[]): void {
    let selectedOption: IACOption;
    if (!!currentValue && !!options) {
      // Is the current text a valid option?
      selectedOption = options.find(opt => opt.human.toLowerCase() === currentValue.toLowerCase());
    }
    if (selectedOption) {
      // If the current text matches the `human` text of an option, select that option
      this.emitSelectEvent(selectedOption);
    } else {
      this.clearValue.emit(); // Reset the textbox when closing the menu
    }
  }

  emitSelectEvent (option: IACOption): void {
    if (this.returnObject) {
      this.selectOption.emit(option); // Emit the select event with the selected object
    } else {
      this.selectOption.emit(option.human); // Emit the select event with the string // TODO - REMOVE THIS ON REFACTOR - AT
    }
  }

  onFocus (event): void {
    if (this.hasAutocomplete && this.shouldOpenPanel && !this.justSelectedOption) {
      this.autocompleteTrigger.openPanel();
    }
  }

  onClick (event): void {
    if (this.hasAutocomplete) {
      // Clicking the input to focus the autocomplete, triggers an
      // _outsideClickStream event just after autocomplete panel
      // has been opened, hence, causing the autocomplete panel to
      // immediately close right afterwards.
      // To prevent this unintentional behavior, we need to stop
      // event propagation.
      event.stopPropagation();
      if (!this.justSelectedOption) {
        this.autocompleteTrigger.openPanel();
      }
    }
  }

  isExactMatch (text: string, options: IACOption[]): boolean {
    let foundIndex = -1;
    // ensure text is a string
    if (!!options && !!text && typeof text === 'string') {
      foundIndex = options.findIndex(opt => {
        // trim leading and trailing whitespace to remove accidental duplicates (JE)
        return opt.human.toLowerCase() === text.toLowerCase().trim();
      });
    }
    return foundIndex >= 0;
  }

  // Used in template on icon-button press
  // tslint:disable-next-line:no-unused-variable
  clearSearch (): void {
    this.clearValue.emit();
    this.inputElement.nativeElement.focus();
  }

  ngOnDestroy (): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
