import { NvSharedTooltipModule } from 'projects/shared/nvps-libraries/design/nv-tooltip/nv-shared-tooltip.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvPrimaryTileComponent } from './nv-primary-tile.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [CommonModule, NvSharedTooltipModule, NgxSkeletonLoaderModule],
  declarations: [NvPrimaryTileComponent],
  exports: [NvPrimaryTileComponent],
})
export class NvPrimaryTileModule {}
