import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { ImUser } from '../../../shared/services/im-models/im-user';
import { IUser } from '../../typings/interfaces/user.interface';
import { Injectable } from '@angular/core';
import { each, filter, find, flatten, includes, isArray, map, pull, uniq } from 'lodash';
import { ImSchool } from '../../services/im-models/im-school';
import { Cohort } from '../../../shared/constants/cohort.constant';
import { CurrentSchoolYear, DISTRICT_CURRENT_SCHOOL_YEAR_MAP } from '../../../shared/constants/current-school-year.constant';
import { GraduationDate, TValidGradDates } from '../../../shared/constants/graduation-date.constant';
import { GraduationMonth } from '../../../shared/constants/graduation-month.constant';
import { OnTrackStatus } from '../../../shared/constants/on-track-status.constant';
import { PlannedDiplomaType } from '../../../shared/constants/planned-diploma-type.constant';
import { StudentSupportStatuses } from '../../../shared/constants/student-support-statuses.constant';
import {
  ICurrProgramCourse,
  IPointPerson,
  IStudent,
  StudentOtherSchoolType,
  TValidPlannedDiplomaTypes,
} from '../../../shared/typings/interfaces/student.interface';
import { UserRolePermissionsForModelService } from '../user-role-permissions-for-model/user-role-permissions-for-model.service';
import { UtilitiesService } from '../utilities/utilities.service';
import {
  LEAD_POINT_PEOPLE_TYPES,
  NON_LEAD_POINT_PEOPLE_TYPES,
} from './../../../../ng2/shared/constants/point-people-types.constant';
import { Cohort_D75 } from './../../constants/cohort.constant';
import { PartnerTypes } from '../../typings/interfaces/partner.interface';

export interface IMethodDependency {
  paths?: string[];
  joins?: string[];
  methods?: string[];
}

export interface IOtherPointPeople {
  GUIDANCE_COUNSELOR?: IPointPerson;
  ADVISOR?: IPointPerson;
  SUPPORT_LEAD?: IPointPerson;
}

type validCategoryGroup = '5' | '9';

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudent method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    static fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends: IMethodDependency) {
  return function dependencyDecorator (target: any, key: string, descriptor: PropertyDescriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudent {
  permissioningFns;

  constructor (
    private ImSchool: ImSchool,
    private UtilitiesService: UtilitiesService,
    private UserRolePermissionsForModelService: UserRolePermissionsForModelService,
    private ImUser: ImUser,
  ) {
    const PERMISSIONING_MODEL_NAME = 'IM_STUDENT';

    this.permissioningFns = {
      canEdit: this.UserRolePermissionsForModelService.canEditPartial(PERMISSIONING_MODEL_NAME),
      canView: this.UserRolePermissionsForModelService.canViewPartial(PERMISSIONING_MODEL_NAME),
    };
  }

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student: IStudent, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.UtilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      each(methods, m => {
        if (!includes(resolvedMethods, m)) {
          if (includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the paths that `method` depends on
   *
   * @param method a ImStudent method
   * @return {Array} and array of paths
   */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!isArray(paths)) {
        console.warn(
          `ImStudent#pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
            `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudent method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = uniq(
      flatten(
        map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  @depends({
    paths: ['studentDetails.name'],
    methods: [],
  })
  fullName (student: IStudent) {
    if (student.studentDetails.name && student.studentDetails.name.first && student.studentDetails.name.last) {
      return `${student.studentDetails.name.first} ${student.studentDetails.name.last}`;
    } else {
      const lastFirst = student.studentDetails.name.lastFirst.split(',').map(string => {
        return string.trim();
      });
      return `${lastFirst[1]} ${lastFirst[0]}`;
    }
  }

  @depends({
    paths: ['gradDetails.threatsToGrad.attendanceThreat'],
    methods: [],
  })
  isAttendanceOnTrack (student: IStudent) {
    const path = 'gradDetails.threatsToGrad.attendanceThreat';
    const status = { status: '' };

    if (this.UtilitiesService.getFieldByPath(student, path)) {
      status.status = OnTrackStatus.OFF;
    } else {
      status.status = OnTrackStatus.ON;
    }

    return status;
  }

  @depends({
    paths: ['gradDetails.threatsToGrad.courseFailureThreat'],
    methods: [],
  })
  isCollegeReadinessOnTrack (student: IStudent) {
    const path = 'gradDetails.threatsToGrad.courseFailureThreat';
    const status = { status: '' };

    if (this.UtilitiesService.getFieldByPath(student, path)) {
      status.status = OnTrackStatus.OFF;
    } else {
      status.status = OnTrackStatus.ON;
    }

    return status;
  }

  // TODO ADD TESTS
  @depends({
    paths: [],
    methods: [],
  })
  isSuperSeniorForGradPlanning (student: IStudent) {
    const studentCohort = student.studentDetails.classOf;
    const cohortDetails = find(Cohort, { humanName: studentCohort });
    return cohortDetails.studentYear > 4;
  }

  // returns 1,2,3,4 instead of cohort
  // TODO this will get added to schema eventually - no need to calculate on the frontend
  @depends({
    paths: ['studentDetails.classOf', 'isHS'],
    methods: [],
  })
  getStudentYear ({ studentDetails, isHS }: IStudent, district: string = 'NYC') {
    const currentSchoolYear = DISTRICT_CURRENT_SCHOOL_YEAR_MAP[district]
    let cohort = studentDetails.classOf;

    if (!isHS) {
      return 0;
    }

    if (!cohort) {
      // If student is HS and no cohort exists, assume they are a freshman (JC)
      cohort = (Number(currentSchoolYear.ENDFULL) + 3).toString();
    }

    const cohortAsVal = Number(cohort);
    const currentSchoolYearEnd = Number(currentSchoolYear.ENDFULL);
    const currentCohortIsD75 = Cohort_D75[cohort];
    if (currentCohortIsD75) return 0;

    if (cohortAsVal > currentSchoolYearEnd) return 4 - (cohortAsVal - currentSchoolYearEnd);
    if (cohortAsVal === currentSchoolYearEnd) return 4;
    if (cohortAsVal < currentSchoolYearEnd) return currentSchoolYearEnd - cohortAsVal + 4;
  }

  // returns 1,2,3,4 instead of cohort
  @depends({
    methods: ['getStudentYear'],
  })
  getEffectiveStudentYear (student: IStudent) {
    const studentYear = this.getStudentYear(student);

    if (studentYear > 4) return 4;
    return studentYear;
  }

  // Returns term in high school out of total number of terms 1, 2, 3, 4, 5, 6, 7, 8 etc.
  // i.e. for a semester school:
  // 1 === freshman term 1
  // 2 === freshman term 2
  // 3 === sophomore term 1
  // 4 === sophomore term 2, etc.
  // i.e. for a trimester school:
  // 1 === freshman term 1
  // 2 === freshman term 2
  // 3 === freshman term 3
  // 4 === sophomore term 1
  // 5 === sophomore term 2
  // 6 === sophomore term 3, etc.
  @depends({
    paths: [],
    methods: ['getStudentYear'],
  })
  getStudentCurrentTerm (student: IStudent, school) {
    const studentCurrentYear = this.getStudentYear(student);
    if (!studentCurrentYear) return null;

    const schoolNumPerTermYear = this.ImSchool.getNumTermsPerYear(school);

    // if the current term is 7, force the current term to be whatever the previous term was
    // i.e. the last term of the school year, excluding summer
    let schoolCurrentTermNumber = this.ImSchool.getCurrentTermNumber(school);
    schoolCurrentTermNumber = schoolCurrentTermNumber === 7 ? schoolNumPerTermYear : schoolCurrentTermNumber;

    const subtractBy = schoolNumPerTermYear - schoolCurrentTermNumber;

    return schoolNumPerTermYear * studentCurrentYear - subtractBy;
  }

  // TODO ADD TESTS
  @depends({
    paths: ['gradPlanningDetails.plannedGraduationDate'],
  })
  isPlannedGraduationDateUpcoming (student) {
    const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;
    const upcomingGradDates = this.ImSchool.getUpcomingGradDates();
    const isFound = find(upcomingGradDates, { humanName: plannedGraduationDate });
    return isFound;
  }

  /**
   * @param {Object} student
   * @return {Number} returns the students grad plan based on their planned diploma type and graduation date
   */
  @depends({
    paths: [
      'gradPlanningDetails.plannedDiplomaType',
      'gradPlanningDetails.plannedGraduationDate',
      'studentDetails.classOf',
      'schoolStatus',
      'isHS',
    ],
    methods: ['isPlannedGraduationDateUpcoming'],
  })
  getCurrentGradPlan (student) {
    let currentGradPlan = '';
    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;
    const cohort = student.studentDetails.classOf;
    const status = student.schoolStatus;
    const isHS = student.isHS;

    // Middle or elementary school students won’t cohort or grad plan
    if (!isHS) return currentGradPlan;
    // D-grad status means the student has already graduated
    if (status === 'D-grad') return (currentGradPlan = 'Graduate');
    // D-neg or D-NDG means the student has already been discharged
    if (status === 'D-neg' || status === 'D-NDG') return (currentGradPlan = 'Negative Discharge');

    // Deal with planned non-graduates
    if (plannedDiplomaType === 'Non-Graduate') return (currentGradPlan = 'Non-Graduate');

    // Handle incomplete grad plans
    const planIsIncomplete =
      !plannedGraduationDate || !plannedDiplomaType || plannedDiplomaType === PlannedDiplomaType.NO_PLAN.humanName;
    if (planIsIncomplete) return (currentGradPlan = 'Plan Incomplete');
    const planIsInPast = !this.isPlannedGraduationDateUpcoming(student);
    if (planIsInPast) return (currentGradPlan = 'Plan in Past');

    // Get graduation date constant obj for the current plannedGraduationDate
    const gradDateDetails = find(GraduationDate, { humanName: plannedGraduationDate });
    const gradDateYear = gradDateDetails.year;
    const gradDateMonth = gradDateDetails.month;
    const cohortAsVal = Number(cohort);

    // If the student’s planned gradYear is less than or equal to their cohort
    // flag the student as a 4 year grad
    // (i.e. 2019 planned gradYear is less than 2020 cohort)
    if (gradDateYear <= cohortAsVal) {
      currentGradPlan = plannedDiplomaType + ' 4 Year ' + gradDateMonth;

      // If the student’s planned gradYear is greater than their cohort
      // the difference between the two plus 4 is how long the student is
      // planned to be in high school
      // (ie. 2020 planned gradYear is greater than 2019 cohort; 2020-2019 = 1 + 4 = 5 years)
    } else if (gradDateYear > cohortAsVal) {
      let diffPlus4: any = gradDateYear - cohortAsVal + 4;
      diffPlus4 = diffPlus4 >= 6 ? '6+' : diffPlus4;
      currentGradPlan = plannedDiplomaType + ' ' + diffPlus4 + ' Year';
    }

    return currentGradPlan;
  }

  /**
   * @param {Object} student
   * @return {Number} returns the students grad plan based on their planned diploma type and graduation date
   */
  @depends({
    paths: [
      'gradPlanningDetails.plannedDiplomaType',
      'gradPlanningDetails.plannedGraduationDate',
      'isHS',
      'schoolStatus',
    ],
    methods: ['isPlannedGraduationDateUpcoming'],
  })
  getCurrentGradPlanTransfer (student: IStudent) {
    let currentGradPlan = '';
    const {
      gradPlanningDetails: { plannedGraduationDate, plannedDiplomaType },
      schoolStatus: status,
      isHS,
    } = student;

    // Middle or elementary school students won’t cohort or grad plan
    if (!isHS) return currentGradPlan;
    // D-grad status means the student has already graduated
    if (status === 'D-grad') return (currentGradPlan = 'Graduate');
    // D-neg or D-NDG means the student has already been discharged
    if (status === 'D-neg' || status === 'D-NDG') return (currentGradPlan = 'Negative Discharge');

    // Deal with planned non-graduates
    if (plannedDiplomaType === 'Non-Graduate') return (currentGradPlan = 'Non-Graduate');

    // Handle incomplete grad plans
    const planIsIncomplete =
      !plannedGraduationDate || !plannedDiplomaType || plannedDiplomaType === PlannedDiplomaType.NO_PLAN.humanName;
    if (planIsIncomplete) return (currentGradPlan = 'Plan Incomplete');
    const planIsInPast = !this.isPlannedGraduationDateUpcoming(student);
    if (planIsInPast) return (currentGradPlan = 'Plan in Past');

    currentGradPlan = `${plannedDiplomaType} ${plannedGraduationDate}`;

    return currentGradPlan;
  }

  /**
   * Filters an array of student supports to those that are currently active
   * @param {Array} `studentSupports`
   * @param {String} - optional - `supportCategoryKey` from SupportCategories constant
   *                               in app.constants.ts (i.e. 'REGENTS_PREP')
   * @param {String} - optional - `examSubjectKey` in RegentsSubject.[CONSTANT].key (i.e. 'ela')
   * @return {Array} of filtered studentSupports that are currently active
   */
  @depends({
    paths: [],
    methods: [],
  })
  getCurrentlyActiveSupports (
    student: IStudent,
    studentSupports: any[],
    supportCategoryKey?: string,
    examSubjectKey?: string,
  ) {
    return filter(studentSupports, function (studentSupport: IStudentSupport) {
      const studetSupportIsActive = studentSupport.status === StudentSupportStatuses.backend.ACTIVE;

      if (!studetSupportIsActive) return false;
      let include = true;

      if (supportCategoryKey) {
        include = studentSupport.support.categories.some(({ category }) => category === supportCategoryKey);
      }

      if (examSubjectKey) {
        include = studentSupport.support.categories.some(({ metaData }) => metaData?.examSubject === examSubjectKey);
      }

      return include;
    });
  }

  /**
   * Looks at `isSped` or `schoolVerifiedSafetyNetEligibility`. If either is true, it returns true.
   * @return {Boolean}
   */
  @depends({
    paths: ['spedDetails.isSped', 'gradPlanningDetails.schoolVerifiedSafetyNetEligibility'],
    methods: [],
  })
  isSafetyNetEligible (student: IStudent): boolean {
    const isSped = student.spedDetails.isSped;
    const schoolVerifiedSafetyNetEligibility = student.gradPlanningDetails.schoolVerifiedSafetyNetEligibility;
    if (schoolVerifiedSafetyNetEligibility === true) return true;
    if (schoolVerifiedSafetyNetEligibility === false) return false;
    return isSped;
  }

  /**
   * Based on plannedDiplomaType, it either returns '5' or '9'
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType]
   *                  - a value in PlannedDiplomaType[CONSTANT].humanName
   * @returns {string} '5' or '9'
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: [],
  })
  isPlannedDiplomaTypeByCat5OrByCat9 (
    student: IStudent,
    plannedDiplomaType?: TValidPlannedDiplomaTypes,
  ): validCategoryGroup {
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    let plannedDiplomaTypeDetails: any = find(PlannedDiplomaType, { humanName: plannedDiplomaType });
    if (!plannedDiplomaTypeDetails) plannedDiplomaTypeDetails = PlannedDiplomaType.NO_PLAN;
    return plannedDiplomaTypeDetails.byCategory;
  }

  @depends({
    paths: [],
    methods: ['getStudentYear'],
  })
  isSuperSenior (student: IStudent) {
    return this.getStudentYear(student) > 4;
  }

  // TODO ADD TESTS
  /**
   * @param {Object} student
   * @return {Boolean} true if the student is Active
   */
  @depends({
    paths: ['schoolStatus'],
    methods: [],
  })
  isActive (student: IStudent) {
    const status = student.schoolStatus;
    return status === 'A';
  }

  // TODO ADD TESTS
  @depends({
    paths: ['gradPlanningDetails.plannedGraduationDate', 'gradPlanningDetails.plannedDiplomaType', 'isHS'],
    methods: ['isActive'],
  })
  isPlannedToGraduateThisSchoolYear (student: IStudent) {
    const plannedGradDate = student.gradPlanningDetails.plannedGraduationDate;
    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const isActive = this.isActive(student);
    const isHS = student.isHS;

    if (!isActive) return false;
    if (!isHS) return false;
    if (!plannedDiplomaType || !plannedGradDate) return false;
    if (plannedDiplomaType === PlannedDiplomaType.NO_PLAN.humanName) return false;
    if (plannedDiplomaType === PlannedDiplomaType.NON_GRADUATE.humanName) return false;

    const currentSchoolYearEnd = CurrentSchoolYear.ENDFULL;
    // TODO this does not need to be calculated over and over
    // TODO should this be a service?? (DS)
    const gradDatesThisYear = map(GraduationMonth, function (gradMonthValue) {
      return gradMonthValue + ' ' + currentSchoolYearEnd;
    });
    const isPlannedToGraduateThisSchoolYear = includes(gradDatesThisYear, plannedGradDate);
    return isPlannedToGraduateThisSchoolYear;
  }

  @depends({
    paths: ['studentProgramDetails.currSy'],
    methods: [],
  })
  getCoursesForCurrentTermYear (student: IStudent): ICurrProgramCourse[] {
    const studentCourses = student.studentProgramDetails.currSy;

    return filter(studentCourses, (course: ICurrProgramCourse) => {
      return course.isCurrentTermYear;
    });
  }

  @depends({ paths: ['schoolId'] })
  isTransferStudent (student: IStudent, school): boolean | void {
    // ensure student attends school arg
    if (student.schoolId !== school._id) {
      console.error('student and school are misaligned');
      return;
    }
    return this.ImSchool.isTransferSchool(school);
  }

  @depends({
    paths: ['otherSchools', 'schoolId', '_id'],
    methods: [],
  })
  canEdit (student: IStudent, viewingUser: IUser, schoolId: string, contextPartnerType: string): boolean {
    if (contextPartnerType === PartnerTypes.SHELTER) {
      return this.permissioningFns.canEdit(student, viewingUser);
    }
    return !this.isSummerOnly(student, schoolId, viewingUser) && !this.isUftDoeAdvisingStudent(student, schoolId, viewingUser) && this.permissioningFns.canEdit(student, viewingUser);
  }

  @depends({
    paths: [],
    methods: [],
  })
  canView (student: IStudent, viewingUser): boolean {
    return this.permissioningFns.canView(student, viewingUser);
  }

  // TODO ADD TESTS
  /**
   * Based on plannedDiplomaType, it either returns the value from
   * student.regentsDetails.numberPasssed[of5Local] or [of5Regents] or [of5Advanced]
   * @param {Object} student
   * @param {String} plannedDiplomaType student.gradPlanningDetails.plannedDiplomaType
   * - a value in PlannedDiplomaType[CONSTANT].humanName
   * @returns {Number} number of Regents requirements passed based on the students planned diploma type
   */
  @depends({
    methods: [],
    paths: ['gradPlanningDetails.plannedDiplomaType', 'regentsDetails.numberPassed'],
  })
  getNumberRegentsPassedBasedOnDiplomaType (student, plannedDiplomaType?) {
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    const plannedDiplomaTypeDetails = find(PlannedDiplomaType, { humanName: plannedDiplomaType });
    const keyForNumberPassed = plannedDiplomaTypeDetails.keyForNumberPassed;
    const numberPassedBasedOnDiplomaType = student.regentsDetails.numberPassed[keyForNumberPassed];
    return numberPassedBasedOnDiplomaType;
  }

  @depends({
    paths: ['studentDetails.classOf'],
    methods: ['getStudentYear'],
  })
  getEffectiveCohort (student: IStudent, studentYear?: number) {
    const {
      studentDetails: { classOf: studentCohort },
    } = student;
    const year = studentYear || this.getStudentYear(student);
    const currentSchoolYearEnd = Number(CurrentSchoolYear.ENDFULL);
    const cohortAsVal = studentCohort ? Number(studentCohort) : null;
    const cohortIsBeforeCurrentSchoolYearEnd = cohortAsVal && cohortAsVal < currentSchoolYearEnd;
    const freshmanCohort = currentSchoolYearEnd + 3;
    const cohortIsAfterFreshmanCohort = cohortAsVal > freshmanCohort;

    if (cohortIsAfterFreshmanCohort) return freshmanCohort.toString();
    if (year > 4 || cohortIsBeforeCurrentSchoolYearEnd) return CurrentSchoolYear.ENDFULL;
    return studentCohort;
  }

  /**
   * Returns `student.gradPlanningDetails.plannedGraduationDate`
   * If `student.gradPlanningDetails.plannedGraduationDate` is null, it returns
   * Aug of the student's cohort year as the grad date
   *
   * @param {Object} IStudent
   * @return {String} Effective Grad Date
   */
  @depends({
    paths: ['gradPlanningDetails.plannedGraduationDate', 'studentDetails.classOf'],
    methods: [],
  })
  getEffectivePlannedGradDate (student: IStudent): TValidGradDates {
    const { plannedGraduationDate } = student.gradPlanningDetails;
    if (!plannedGraduationDate) {
      const { classOf } = student.studentDetails;
      if (classOf) {
        // For super seniors: use August of the current school year, otherwise use August of 'classOf' value
        const { STARTFULL: currentSchoolYear } = CurrentSchoolYear;
        const effectiveGradYear = classOf >= currentSchoolYear ? classOf : currentSchoolYear;
        const assumedPlannedGraduationDate: any = `Aug ${effectiveGradYear}`;
        return assumedPlannedGraduationDate;
      }
    }
    return plannedGraduationDate;
  }

  @depends({
    paths: ['pointPeople'],
    methods: [],
  })
  getLeadPointPeople (student: IStudent) {
    const { pointPeople } = student;
    const response = {};
    each(pointPeople, person => {
      const { type } = person;
      if (includes(LEAD_POINT_PEOPLE_TYPES, type)) response[type] = person;
    });
    return response;
  }

  @depends({
    paths: ['pointPeople'],
    methods: [],
  })
  getOtherPointPeople (student: IStudent): IOtherPointPeople {
    const { pointPeople } = student;
    const response = {};
    each(pointPeople, (person: IPointPerson) => {
      const { type } = person;
      if (includes(NON_LEAD_POINT_PEOPLE_TYPES, type)) response[type] = person;
    });
    return response;
  }

  @depends({
    paths: ['otherSchools', 'schoolId', '_id'],
  })
  isSummerOnly (student: Partial<IStudent>, schoolId: string, viewingUser?: IUser): boolean {
    let isSummerOnly: boolean;
    const isInOriginalSchool = this.isInOriginalSchool(student, schoolId);
    if (isInOriginalSchool) {
      isSummerOnly = false;
    } else {
      // if a student doesn't have otherSchools, they should really not be considered a summer school student.
      // but due to how we're using this method right now in the student.component via initCurrentStudentData method,
      // we're only passing in a student partial that doesn't have the otherSchool subdoc. This needs to be revisted. (Jack)
      if (!student.otherSchools) isSummerOnly = true;
      else {
        isSummerOnly = student.otherSchools.some(otherSchool => {
          let schoolIdMatches = false;

          if (otherSchool.type === StudentOtherSchoolType.SUMMER) {
            schoolIdMatches = otherSchool.schoolId === schoolId || otherSchool.schoolId === viewingUser?.summerSiteSchoolId;
          }

          return schoolIdMatches;
        });
      }
    }
    return isSummerOnly;
  }

  @depends({
    paths: [],
    methods: ['isInOriginalSchool'],
  })
  isUftDoeAdvisingStudent (student: Partial<IStudent>, schoolId: string, user: IUser): boolean {
    const isUftDoeAdvisingUser = this.ImUser.isUftDoeAdvisingUser(user);
    let isUftDoeAdvisingStudent = false;

    if (isUftDoeAdvisingUser) {
      const isInOriginalSchool = this.isInOriginalSchool(student, schoolId);
      const isCaseloadUser = this.ImUser.isCaseloadUser(user);
      const isNonCaseloadUftDoeAdvisor = isUftDoeAdvisingUser && !isCaseloadUser;
      const isCaseloadUftDoeAdvisor = isUftDoeAdvisingUser && isCaseloadUser;

      if (isNonCaseloadUftDoeAdvisor) {
        isUftDoeAdvisingStudent = !isInOriginalSchool;
      } else if (isCaseloadUftDoeAdvisor) {
        isUftDoeAdvisingStudent = !this.ImUser.isStudentInCaseload(user, student._id);
      }
    }

    return isUftDoeAdvisingStudent;
  }

  @depends({
    paths: ['schoolId', '_id'],
  })
  isInOriginalSchool (student: Partial<IStudent>, schoolId: string): boolean {
    const studentSchoolId = student.schoolId ? student.schoolId : student._id.slice(-6);
    return studentSchoolId === schoolId && student._id.slice(-6) === schoolId;
  }
}
