<nv-icon *ngIf="data.isDanger" [name]="iconName"></nv-icon>

<span *ngIf="!!data.toastText" class="content">{{ data.toastText }}</span>

<button id="action-button" *ngIf="!!data.actionText" (click)="snackBarRef.dismissWithAction()">
  {{ data.actionText }}
</button>

<nv-icon-button
  *ngIf="!!data.hasClearButton"
  id="close-button"
  name="close-large-white"
  [color]="data.isDanger ? 'red' : 'grey'"
  [isInverted]="true"
  (clickButton)="snackBarRef.dismiss()"
></nv-icon-button>
