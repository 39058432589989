import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  each,
  filter,
  find,
  flattenDeep,
  forEach,
  includes,
  map,
  reduce,
  some,
  sortBy,
  uniq,
  uniqBy,
  without,
} from 'lodash';
import { Observable } from 'rxjs';
import { map as rxMap, take } from 'rxjs/operators';
import { UpdateCsvData } from 'Src/ng2/store';
import { CurrentSchoolYear } from '../../constants/current-school-year.constant';
import { LIST_TYPE } from '../../constants/list-view/list-view.constant';
import { IStudentSupport } from '../../typings/interfaces/student-support.interface';
import { IPointPerson } from '../../typings/interfaces/student.interface';
import { IUserMini } from '../../typings/interfaces/user.interface';
import { IConstants } from './../../../school/lists/academic-list/academic-mad-lib/academic-mad-lib.interface';
import { MadLibSorting } from './mad-lib-sorting';

export interface IMadlibOptions {
  _id: string;
  grade?: string[];
  classOf?: string[];
  officialClass?: string[];
  plannedGraduationDate?: string[];
  isSped?: boolean[];
  isEll?: boolean[];
  isSpEll?: boolean[];
  counselor?: IUserMini[];
  advisor?: IUserMini[];
  pointPeople?: IPointPerson[][];
}

const ES_LEVEL = 'ES';
const MS_LEVEL = 'MS';
const HS_LEVEL = 'HS';
const SCHOOL_LEVEL_BY_GRADE = {
  PK: ES_LEVEL,
  '0K': ES_LEVEL,
  '01': ES_LEVEL,
  '02': ES_LEVEL,
  '03': ES_LEVEL,
  '04': ES_LEVEL,
  '05': ES_LEVEL,
  '06': MS_LEVEL,
  '07': MS_LEVEL,
  '08': MS_LEVEL,
  '09': HS_LEVEL,
  10: HS_LEVEL,
  11: HS_LEVEL,
  12: HS_LEVEL,
};
const panelShadowLimit = 10;

/* istanbul ignore next */
@Injectable()
export class MadLibHelpers {
  constructor (private madLibSorting: MadLibSorting, private store: Store<any>, private router: Router) {}

  getStateParamFromRecentHistory (storeState$, viewName: string, param: string): string {
    let result;
    storeState$.pipe(take(1)).subscribe(previousStates => {
      let length = previousStates.length - 1;
      // since we need the most recent entry, loop from the end
      while (length >= 0) {
        const desiredState = previousStates[length][viewName];
        if (desiredState) {
          result = desiredState[param];
          length = -1;
        }
        length--;
      }
    });
    return result;
  }

  formatListSearchTerm (searchTerm: string): string {
    const formattedSearchTerm =
      searchTerm &&
      searchTerm
        .replace(/[^0-9a-z,\- ]/gi, '') // replace everything except 0-9, a-z, A-Z, hyphen, comma and space
        .replace(/,/g, ' ') // replace all instances of comma with space
        .replace(/ +/g, ' ') // replace one or more occurrences of space with single space
        .toLowerCase()
        .trim();

    return formattedSearchTerm;
  }

  assignStudentSupports (studentSupports: IStudentSupport[], students) {
    const studentSupportsKey = 'activeStudentSupports';
    const studentSupportsAssignments = map(students, (st: any) => {
      st[studentSupportsKey] = [];
      each(studentSupports, (studentSupport: IStudentSupport) => {
        const supportStudentIdTrimmed = studentSupport.student.studentId.substring(
          0,
          studentSupport.student.studentId.length - 6,
        );
        if (st.studentId === supportStudentIdTrimmed) {
          st[studentSupportsKey].push(studentSupport);
        }
      });
      return st;
    });
    return studentSupportsAssignments;
  }

  /**
   *
   * @param keyToAddToStudent - key to add to each student that will house the items with same studentId in collection
   * @param studentIdKeyInItem - key in collection item that equals that of `student._id`
   */
  mergeCollectionToStudents<T, V> ({
    students,
    keyToAddToStudent,
    collection,
    studentIdKeyInItem,
  }: {
    students: any[]; // iStudent invalid. Should be an extended interface againset iStudent
    keyToAddToStudent: string;
    collection: T[];
    studentIdKeyInItem: string;
  }): V[] {
    // where item is milestone, etc
    const hash = reduce(
      collection,
      (result, item) => {
        const studentId = item[studentIdKeyInItem];

        if (result[studentId]) {
          result[studentId].push(item);
        } else {
          result[studentId] = [item];
        }

        return result;
      },
      {},
    );
    const mergedStudents = map(students, (s: any) => {
      const studentId = s._id;
      const items = hash[studentId] || [];

      s[keyToAddToStudent] = items;

      return s;
    });
    return mergedStudents;
  }

  filterMadLib (madlib: any[], madlibOptions: IMadlibOptions, school: any, listName: string) {
    const filteredDimension1 = [];
    const { schoolType } = school;
    const schoolYear = CurrentSchoolYear.ENDFULL_VAL;
    const [dimension1, dimension2, groupings] = madlib;
    const dimension1Copy: any = { ...dimension1 };
    let { options } = dimension1;

    const isHS = includes(['Small HS', 'Large HS', 'Transfer'], schoolType);
    const is6_12 = schoolType === '6 to 12';
    const isK_8 = schoolType === 'K to 8';
    const is6_8 = schoolType === '6 to 8';
    let schoolLevel;
    if (isHS) {
      schoolLevel = 'HS';
    } else if (is6_12 || is6_8 || isK_8) {
      schoolLevel = 'MS';
    } else if (schoolType === 'K to 5') {
      schoolLevel = 'ES';
    }

    options = filter(options, (option: any) => {
      return includes(option.lists, listName);
    });

    forEach(options, (option: any, idx) => {
      const optionCopy = { ...option };
      // an option without children doesn't have a sub-menu (JE)
      if (!option.children) {
        filteredDimension1.push(option);
      } else {
        let children = [];
        // check for aggregated buckets like 'Special population' (JE)
        switch (option.key) {
          case 'SPECIAL_POPULATION':
            let specialPopOptions;
            if (isHS) specialPopOptions = this.parseSpecialPopOptionsHS(madlibOptions);
            //
            else if (is6_8) specialPopOptions = this.parseSpecialPopOptionsMS(madlibOptions);
            else if (is6_12 || isK_8) { specialPopOptions = this.parseSpecialPopOptionsHybrid(madlibOptions, is6_12, isK_8, listName); } else specialPopOptions = this.parseSpecialPopOptionsES(madlibOptions);
            children = specialPopOptions;
            break;
          case 'GRAD_PLAN':
            const gradPlanOptions = this.parseGradPlanOptions(madlibOptions, schoolYear);
            children = gradPlanOptions;
            break;
          case 'CLASS':
            const cohortOptions = this.parseCohortOptions(madlibOptions, schoolYear, schoolLevel);
            children = cohortOptions;
            break;
          case 'OFFICIAL_CLASS':
            const officialClassOptions = this.parseOfficialClassOptions(
              madlibOptions,
              schoolLevel,
              schoolType,
              listName,
            );
            children = officialClassOptions;
            break;
          case 'GRADE':
            const gradeOptions = this.parseGradeOptions(option, madlibOptions, listName);
            children = gradeOptions;
            break;
          case 'ATTENDANCE_POINT_PERSON':
          case 'ACADEMIC_POINT_PERSON':
          case 'POST_SECONDARY_POINT_PERSON':
          case 'GUIDANCE_COUNSELOR':
          case 'ADVISOR':
            const userOptions = this.parseUserOptions(option, madlibOptions, schoolLevel);
            children = userOptions;
            break;
          default:
            const childOptions = this.parseDimension1Options(option, madlibOptions, idx);
            children = childOptions;
            break;
        }
        optionCopy.children = children;
        filteredDimension1.push(optionCopy);
      }
    });
    dimension1Copy.options = filteredDimension1.sort((a, b) => {
      if (b.order > a.order) return -1;
      if (b.order < a.order) return 1;
      return 0;
    });
    return without([dimension1Copy, dimension2, groupings], undefined);
  }

  getDimensionOptionsForSchoolType (schoolType: string, dimensionConstant) {
    return dimensionConstant.options[schoolType];
  }

  getStudentSupportsByCategory (studentSupports, category) {
    return studentSupports.filter(studentSupport => studentSupport.support.category === category);
  }

  generateDimensionForSchool (schoolOptions, dimensionConstant) {
    return { ...dimensionConstant, ...{ options: schoolOptions } };
  }

  getListColumns (madlibModel, constants?: IConstants) {
    const { Dimension1, Dimension2 = { columns: [] }, Groupings = { columns: [] } } = madlibModel;
    const columns = { ...Dimension1.columns, ...Dimension2.columns, ...Groupings.columns };
    const selectedOption = Dimension1.key;
    const postSecColExclusionKeys = [
      'ALL_MS_STUDENTS',
      'ALL_MY_MS_STUDENTS',
      '06',
      '07',
      '08',
      'MY_MS_SPED',
      'MY_MS_ELL',
      'MY_MS_SPED_AND_ELL',
    ];

    return reduce(
      columns,
      (result: any, col: any) => {
        if (
          (col.key === 'postSecondaryPointPerson') &&
          (postSecColExclusionKeys.indexOf(selectedOption) !== -1 ||
            selectedOption.substring(0, 3).toUpperCase() === 'MS ')
        ) {
          return result;
        }
        // this is currently only used in the academic list and is subject failure specific(JJ)
        // and is iReady specific (JChu)
        if (col.isHumanNameDynamic) {
          col.setDynamicHumanName(madlibModel, constants);
        }

        result.push(col);
        return result;
      },
      [],
    );
  }

  getFilteredStudents (students$, currentDim1Selection): Observable<any> {
    const { filterStudent } = currentDim1Selection;
    return students$.pipe(rxMap((students: any[]) => students.filter(student => filterStudent(student))));
  }

  applySearchFilter (filteredStudents$, searchKey: string): Observable<any> {
    if (!searchKey || searchKey.trim() === '') {
      return filteredStudents$;
    }

    searchKey = searchKey.toLowerCase();
    return filteredStudents$.pipe(
      rxMap((students: any[]) => {
        return students.reduce((acc, student) => {
          let matchFound: boolean = false;
          const studentName = student.studentDetails.name.lastFirst.toLowerCase();
          const studentId = student._id.toLowerCase();
          const searchTerms = searchKey.split(' ');

          searchTerms.forEach(pattern => {
            if (
              !matchFound &&
              pattern.trim() !== '' &&
              (studentName.includes(pattern) || studentId.includes(pattern))
            ) {
              acc.push(student);
              matchFound = true;
            }
          });
          return acc;
        }, []);
      }),
    );
  }

  // sort grouping and append needed sortData to new groupings object
  /* istanbul ignore next */
  getSortedStudentGrouping (groupings$, key, dataType, sortOrder, sortState, isInitialLoad, searchQuery?, madLibModel?) {
    const Groupings = madLibModel && madLibModel.Groupings ? madLibModel.Groupings.human : null;
    const madLibSelections = {
      Dimension1: madLibModel ? madLibModel.Dimension1.human : null,
      Dimension2: madLibModel ? madLibModel.Dimension2.human : null,
      Groupings,
    };
    const sortData = { key, dataType, sortOrder, isInitialLoad }; // isInitialLoad?
    return groupings$.pipe(
      rxMap(data => {
        const sortedData = reduce(
          data as any,
          (sortedGrouping, data: any, prop) => {
            if (prop === 'sections') {
              const sortedSections = this.madLibSorting.sortSections(data, sortState, sortData, isInitialLoad);
              sortedGrouping[prop] = sortedSections;
            } else sortedGrouping[prop] = data;
            return sortedGrouping;
          },
          { sortedColumn: key, sortOrder, searchQuery } as any,
        );
        const payload = {
          data: sortedData,
          madLibSelections,
        };
        // update csvData on store
        if (madLibModel) this.store.dispatch(new UpdateCsvData(payload));
        return sortedData;
      }),
    );
  }

  parseDimension1Options (option, madlibOptions, idx) {
    const children = [];
    const paths = option.path.split('.');
    const lastPath = paths[paths.length - 1];
    const userValidOptions = madlibOptions[lastPath];
    forEach(userValidOptions, validOption => {
      if (validOption) {
        // account for null values in distinct query (JE)
        const childOption = {
          human: validOption,
          key: validOption,
          schoolLevel: option.schoolLevel,
          dimension: 1,
          isActive: false,
          order: idx,
          filterStudent: student => {
            let studentCopy = { ...student };
            paths.forEach(path => {
              studentCopy = studentCopy[path];
            });
            return studentCopy === validOption;
          },
        };
        children.push(childOption);
      }
    });
    const sortedChildren = children.sort((a, b) => {
      if (b.human > a.human) return -1;
      else if (b.human < a.human) return 1;
    });
    return sortedChildren;
  }

  parseGradeOptions (option, madlibOptions, listName) {
    const children = [];
    const paths = option.path.split('.');
    const lastPath = paths[paths.length - 1];
    let userValidOptions;
    if (option.schoolType === '6_to_12' && listName === 'POST_SECONDARY') {
      userValidOptions = madlibOptions[lastPath].sort().slice(3);
    } else {
      userValidOptions = madlibOptions[lastPath];
    }
    const esSpecialGrades = [];
    forEach(userValidOptions, validOption => {
      if (validOption) {
        // account for null values in distinct query (JE)
        const childOption = {
          human: `Grade ${validOption}`,
          key: validOption,
          schoolLevel: SCHOOL_LEVEL_BY_GRADE[validOption],
          dimension: 1,
          isActive: false,
          isCalculatedGraphQuery: false,
          getGraphQuery: () => `grade=${validOption}`,
          filterStudent: student => {
            let studentCopy = { ...student };
            paths.forEach(path => {
              studentCopy = studentCopy[path];
            });
            return studentCopy === validOption;
          },
        };
        // account for ES grades (JE)
        if (validOption === 'PK' || validOption === '0K') esSpecialGrades.push(childOption);
        else children.push(childOption);
      }
    });
    const sortedChildren = children.sort((a, b) => {
      if (b.human > a.human) return 1;
      else if (b.human < a.human) return -1;
    });
    const sortedESSpecialGrades = esSpecialGrades.sort((a, b) => {
      if (b.human > a.human) return -1;
      else if (b.human < a.human) return 1;
    });
    return [...sortedChildren, ...sortedESSpecialGrades];
  }

  parseSpecialPopOptionsHybrid (madlibOptions, is6_12, isK_8, listName) {
    const children = [];
    const hasSped = includes(madlibOptions.isSped, true);
    const hasEll = includes(madlibOptions.isEll, true);
    const hasSpEll = includes(madlibOptions.isSpEll, true);
    const isPostSec = listName === 'POST_SECONDARY';

    const isSpedHSOption = {
      human: 'HS SPED',
      key: 'MY_HS_SPED',
      schoolLevel: 'HS',
      dimension: 1,
      order: 0,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isHS=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.isHS;
      },
    };
    if (hasSped && is6_12) children.push(isSpedHSOption);

    const isEllHSOption = {
      human: 'HS ELL',
      key: 'MY_HS_ELL',
      schoolLevel: 'HS',
      dimension: 1,
      order: 1,
      getGraphQuery: () => 'isHS=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll && student.isHS;
      },
    };
    if (hasEll && is6_12) children.push(isEllHSOption);

    const isSpedAndEllHSOption = {
      human: 'HS SPED & ELL',
      key: 'MY_HS_SPED_AND_ELL',
      schoolLevel: 'HS',
      dimension: 1,
      order: 2,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isHS=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll && student.isHS;
      },
    };
    if (hasSpEll && is6_12) children.push(isSpedAndEllHSOption);

    const isSpedMSOption = {
      human: 'MS SPED',
      key: 'MY_MS_SPED',
      schoolLevel: 'MS',
      dimension: 1,
      order: 0,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isMS=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.isMS;
      },
    };
    if (hasSped && !isPostSec && (is6_12 || isK_8)) children.push(isSpedMSOption);

    const isEllMSOption = {
      human: 'MS ELL',
      key: 'MY_MS_ELL',
      schoolLevel: 'MS',
      dimension: 1,
      order: 1,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isMS=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll && student.isMS;
      },
    };
    if (hasEll && !isPostSec && (is6_12 || isK_8)) children.push(isEllMSOption);

    const isSpedAndEllMSOption = {
      human: 'MS SPED & ELL',
      key: 'MY_MS_SPED_AND_ELL',
      schoolLevel: 'MS',
      dimension: 1,
      order: 2,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isMS=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll && student.isMS;
      },
    };
    if (hasSpEll && !isPostSec && (is6_12 || isK_8)) children.push(isSpedAndEllMSOption);

    const isSpedESOption = {
      human: 'ES SPED',
      key: 'MY_ES_SPED',
      schoolLevel: 'ES',
      dimension: 1,
      order: 0,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.isES;
      },
    };
    if (hasSped && !is6_12) children.push(isSpedESOption);

    const isEllESOption = {
      human: 'ES ELL',
      key: 'MY_ES_ELL',
      schoolLevel: 'ES',
      dimension: 1,
      order: 1,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll && student.isES;
      },
    };
    if (hasEll && !is6_12) children.push(isEllESOption);

    const isSpedAndEllESOption = {
      human: 'ES SPED & ELL',
      key: 'MY_ES_SPED_AND_ELL',
      schoolLevel: 'ES',
      dimension: 1,
      order: 2,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll && student.isES;
      },
    };
    if (hasSpEll && !is6_12) children.push(isSpedAndEllESOption);

    return children;
  }

  parseSpecialPopOptionsHS (madlibOptions) {
    const children = [];
    const hasSped = includes(madlibOptions.isSped, true);
    const hasEll = includes(madlibOptions.isEll, true);
    const hasSpEll = includes(madlibOptions.isSpEll, true);

    const isSpedOption = {
      human: 'SPED',
      key: 'MY_HS_SPED',
      schoolLevel: 'HS',
      dimension: 1,
      order: 0,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isHS=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped;
      },
    };
    if (hasSped) children.push(isSpedOption);

    const isEllOption = {
      human: 'ELL',
      key: 'MY_HS_ELL',
      schoolLevel: 'HS',
      dimension: 1,
      order: 1,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isHS=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll;
      },
    };
    if (hasEll) children.push(isEllOption);

    const isSpedAndEllOption = {
      human: 'SPED & ELL',
      key: 'MY_HS_SPED_AND_ELL',
      schoolLevel: 'HS',
      dimension: 1,
      order: 2,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isHS=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll;
      },
    };
    if (hasSpEll) children.push(isSpedAndEllOption);

    return children;
  }

  parseSpecialPopOptionsES (madlibOptions) {
    const children = [];
    const hasSped = includes(madlibOptions.isSped, true);
    const hasEll = includes(madlibOptions.isEll, true);
    const hasSpEll = includes(madlibOptions.isSpEll, true);

    const isSpedOption = {
      human: 'SPED',
      key: 'MY_ES_SPED',
      schoolLevel: 'ES',
      dimension: 1,
      order: 0,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped;
      },
    };
    if (hasSped) children.push(isSpedOption);

    const isEllOption = {
      human: 'ELL',
      key: 'MY_ES_ELL',
      schoolLevel: 'ES',
      dimension: 1,
      order: 1,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll;
      },
    };
    if (hasEll) children.push(isEllOption);

    const isSpedAndEllOption = {
      human: 'SPED & ELL',
      key: 'MY_ES_SPED_AND_ELL',
      schoolLevel: 'ES',
      dimension: 1,
      order: 2,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'isES=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll;
      },
    };
    if (hasSpEll) children.push(isSpedAndEllOption);

    return children;
  }

  parseSpecialPopOptionsMS (madlibOptions) {
    const children = [];
    const hasSped = includes(madlibOptions.isSped, true);
    const hasEll = includes(madlibOptions.isEll, true);
    const hasSpEll = includes(madlibOptions.isSpEll, true);

    const isSpedOption = {
      human: 'SPED',
      key: 'MY_MS_SPED',
      schoolLevel: 'MS',
      dimension: 1,
      order: 0,
      getGraphQuery: () => 'isMS=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped;
      },
    };
    if (hasSped) children.push(isSpedOption);

    const isEllOption = {
      human: 'ELL',
      key: 'MY_MS_ELL',
      schoolLevel: 'MS',
      dimension: 1,
      order: 1,
      getGraphQuery: () => 'isMS=true&isELL=true',
      filterStudent: student => {
        return student.ellDetails.isEll;
      },
    };
    if (hasEll) children.push(isEllOption);

    const isSpedAndEllOption = {
      human: 'SPED & ELL',
      key: 'MY_MS_SPED_AND_ELL',
      schoolLevel: 'MS',
      dimension: 1,
      order: 2,
      getGraphQuery: () => 'isMS=true&isELL=true&isSPED=true',
      filterStudent: student => {
        return student.spedDetails.isSped && student.ellDetails.isEll;
      },
    };
    if (hasSpEll) children.push(isSpedAndEllOption);

    return children;
  }

  parseUserOptions (option, madlibOptions, schoolLevel: string) {
    const children = [];
    const { optionFilter } = option;
    const paths = option.path.split('.');
    const lastPath = paths[paths.length - 1];
    const userValidOptions = madlibOptions[lastPath];
    // distinct endpoint returns an array of arrays for userminis (JE)
    let flattenedOptions = flattenDeep(userValidOptions);
    flattenedOptions = uniqBy(flattenedOptions, (option: any) => {
      return [option.user.userId, option.type].join();
    });

    const pointPeople = filter(flattenedOptions, (person: IPointPerson) => {
      return person.type === optionFilter.type;
    });
    forEach(pointPeople, (person: any) => {
      const { userId, firstName, lastName } = person.user;
      const personCopy = { ...person };
      if (userId) {
        // account for null values in distinct query (JE)
        const schoolType = firstName.substring(0, 2); // hybrid options have 'HS/MS/ES' appended to first name
        const isHybridSchool = includes(['HS', 'MS', 'ES'], schoolType);
        const truncatedName = firstName.substring(3);
        // remove 'HS/MS/ES' for filter
        if (isHybridSchool) personCopy.user.firstName = truncatedName;
        const userName = isHybridSchool
          ? `${truncatedName} ${lastName} ${schoolType} students`
          : `${firstName} ${lastName}`;
        const childOption = {
          human: userName,
          key: userName,
          schoolLevel,
          dimension: 1,
          isActive: false,
          filterStudent: student => {
            let studentCopy = { ...student };
            paths.forEach(path => {
              studentCopy = studentCopy[path];
            });
            if (isHybridSchool) {
              switch (schoolType) {
                case 'HS':
                  return some(studentCopy, personCopy) && student.isHS;
                case 'MS':
                  return some(studentCopy, personCopy) && student.isMS;
                case 'ES':
                  return some(studentCopy, personCopy) && student.isES;
                default:
                  break;
              }
            }
            return some(studentCopy, personCopy);
          },
        };
        children.push(childOption);
      }
    });

    const sortedChildren = children.sort((a, b) => {
      if (b.human > a.human) return -1;
      if (b.human < a.human) return 1;
      return 0;
    });

    // add option for 'none' (JE)
    const { type } = optionFilter;
    const noneOption = {
      human: 'None',
      key: `None ${type}`,
      schoolLevel,
      dimension: 1,
      isActive: false,
      filterStudent: student => {
        let studentCopy = { ...student };
        paths.forEach(path => {
          studentCopy = studentCopy[path];
        });
        return !find(studentCopy, (person: IPointPerson) => {
          return person.type === optionFilter.type;
        });
      },
    };
    sortedChildren.push(noneOption);

    return sortedChildren;
  }

  parseGradPlanOptions (madlibOptions, schoolYear: number) {
    let children = [];
    const dates = madlibOptions.plannedGraduationDate; // dates are in the format 'Mmm YYYY' (JE)
    const years = dates.map(date => {
      if (date) return date.split(' ')[1];
    }); // parse out year
    const uniqueYears = uniq(years)
      .map((year: string) => parseInt(year))
      .sort((a, b) => {
        if (b > a) return -1;
        if (b < a) return 1;
      });
    const aggregatedYears = uniqueYears.filter((year: number) => {
      return year > schoolYear;
    });

    // check if caseload includes year+ students (JE)
    const plusYears = uniqueYears.filter((year: number) => {
      return year <= schoolYear;
    });

    if (plusYears.length) {
      const childOption = {
        human: `Planned ${schoolYear}+ grads`,
        key: `GRAD_${schoolYear}`,
        schoolLevel: 'HS',
        dimension: 1,
        isCalculatedGraphQuery: true,
        getGraphQuery: (data?) => {
          const { plannedGraduationDate } = data;
          const filteredGradDates = plannedGraduationDate.reduce((years, date) => {
            if (!date) return years;
            // split month from year
            const year = date.split(' ')[1];
            // aggregate an array of senior+ years
            if (parseInt(year) <= schoolYear) years.push(year);
            return years;
          }, []);
          const uniqueFilteredGradDates = uniq(filteredGradDates);
          const queryString = `plannedGraduationDate=${uniqueFilteredGradDates.join(',')}`;
          return queryString;
        },
        filterStudent: student => {
          const {
            gradPlanningDetails: { plannedGraduationDate },
            gradPlanningDetails: { plannedDiplomaType },
          } = student;
          const studentYear = plannedGraduationDate && plannedDiplomaType ? plannedGraduationDate.split(' ')[1] : null;
          return studentYear && parseInt(studentYear) <= schoolYear;
        },
      };
      children.push(childOption);
    }

    aggregatedYears.slice(0, 4).forEach(year => {
      const childOption = {
        human: `Planned ${year} grads`,
        key: `GRAD_${year}`,
        schoolLevel: 'HS',
        dimension: 1,
        isCalculatedGraphQuery: false,
        getGraphQuery: () => `plannedGraduationDate=${year}`,
        filterStudent: student => null,
      };

      childOption.filterStudent = student => {
        const {
          gradPlanningDetails: { plannedGraduationDate },
          gradPlanningDetails: { plannedDiplomaType },
        } = student;
        const studentYear = plannedGraduationDate && plannedDiplomaType ? plannedGraduationDate.split(' ')[1] : null;
        return parseInt(studentYear) === year;
      };
      if (year) children.push(childOption);
    });

    children = sortBy(children, child => {
      return child.human;
    });

    const beyond = {
      human: `Planned beyond ${schoolYear + 3} grads`,
      key: `PLANNED_BEYOND_${schoolYear + 3}_GRADS`,
      schoolLevel: 'HS',
      dimension: 1,
      isActive: false,
      isCalculatedGraphQuery: true,
      getGraphQuery: data => {
        const { plannedGraduationDate } = data;
        const filteredGradDates = plannedGraduationDate.filter(date => {
          if (!date) return false;
          const x = date.split(' ')[1];
          return parseInt(x) > schoolYear + 3;
        });
        const encodedGradDates = filteredGradDates.map(gradDate => encodeURI(gradDate));
        const queryString = encodedGradDates.join(',');
        return queryString;
      },
      filterStudent: student => {
        const {
          gradPlanningDetails: { plannedGraduationDate },
          gradPlanningDetails: { plannedDiplomaType },
        } = student;
        const studentYear = plannedGraduationDate && plannedDiplomaType ? plannedGraduationDate.split(' ')[1] : null;
        return parseInt(studentYear) > schoolYear + 3;
      },
    };
    if (aggregatedYears.length > 4) children.push(beyond);

    const incomplete = {
      human: 'None',
      key: 'NO_GRAD_DATE',
      schoolLevel: 'HS',
      dimension: 1,
      isActive: false,
      isCalculatedGraphQuery: false,
      getGraphQuery: () => 'plannedGraduationDate=null',
      filterStudent: student => {
        const {
          gradPlanningDetails: { plannedGraduationDate },
          gradPlanningDetails: { plannedDiplomaType },
          isHS,
        } = student;
        return (plannedDiplomaType === 'No plan' || !plannedGraduationDate) && isHS;
      },
    };
    if (includes(dates, null)) children.push(incomplete);
    return children;
  }

  parseCohortOptions (madlibOptions, schoolYear: number, schoolLevel) {
    const children = [];
    const cohorts = madlibOptions.classOf;
    const sortedYears = cohorts
      .map((year: string) => parseInt(year))
      .sort((a, b) => {
        if (b > a) return -1;
        if (b < a) return 1;
      });
    const aggregatedYears = sortedYears.filter((year: number) => {
      return year > schoolYear;
    });

    const childOption = {
      human: `${schoolYear}+`,
      key: `COHORT_${schoolYear}`,
      schoolLevel: 'HS',
      dimension: 1,
      isActive: false,
      isCalculatedGraphQuery: true,
      getGraphQuery: data => {
        const { classOf } = data;
        const filteredYears = classOf.filter(year => parseInt(year) <= schoolYear);
        const yearsString = filteredYears.join(',');
        const query = `classOf=${yearsString}`;
        return query;
      },
      filterStudent: student => {
        const {
          studentDetails: { classOf },
          isHS,
        } = student;
        return !!classOf && classOf <= schoolYear && isHS;
      },
    };
    children.push(childOption);

    aggregatedYears.forEach(year => {
      const childOption = {
        human: year,
        key: `COHORT_${year}`,
        schoolLevel: 'HS',
        dimension: 1,
        isActive: false,
        isCalculatedGraphQuery: false,
        getGraphQuery: () => `classOf=${year}`,
        filterStudent: student => {
          const {
            studentDetails: { classOf },
          } = student;
          return parseInt(classOf) === year;
        },
      };

      if (year) children.push(childOption);
    });

    return sortBy(children, child => {
      return child.human;
    });
  }

  parseOfficialClassOptions (madlibOptions: IMadlibOptions, schoolLevel, type, listName) {
    let children = [];
    let esChildren = [];
    let msChildren = [];
    let hsChildren = [];
    const officialClasses = madlibOptions.officialClass;

    officialClasses.forEach(klass => {
      const classPrefix = klass ? klass.substring(0, 2) : 'none';
      const isHSClass = classPrefix === 'HS';
      const isMSClass = classPrefix === 'MS';
      const isESClass = classPrefix === 'ES';

      const childOption = {
        human: klass,
        key: klass,
        schoolLevel,
        dimension: 1,
        isActive: false,
        isCalculatedGraphQuery: false,
        getGraphQuery: () => {
          if (isHSClass) return `isHS=true&officialClass=${klass.substring(3)}`;
          if (isMSClass) return `isMS=true&officialClass=${klass.substring(3)}`;
          if (isESClass) return `isES=true&officialClass=${klass.substring(3)}`;
          return `officialClass=${klass}`;
        },
        filterStudent: student => {
          const {
            studentDetails: { officialClass },
            isHS,
            isMS,
            isES,
          } = student;
          // hybrid schools have classes prepended with 'HS/MS/ES' (JE)
          if (isHSClass) return officialClass === klass.substring(3) && isHS;
          if (isMSClass) return officialClass === klass.substring(3) && isMS;
          if (isESClass) return officialClass === klass.substring(3) && isES;
          return officialClass === klass;
        },
      };
      switch (classPrefix) {
        case 'HS':
          hsChildren.push(childOption);
          break;
        case 'MS':
          msChildren.push(childOption);
          break;
        case 'ES':
          esChildren.push(childOption);
          break;
        case 'none':
          break;
        default:
          children.push(childOption);
      }
    });
    if (children.length > 0) {
      children = sortBy(children, child => {
        return child.human;
      });
      return children;
    } else {
      esChildren = sortBy(esChildren, child => {
        return child.human;
      });
      msChildren = sortBy(msChildren, child => {
        return child.human;
      });
      hsChildren = sortBy(hsChildren, child => {
        return child.human;
      });

      if (type === '6 to 12' && listName === 'POST_SECONDARY') {
        return [...hsChildren];
      } else {
        return [...hsChildren, ...msChildren, ...esChildren];
      }
    }
  }

  createHybridMadlibOptions (schoolType: string, students, madlibOptions: IMadlibOptions): IMadlibOptions {
    // coerce official class into shape 'HS XXX', 'MS YYY', 'ES ZZZ' (JE)
    students = students.filter(student => student.pointPeople);
    const { officialClass } = madlibOptions;
    const hybridOfficialClasses = [];
    officialClass.forEach(klass => {
      const hsClass = find(students, (student: any) => {
        return student.studentDetails.officialClass === klass && student.isHS;
      });
      if (hsClass) hybridOfficialClasses.push(`HS ${klass}`);
      const msClass = find(students, (student: any) => {
        return student.studentDetails.officialClass === klass && student.isMS;
      });
      if (msClass) hybridOfficialClasses.push(`MS ${klass}`);
      const esClass = find(students, (student: any) => {
        return student.studentDetails.officialClass === klass && student.isES;
      });
      if (esClass) hybridOfficialClasses.push(`ES ${klass}`);
    });

    // coerce point people user to include HS/MS/ES
    const { pointPeople } = madlibOptions;
    // 'distinct' api call can return multiple nested arrays of pointpeople (JE)
    let flattenedPointPeople = flattenDeep(pointPeople);
    flattenedPointPeople = uniqBy(flattenedPointPeople, (person: any) => {
      return [person.user.userId, person.type].join();
    });
    const hybridPointPeople = [];
    flattenedPointPeople.forEach((person: any) => {
      const { user } = person;

      const msPerson = find(students, (student: any) => {
        const hasPointPerson = find(student.pointPeople, (pointPerson: any) => {
          return user.userId === pointPerson.user.userId;
        });
        return hasPointPerson && student.isMS;
      });

      if (msPerson) {
        const msUserCopy = { ...user };
        msUserCopy.firstName = `MS ${user.firstName}`;
        hybridPointPeople.push({ type: person.type, user: msUserCopy });
      }

      if (schoolType === '6 to 12') {
        const hsPerson = find(students, (student: any) => {
          const hasPointPerson = find(student.pointPeople, (pointPerson: any) => {
            return user.userId === pointPerson.user.userId;
          });
          return hasPointPerson && student.isHS;
        });
        if (hsPerson) {
          const hsUserCopy = { ...user };
          hsUserCopy.firstName = `HS ${user.firstName}`;
          hybridPointPeople.push({ type: person.type, user: hsUserCopy });
        }
      }
      if (schoolType === 'K to 8') {
        const esPerson = find(students, (student: any) => {
          const hasPointPerson = find(student.pointPeople, (pointPerson: any) => {
            return user.userId === pointPerson.user.userId;
          });
          return hasPointPerson && student.isES;
        });
        if (esPerson) {
          const esUserCopy = { ...user };
          esUserCopy.firstName = `ES ${user.firstName}`;
          hybridPointPeople.push({ type: person.type, user: esUserCopy });
        }
      }
    });

    const madlibOptionsCopy = { ...madlibOptions };
    madlibOptionsCopy.officialClass = hybridOfficialClasses;
    madlibOptionsCopy.pointPeople = [hybridPointPeople];
    return madlibOptionsCopy;
  }

  findNestedOption (dimensionOptions: any[], key: string) {
    const level1Options = [];
    const childOptions = [];
    forEach(dimensionOptions, option => {
      if (option.children) childOptions.push(option);
      else level1Options.push(option);
    });
    const topLevelSelection = find(level1Options, option => {
      return option.key === key;
    });

    let childSelection;
    forEach(childOptions, option => {
      const foundOpt = find(option.children, (child: any) => {
        return child.key === key;
      });
      if (foundOpt) childSelection = foundOpt;
    });
    return topLevelSelection || childSelection;
  }

  updateUrl (madLibModel: object, defaultSortData) {
    const { sortKey, orderBy, dataType, search, list } = defaultSortData;
    const dimensionQueryParams = reduce(
      madLibModel as any,
      (params, v, k) => {
        if (!madLibModel[k]) return params;
        params[k] = madLibModel[k].key;
        return params;
      },
      {},
    );
    const params = {
      ...dimensionQueryParams,
      SortedColumn: sortKey,
      SortBy: orderBy,
      Type: dataType,
      Search: search,
      List: list,
    };
    this.router.navigate([], { queryParams: { params }, replaceUrl: true });
  }

  updateMadLibModel (madLibModel: object, dimension: string, value: any): object {
    const updatedMadLibModel = { ...madLibModel, ...{ [dimension]: value } };
    return updatedMadLibModel;
  }

  getSearchTerm (urlValues, currentListType: LIST_TYPE) {
    let result = '';
    const urlList = urlValues.List;
    if (urlList === currentListType) {
      result = urlValues.Search || '';
    }
    return result;
  }

  showPanelShadow (scrollYPosition: number): boolean {
    return scrollYPosition > panelShadowLimit;
  }
}
